import { Button, Card, Flex, Input, Modal, Typography } from 'antd';
import Header from '@components/Header.tsx';
import OrderSummary from '@pages/CheckoutPage/components/OrderSummary.tsx';
import PaymentMethods from '@pages/CheckoutPage/components/PaymentMethods.tsx';

import { useCartStore } from '@/stores/cart.store.ts';
import { displayPrice } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getMerchantDistance } from '@/services/merchant.service.ts';

import { useNavigate } from 'react-router-dom';
import { buildOrderRequest } from '@utils/order.util.ts';
import { useSubmitOrder } from '@/services/order.service.ts';
import Lottie from 'lottie-react';

import './CheckoutPage.less';
import SuccessAnimation from '@assets/animations/success.json';
import { ORDERS_ROUTE } from '@/routes/AppRoute.tsx';
import { useEffect, useMemo, useState } from 'react';
import OrderPricingBreakdown from '@components/OrderPricingBreakdown';
import DeliveryAddress from '@components/DeliveryAddress';
import { useUserStore } from '@/stores/user.store.ts';
import OrderConfirmation from '@pages/CheckoutPage/components/OrderConfirmation.tsx';
import { track } from '@utils/analytic.util.ts';
import ApplyCoupon from '@pages/CheckoutPage/components/ApplyCoupon.tsx';

const { Title, Text } = Typography;

export default function CheckoutPage() {
  const navigate = useNavigate();
  const { cart, getSubTotalPrice, paymentMethod, coupon, setCoupon, clearCoupon } = useCartStore((state) => state);
  const merchant = useCartStore((state) => state.merchant);
  const defaultAddress = useUserStore((state) => state.defaultAddress);
  const [noteToDriver, setNoteToDriver] = useState('');
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const { data, isFetching } = useQuery({
    queryKey: ['getMerchantDistance', merchant!.id],
    queryFn: () => getMerchantDistance(merchant!.id),
    enabled: !!merchant?.id,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    mutate,
    isPending: isSubmitOrderPending,
    isSuccess: isSubmitOrderSuccess,
    isError: isSubmitOrderError,
    failureReason,
  } = useSubmitOrder();

  useEffect(() => {
    if (!isSubmitOrderError) return;

    Modal.error({
      title: 'Gagal membuat pesanan',
      content: failureReason?.message,
      centered: true,
      okText: 'Oke',
    });
  }, [isSubmitOrderError]);

  const orderDiscount = useMemo(() => {
    if (!coupon || !getSubTotalPrice()) return 0;

    if (coupon.discount_type !== 2) {
      return 0;
    }

    if (coupon.discount_amount) {
      return coupon.discount_amount;
    }

    if (!coupon.discount_percentage) {
      return 0;
    }

    const percentageDiscount = getSubTotalPrice() * (coupon.discount_percentage / 100);
    return Math.min(percentageDiscount, coupon.max_discount_amount ?? percentageDiscount);
  }, [coupon, cart]);

  const deliveryDiscount = useMemo(() => {
    if (!coupon || !data?.delivery_fee) return 0;

    if (coupon.discount_type !== 1) {
      return 0;
    }

    if (coupon.discount_amount) {
      return coupon.discount_amount;
    }

    if (!coupon.discount_percentage) {
      return 0;
    }

    const percentageDiscount = data.delivery_fee * (coupon.discount_percentage / 100);
    return Math.min(percentageDiscount, coupon.max_discount_amount ?? percentageDiscount);
  }, [coupon, data]);

  const calculateTotalPrice = (): number => {
    const deliveryFee = data?.delivery_fee ?? 0;
    const serviceFee = data?.service_fee ?? 0;
    return getSubTotalPrice() + deliveryFee + serviceFee - orderDiscount - deliveryDiscount;
  };

  const renderButtonText = () => {
    if (getSubTotalPrice() === 0) {
      return 'Kembali ke Menu';
    }

    return `Pesan Sekarang - ${displayPrice(calculateTotalPrice(), false)}`;
  };

  const onActionButtonClicked = () => {
    if (getSubTotalPrice() === 0) {
      navigate(-1);
      return;
    }

    setConfirmationVisible(true);
  };

  const checkout = (latitude: number, longitude: number) => {
    // Do api checkout
    const request = buildOrderRequest(
      merchant.id,
      cart,
      paymentMethod,
      data!.service_fee!,
      data!.delivery_fee!,
      noteToDriver
    );

    track('CHECKOUT');
    mutate({ ...request, latitude, longitude, coupon_code: coupon?.code });
  };

  if (isSubmitOrderSuccess) {
    return (
      <Flex vertical gap={'large'} className={'min-h-screen'} flex={1} align={'center'} justify={'center'}>
        <Lottie animationData={SuccessAnimation} loop={false} style={{ width: '50%' }} />
        <Flex vertical align={'center'} gap={'small'}>
          <Title className={'m-0'}>Pesanan diterima</Title>
          <Button
            type={'link'}
            onClick={() => {
              navigate(`/${ORDERS_ROUTE}`);
            }}
          >
            Klik disini untuk melihat status pesanan Anda
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex vertical className={'checkout-page-root min-h-screen'}>
      <Header title={'Checkout Pesanan'} showBackButton={true} />

      <Flex flex={1} vertical className={'p-6 pt-2'} gap={'large'}>
        <DeliveryAddress label={defaultAddress.label} address={defaultAddress.address} />

        <OrderSummary />

        <Card>
          <Flex gap={'small'} align={'center'} className={'mb-6'}>
            <Text className={'font-bold m-0'}>Catatan untuk kurir</Text>
            <Text className={'text-sm'}>(Opsional)</Text>
          </Flex>
          <Input
            placeholder="misalnya: titip di lobby aja ya"
            maxLength={160}
            value={noteToDriver}
            onChange={(e) => setNoteToDriver(e.target.value)}
          />
        </Card>

        <PaymentMethods />

        <ApplyCoupon coupon={coupon} setCoupon={setCoupon} clearCoupon={clearCoupon} />

        <OrderPricingBreakdown
          couponCode={coupon?.code}
          isLoading={isFetching}
          deliveryFee={data?.delivery_fee}
          serviceFee={data?.service_fee}
          subtotal={getSubTotalPrice()}
          deliveryDiscount={deliveryDiscount}
          orderDiscount={orderDiscount}
          total={calculateTotalPrice()}
        />

        <OrderConfirmation
          visible={confirmationVisible}
          onClose={() => setConfirmationVisible(false)}
          onConfirmed={checkout}
        />
      </Flex>

      <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
        <Button
          type={'primary'}
          className={'w-full'}
          onClick={onActionButtonClicked}
          loading={isSubmitOrderPending}
          disabled={!data}
        >
          {renderButtonText()}
        </Button>
      </Flex>
    </Flex>
  );
}
