import React from 'react';
import { Card, Divider, Flex, Timeline, Typography } from 'antd';
import DestinationPicker from '@pages/KirimAjaPage/components/DestinationPicker.tsx';
import { IShipmentOrder } from '@core/types/kirim_aja/IShipmentOrder.ts';

interface ShipmentDetailsProps {
  order: IShipmentOrder;
}

const { Text } = Typography;

export default function ShipmentDetails(props: ShipmentDetailsProps) {
  const { order } = props;

  return (
    <React.Fragment>
      <Card className={'w-full h-fit app-timeline'}>
        <Timeline
          className={'w-full mt-2'}
          items={[
            {
              children: <Text className={'ml-2'}>{order.sender_address}</Text>,
            },
            ...order.destinations.map((i) => ({
              children: (
                <DestinationPicker
                  id={i.id}
                  address={i.address}
                  allowDelete={false}
                  onClick={() => null}
                  onDelete={() => null}
                />
              ),
              color: '#1677ff',
            })),
          ]}
        />
      </Card>

      <Card>
        <Text className={'text-sm font-bold'}>Pengirim</Text>
        <Flex justify={'space-between'}>
          <Text className={'text-sm'}>{order.sender_name}</Text>
          <Text className={'text-sm'}>{order.sender_phone}</Text>
        </Flex>

        <Divider className={'my-3'} />
        <Text className={'text-sm font-bold'}>Penerima</Text>
        <Flex justify={'space-between'}>
          <Text className={'text-sm'}>{order.recipient_name}</Text>
          <Text className={'text-sm'}>{order.recipient_phone}</Text>
        </Flex>
      </Card>

      <Card>
        <Text className={'text-sm font-bold'}>Detail Barang</Text>
        <Flex justify={'space-between'}>
          <Text className={'text-sm'}>{order.item_details}</Text>
          <Text className={'text-sm'}>{order.item_weight} kg</Text>
        </Flex>
      </Card>
    </React.Fragment>
  );
}
