enum OrderStatus {
  CANCELED,
  WAITING_FOR_CUSTOMER_PAYMENT,
  WAITING_FOR_PAYMENT_VERIFICATION,
  WAITING_FOR_MERCHANT_CONFIRMATION,
  SEARCHING_FOR_DRIVER,
  MERCHANT_PREPARING_ORDER,
  READY_TO_PICKUP,
  ORDER_RECEIVED_BY_DRIVER,
  ON_DELIVERY,
  COMPLETED,
}

export default OrderStatus;
