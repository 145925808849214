import { Card, Flex, Result, Skeleton } from 'antd';
import Header from '@components/Header.tsx';
import { useParams } from 'react-router-dom';
import OrderStatusTag from '@pages/OrdersPage/components/OrderStatusTag.tsx';
import PendingPayment from '@pages/OrdersPage/components/PendingPayment.tsx';
import OrderPricingBreakdown from '@components/OrderPricingBreakdown';
import React, { useEffect, useState } from 'react';
import CancelOrder from '@pages/OrdersPage/components/CancelOrder.tsx';
import CancelationData from '@pages/OrdersPage/components/CancelationData.tsx';
import { useGetShipmentOrder } from '@services/kirim_aja.service.ts';
import ShipmentDetails from '@pages/OrdersPage/ShipmentOrderDetailPage/components/ShipmentDetails.tsx';
import OrderPaymentMethod from '@pages/OrdersPage/ShipmentOrderDetailPage/components/OrderPaymentMethod.tsx';
import OrderStatus from '@core/enums/kirim_aja/OrderStatus.ts';
import ShipmentOrderRating from '@pages/OrdersPage/ShipmentOrderDetailPage/components/ShipmentOrderRating.tsx';

export default function ShipmentOrderDetailPage() {
  const { orderId } = useParams();
  const { data, isFetching, isPending, refetch } = useGetShipmentOrder(orderId ?? '');
  const [title, setTitle] = useState('Memuat data...');

  useEffect(() => {
    if (!data) return;
    setTitle(data.order_number);
  }, [data]);

  return (
    <Flex vertical style={{ backgroundColor: '#F7F7F7' }} className={'min-h-screen'}>
      <Header
        showBackButton
        title={title}
        accessoryRight={!data ? null : <OrderStatusTag orderType={'shipment'} status={data.status} />}
      />

      <Flex flex={1} className={'px-6 pb-6'} gap={'large'} vertical>
        {isFetching && (
          <Card>
            <Skeleton active />
          </Card>
        )}

        {data && (
          <React.Fragment>
            {data.status === OrderStatus.COMPLETED && !data.is_rated && (
              <ShipmentOrderRating
                driverName={data.driver.name}
                driverPhoto={data.driver.photo_path}
                orderId={data.id}
              />
            )}

            <CancelationData order={data} />

            <PendingPayment
              orderType={'shipment'}
              orderId={data.id}
              orderDate={data.created_at}
              lastUpdateDate={data.updated_at}
              totalAmount={data.total}
              paymentMethod={data.payment_method}
              paymentStatus={data.payment_status}
            />

            <ShipmentDetails order={data} />

            <OrderPaymentMethod paymentMethod={data.payment_method} />

            <OrderPricingBreakdown
              isLoading={false}
              deliveryFee={data.delivery_fee}
              serviceFee={data.service_fee}
              total={data.total}
              orderDiscount={0}
              deliveryDiscount={0}
            />
          </React.Fragment>
        )}

        {!isPending && !data && <Result status="404" title="404" subTitle="Order data not found." />}
      </Flex>

      {data && (
        <CancelOrder orderType={'shipment'} orderId={data.id} status={data.status} onCanceled={() => refetch()} />
      )}
    </Flex>
  );
}
