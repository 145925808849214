import FullscreenModal from '@components/FullscreenModal';
import { Button, Card, Flex, Input, Typography } from 'antd';
import Header from '@components/Header.tsx';
import MapWithMarker from '@components/MapWithMarker.tsx';
import { useUserStore } from '@/stores/user.store.ts';
import { ChangeEvent, useState } from 'react';
import { Location } from '@services/kirim_aja.service.ts';

interface MapPickerProps {
  visible: boolean;
  onClose: () => void;
  onConfirmed: (location: Location) => void;
}

const { Text } = Typography;

export default function MapPicker(props: MapPickerProps) {
  const { visible, onClose, onConfirmed } = props;
  const defaultAddress = useUserStore((state) => state.defaultAddress);
  const [location, setLocation] = useState<Location>({
    address: '',
    latitude: defaultAddress.latitude,
    longitude: defaultAddress.longitude,
  });

  const handleClose = () => {
    // Reset label
    setLocation({
      address: '',
      latitude: defaultAddress.latitude,
      longitude: defaultAddress.longitude,
    });

    onClose();
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation((prevState) => ({ ...prevState, address: e.target.value }));
  };

  const onMarkerDragEnd = (latitude: number, longitude: number) => {
    setLocation((prevState) => ({ ...prevState, latitude, longitude }));
  };

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'flex-1'} style={{ backgroundColor: '#F5F5F5' }}>
        <Header title={'Pilih Lokasi'} showBackButton onBackButtonClicked={handleClose} />

        <Flex flex={1} className={'px-6 pb-6'} vertical gap={'middle'}>
          <Card className={'w-full text-center'}>
            <Input placeholder={'Alamat Lengkap'} onChange={onChangeText} value={location.address} />
          </Card>

          <Card className={'w-full text-center'}>
            <Text className={'text-center'}>Mohon geser pin (merah) sesuai dengan lokasi-mu saat ini.</Text>
          </Card>

          <Card>
            <MapWithMarker height={360} onCoordinateChanged={onMarkerDragEnd} />
          </Card>
        </Flex>

        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button
            type={'primary'}
            className={'w-full'}
            disabled={!location.address}
            onClick={() => {
              handleClose();
              onConfirmed(location);
            }}
          >
            Pilih Lokasi
          </Button>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
