import { authorizedClient } from './api.service.ts';
import { AxiosResponse } from 'axios';
import { IMerchant } from '@/core/types/IMerchant.ts';
import { API_PATH } from '@/core/constants';

export interface GetNearbyMerchantsResponse {
  merchants: any;
}

export const getNearbyMerchants = async () => {
  return authorizedClient
    .get(API_PATH.GET_NEARBY_MERCHANTS, { params: { perPage: 5 } })
    .then((response: AxiosResponse<GetNearbyMerchantsResponse>) => response.data);
};

export interface GetMerchantsResponse {
  merchant: IMerchant;
}

export const getMerchant = async (merchantId: string) => {
  return authorizedClient.get(API_PATH.getMerchant(merchantId)).then((response: AxiosResponse<GetMerchantsResponse>) => response.data);
};

export interface GetMerchantsDistanceResponse {
  data: {
    delivery_fee: number;
    service_fee: number;
    distance: number;
    duration: number;
  };
}

export const getMerchantDistance = async (merchantId: string) => {
  return authorizedClient
    .get(API_PATH.getMerchantDistance(merchantId))
    .then((response: AxiosResponse<GetMerchantsDistanceResponse>) => response.data.data);
};
