import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface CoreState {
  currentPage: string;
  setCurrentPage: (page: string) => void;
}

export const useCoreStore = create<CoreState>()(
  persist(
    (set) => ({
      currentPage: '/',
      setCurrentPage: (currentPage) => set(() => ({ currentPage })),
    }),
    {
      name: 'core-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
