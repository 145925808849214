import clsx from 'clsx';
import { displayPrice } from '@/utils';
import { Flex, Typography } from 'antd';
import { IProduct } from '@core/types/IProduct.ts';

const { Text } = Typography;

interface DiscountedPriceProps {
  product: IProduct;
}

export default function DiscountedPrice(props: DiscountedPriceProps) {
  const { product } = props;

  return (
    <Flex vertical>
      <Text
        className={clsx('font-bold text-sm strike', {
          'line-through': product.discount_amount > 0,
          'text-error': product.discount_amount > 0,
          'text-primary': product.discount_amount === 0,
        })}
      >
        {displayPrice(product.sell_price)}
      </Text>
      {product.discount_amount > 0 && (
        <Text className={'font-bold text-primary text-sm'}>
          {displayPrice(product.sell_price - product.discount_amount)}
        </Text>
      )}
    </Flex>
  );
}
