import { Avatar, Button, Col, Divider, Flex, Input, Modal, Rate, Row, Typography } from 'antd';
import TouchableOpacity from '@components/TouchableOpacity';
import React, { useEffect, useState } from 'react';
import FullscreenModal from '@components/FullscreenModal';
import Header from '@components/Header.tsx';
import { useRateOrder } from '@services/order.service.ts';

const { TextArea } = Input;
const { Text, Title } = Typography;

interface FoodOrderRatingProps {
  driverName: string;
  driverPhoto: string;
  merchantName: string;
  merchantLogo: string;
  orderId: string;
}

export default function FoodOrderRating(props: FoodOrderRatingProps) {
  const { driverName, driverPhoto, merchantName, merchantLogo, orderId } = props;

  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);

  const [ratingData, setRatingData] = useState({
    driverRating: 5,
    driverReview: '',
    merchantRating: 5,
    merchantReview: '',
  });

  const { mutate, data, isPending, isError, failureReason } = useRateOrder();

  useEffect(() => {
    if (!data) return;

    Modal.success({
      title: 'Pesanan berhasil direview',
      content: 'Terima kasih telah memberikan ulasan untuk pesanan kamu!',
      centered: true,
      okText: 'Oke',
      onOk: () => {
        location.reload();
      },
    });
  }, [data]);

  useEffect(() => {
    if (!isError) return;

    Modal.error({
      title: 'Pesanan gagal direview',
      content: failureReason?.message ?? 'Terjadi kesalahan sistem, silahkan coba lagi nanti.',
      centered: true,
      okText: 'Oke',
    });
  }, [isError]);

  const goBack = () => {
    setPage(1);
    setVisible(false);
  };

  const setRating = (type: 'driver' | 'merchant', value: number) => {
    setRatingData((prev) => ({
      ...prev,
      [`${type}Rating`]: value,
    }));
  };

  const setReview = (type: 'driver' | 'merchant', value: string) => {
    setRatingData((prev) => ({
      ...prev,
      [`${type}Review`]: value,
    }));
  };

  const submit = () => {
    mutate({
      order_id: orderId,
      driver_rating: ratingData.driverRating,
      driver_review: ratingData.driverReview,
      merchant_rating: ratingData.merchantRating,
      merchant_review: ratingData.merchantReview,
    });
  };

  const renderPage = () => {
    if (page === 1) {
      return (
        <React.Fragment>
          <Flex flex={1} vertical className={'px-6 pb-6'} align={'center'}>
            <Avatar
              src={driverPhoto}
              style={{
                width: 100,
                height: 100,
              }}
            />

            <Title level={2} className={'mt-6 text-center'}>
              Beri nilai untuk pelayanan kurir {driverName}
            </Title>

            <Text className={'text-secondary text-center mt-2'}>
              Bagaimana pengiriman pesanan kamu dari {merchantName}?
            </Text>

            <Rate
              allowHalf
              value={ratingData.driverRating}
              allowClear={false}
              className={'mt-6'}
              style={{ fontSize: 36 }}
              onChange={(value) => setRating('driver', value)}
            />

            <Divider />

            <TextArea
              variant={'filled'}
              placeholder="Misalnya: Kurirnya ramah 🤩, pesanan sampai tepat waktu, dan pesanan saya dalam kondisi baik. Terima kasih!"
              autoSize={{ minRows: 3, maxRows: 4 }}
              maxLength={160}
              value={ratingData.driverReview}
              onChange={(e) => setReview('driver', e.target.value)}
            />
          </Flex>

          <Flex className={'px-6 pb-6'} gap={'large'} vertical>
            <Button type={'primary'} onClick={() => setPage(2)}>
              Selanjutnya
            </Button>
          </Flex>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Flex flex={1} vertical className={'px-6 pb-6'} align={'center'}>
          <Avatar
            shape={'square'}
            src={merchantLogo}
            style={{
              width: 96,
              height: 96,
            }}
          />

          <Title level={2} className={'mt-6 text-center'}>
            Beri nilai untuk makanan dari {merchantName}
          </Title>

          <Text className={'text-secondary text-center mt-2'}>
            Apakah kamu menikmati makanannya? Beri rating dan ulasan untuk restoran, masukan kamu sangat penting untuk
            meningkatkan pelayanan kami.
          </Text>

          <Rate
            allowHalf
            value={ratingData.merchantRating}
            allowClear={false}
            className={'mt-6'}
            style={{ fontSize: 36 }}
            onChange={(value) => setRating('merchant', value)}
          />

          <Divider />

          <TextArea
            variant={'filled'}
            placeholder="Misalnya: Makanannya enak 😍😍, porsinya pas, dan harga terjangkau. Terima kasih!"
            autoSize={{ minRows: 3, maxRows: 4 }}
            maxLength={160}
            value={ratingData.merchantReview}
            onChange={(e) => setReview('merchant', e.target.value)}
          />
        </Flex>

        <Row className={'px-6 pb-6'} gutter={16}>
          <Col span={12}>
            <Button type={'default'} className={'w-full'} onClick={goBack} disabled={isPending}>
              Batal
            </Button>
          </Col>
          <Col span={12}>
            <Button type={'primary'} className={'w-full'} onClick={submit} loading={isPending}>
              Kirim
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Flex justify={'center'}>
        <TouchableOpacity onClick={() => setVisible(true)}>
          <Text className={'text-primary font-bold'}>⭐ Beri nilai untuk pesanan ini ⭐</Text>
        </TouchableOpacity>
      </Flex>

      <FullscreenModal visible={visible}>
        <Flex flex={1} vertical className={'flex-1 bg-white'}>
          <Header showBackButton onBackButtonClicked={goBack} />
          {renderPage()}
        </Flex>
      </FullscreenModal>
    </React.Fragment>
  );
}
