import { Col, Flex, Image, Row, Typography } from 'antd';
import friedRiceUrl from '@assets/images/fried-rice.png';
import pizzaUrl from '@assets/images/pizza.png';
import burgerUrl from '@assets/images/cheese-burger.png';
import noodleUrl from '@assets/images/ramen.png';
import iceCreamUrl from '@assets/images/ice-cream.png';
import chickenUrl from '@assets/images/chicken-leg.png';
import cakeUrl from '@assets/images/cake.png';
import meatUrl from '@assets/images/meat.png';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';

const { Title, Text } = Typography;

export default function FoodCategories() {
  const navigate = useNavigate();

  // const onShowAllClick = () => {
  //   navigate(FOOD_CATEGORIES_ROUTE);
  // };

  return (
    <Flex vertical className={'mt-8'}>
      <Flex justify={'space-between'} align={'center'}>
        <Title level={3} style={{ margin: 0 }}>
          Kategori Pilihan 🔥
        </Title>
      </Flex>

      <Row gutter={[2, { xs: 28, sm: 16, md: 24, lg: 32 }]} className={'mt-4'}>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('nasi goreng'))}>
          <Flex vertical align={'center'}>
            <Image src={friedRiceUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Nasi Goreng
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('pizza'))}>
          <Flex vertical align={'center'}>
            <Image src={pizzaUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Pizza
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('burger'))}>
          <Flex vertical align={'center'}>
            <Image src={burgerUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Burger
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('mie'))}>
          <Flex vertical align={'center'}>
            <Image src={noodleUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Mie
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('ice cream'))}>
          <Flex vertical align={'center'}>
            <Image src={iceCreamUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Es Krim
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('ayam'))}>
          <Flex vertical align={'center'}>
            <Image src={chickenUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Ayam
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('kue'))}>
          <Flex vertical align={'center'}>
            <Image src={cakeUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Kue
            </Text>
          </Flex>
        </Col>
        <Col className="gutter-row" span={6} onClick={() => navigate(PageRoutes.foodSearchWithTerm('steak'))}>
          <Flex vertical align={'center'}>
            <Image src={meatUrl} preview={false} width={42} />
            <Text strong className={'text-xs mt-2'}>
              Steak
            </Text>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}
