import { Avatar, Card, Divider, Flex, Typography } from 'antd';
import { LocationFilledIcon } from '@components/AppIcons';

const { Title, Text } = Typography;

interface DeliveryAddressProps {
  label?: string;
  address?: string;
}

export default function DeliveryAddress(props: DeliveryAddressProps) {
  const { label, address } = props;

  return (
    <Card>
      <Title level={3} className={'m-0'}>
        Antarkan ke
      </Title>
      <Divider />
      <Flex gap={'middle'} align={'center'}>
        <Avatar
          size={'large'}
          icon={<LocationFilledIcon className={'text-lg'} />}
          className={'text-white bg-primary'}
        />
        <Flex vertical gap={'small'} flex={1}>
          <Title level={3} className={'m-0'}>
            {label}
          </Title>
          <Text className={'text-sm'}>{address}</Text>
        </Flex>
        {/*<TouchableOpacity>*/}
        {/*  <RightOutlined className={'text-primary'} />*/}
        {/*</TouchableOpacity>*/}
      </Flex>
    </Card>
  );
}
