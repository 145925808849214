import { Flex } from 'antd';
import NotFoundSvg from '@assets/images/not-found.svg?react';

export function NotFoundError() {
  return (
    <Flex align={'center'} justify={'center'} className={'min-h-screen p-6'}>
      <NotFoundSvg />
    </Flex>
  );
}
