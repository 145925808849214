export const API_VERSION = import.meta.env.VITE_API_VERSION;
export const API_HOST = import.meta.env.VITE_API_HOST;

export class API_PATH {
  static LOGIN = `${API_VERSION}/auth/login`;
  static VERIFY_OTP = `${API_VERSION}/auth/verify-otp`;
  static PROFILE = `${API_VERSION}/profile`;
  static UPLOAD_PAYMENT_PROOF = `${API_VERSION}/orders/:orderId/upload-payment-proof`;
  static GET_MY_ADDRESSES = `${API_VERSION}/customers/addresses`;
  static UPDATE_ADDRESS = `${API_VERSION}/customers/addresses/:addressId`;
  static SET_DEFAULT_ADDRESS = `${API_VERSION}/customers/addresses/set-default/:addressId`;
  static GET_PROMOTIONS = `${API_VERSION}/promotions`;
  static GET_COUPON = `${API_VERSION}/coupons/:code`;

  // Makan-Aja
  static GET_MERCHANT = `${API_VERSION}/merchants/:merchantId`;
  static GET_MERCHANT_DISTANCE = `${API_VERSION}/merchants/get-distance/:merchantId`;
  static GET_NEARBY_MERCHANTS = `${API_VERSION}/merchants/nearby`;
  static GET_MENU_BY_TERM = `${API_VERSION}/menus/search`;
  static GET_RANDOM_MENUS = `${API_VERSION}/menus/randomize`;
  static SUBMIT_ORDER = `${API_VERSION}/orders/submit`;
  static GET_ORDERS = `${API_VERSION}/orders`;
  static GET_ORDER = `${API_VERSION}/orders/:orderId`;
  static RATE_ORDER = `${API_VERSION}/orders/:orderId/rate`;

  // Kirim-Aja
  static GET_SHIPMENT_ORDER = `${API_VERSION}/kirim-aja/orders/:orderId`;
  static GET_SHIPMENT_ORDERS = `${API_VERSION}/kirim-aja/orders`;
  static SUBMIT_SHIPPING_ORDER = `${API_VERSION}/kirim-aja/orders/submit`;
  static CALCULATE_SHIPPING_FEES = `${API_VERSION}/kirim-aja/orders/calculate-fees`;
  static UPLOAD_SHIPMENT_PAYMENT_PROOF = `${API_VERSION}/kirim-aja/orders/:orderId/upload-payment-proof`;
  static RATE_SHIPMENT_ORDER = `${API_VERSION}/kirim-aja/orders/:orderId/rate`;

  // Market-Aja
  static GET_STORE = `${API_VERSION}/stores/:storeSlug`;
  static GET_STORE_SALE = `${API_VERSION}/stores/sale/:storeSlug`;
  static GET_STORE_DISTANCE = `${API_VERSION}/stores/get-distance/:storeSlug`;
  static GET_PRODUCT_BY_TERM = `${API_VERSION}/products/search`;
  static GET_STORE_PRODUCTS_BY_TERM = `${API_VERSION}/products/:storeSlug/search`;
  static GET_RANDOM_PRODUCTS = `${API_VERSION}/products/randomize`;
  static SUBMIT_STORE_ORDER = `${API_VERSION}/market-aja/orders/submit`;
  static GET_STORE_ORDERS = `${API_VERSION}/market-aja/orders`;
  static GET_STORE_ORDER = `${API_VERSION}/market-aja/orders/:orderId`;
  static UPLOAD_STORE_PAYMENT_PROOF = `${API_VERSION}/market-aja/orders/:orderId/upload-payment-proof`;

  static getMerchant(merchantId: string): string {
    return this.GET_MERCHANT.replace(':merchantId', merchantId);
  }

  static getMerchantDistance(merchantId: string): string {
    return this.GET_MERCHANT_DISTANCE.replace(':merchantId', merchantId);
  }

  static getStore(storeSlug: string): string {
    return this.GET_STORE.replace(':storeSlug', storeSlug);
  }

  static getStoreSale(storeSlug: string): string {
    return this.GET_STORE_SALE.replace(':storeSlug', storeSlug);
  }

  static getStoreOrder(orderId: string): string {
    return this.GET_STORE_ORDER.replace(':orderId', orderId);
  }

  static getStoreDistance(storeSlug: string): string {
    return this.GET_STORE_DISTANCE.replace(':storeSlug', storeSlug);
  }

  static getStoreProducts(storeSlug: string): string {
    return this.GET_STORE_PRODUCTS_BY_TERM.replace(':storeSlug', storeSlug);
  }

  static getOrder(orderId: string): string {
    return this.GET_ORDER.replace(':orderId', orderId);
  }

  static rateOrder(orderId: string): string {
    return this.RATE_ORDER.replace(':orderId', orderId);
  }

  static rateShipmentOrder(orderId: string): string {
    return this.RATE_SHIPMENT_ORDER.replace(':orderId', orderId);
  }

  static getShipmentOrder(orderId: string): string {
    return this.GET_SHIPMENT_ORDER.replace(':orderId', orderId);
  }

  static uploadPaymentProof(orderId: string): string {
    return this.UPLOAD_PAYMENT_PROOF.replace(':orderId', orderId);
  }

  static uploadStorePaymentProof(orderId: string): string {
    return this.UPLOAD_STORE_PAYMENT_PROOF.replace(':orderId', orderId);
  }

  static uploadShipmentPaymentProof(orderId: string): string {
    return this.UPLOAD_SHIPMENT_PAYMENT_PROOF.replace(':orderId', orderId);
  }

  static updateAddress(addressId: string): string {
    return this.UPDATE_ADDRESS.replace(':addressId', addressId);
  }

  static setDefaultAddress(addressId: string): string {
    return this.SET_DEFAULT_ADDRESS.replace(':addressId', addressId);
  }

  static getCoupon(code: string): string {
    return this.GET_COUPON.replace(':code', code);
  }
}
