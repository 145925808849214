import { Button, Card, Flex, Modal, Radio, RadioChangeEvent, Typography } from 'antd';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { JSX, useState } from 'react';
import { BankIcon, BankNoteDollarIcon, CloseIcon, WalletFilledIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import { QrcodeOutlined, RightOutlined } from '@ant-design/icons';
import Header from '@components/Header.tsx';
import { useCartStore } from '@/stores/cart.store.ts';
import PaymentMethod from '@/core/enums/PaymentMethod';

const { Text } = Typography;

interface PaymentMethodOption {
  label: string;
  value: PaymentMethod;
  icon: JSX.Element;
  enabled: boolean;
  isComingSoon: boolean;
}

const paymentMethods: PaymentMethodOption[] = [
  {
    label: 'Dompet Saya',
    value: PaymentMethod.WALLET,
    icon: <WalletFilledIcon className={'text-primary text-xl'} />,
    enabled: false,
    isComingSoon: true,
  },
  {
    label: 'Tunai (COD)',
    value: PaymentMethod.CASH_ON_DELIVERY,
    icon: <BankNoteDollarIcon className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'Transfer Bank',
    value: PaymentMethod.BANK_TRANSFER,
    icon: <BankIcon className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'QRIS',
    value: PaymentMethod.QRIS,
    icon: <QrcodeOutlined className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
];

export default function PaymentMethods() {
  const [visible, setVisible] = useState<boolean>(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const setPaymentMethod = useCartStore((state) => state.setPaymentMethod);
  const [value, setValue] = useState(paymentMethods[1]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    setPaymentMethod(e.target.value.value);
  };

  return (
    <Flex vertical>
      <Card>
        <Flex gap={'small'} align={'center'}>
          {value.icon}
          <Flex flex={1}>
            <Text className={'text-sm'}>Metode Pembayaran</Text>
          </Flex>
          <TouchableOpacity onClick={() => setVisible(true)}>
            <Flex gap={'small'}>
              <Text strong className={'text-primary text-sm'}>
                {value.label}
              </Text>
              <RightOutlined className={'text-primary'} />
            </Flex>
          </TouchableOpacity>
        </Flex>
      </Card>

      <Modal
        centered
        open={visible}
        width={windowWidth}
        style={{ height: windowHeight }}
        closable={false}
        footer={null}
        className={'app-modal bg-transparent'}
      >
        <Flex vertical flex={1}>
          <Header
            title={'Metode Pembayaran'}
            accessoryLeft={
              <TouchableOpacity onClick={() => setVisible(false)}>
                <CloseIcon style={{ fontSize: 24 }} />
              </TouchableOpacity>
            }
          />
          <Flex vertical flex={1}>
            <Radio.Group onChange={onChange} value={value}>
              <Flex vertical gap={'middle'} className={'px-6 py-2'}>
                {paymentMethods.map((method) => (
                  <Card>
                    <Flex gap={'middle'} align={'center'}>
                      {method.icon}
                      <Flex flex={1}>
                        <Text className={'font-bold'} disabled={!method.enabled}>
                          {method.label}
                        </Text>
                      </Flex>
                      {method.isComingSoon ? (
                        <Text className={'m-0 p-0 text-xs font-bold text-error'}>Segera hadir</Text>
                      ) : (
                        <Radio value={method} />
                      )}
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </Radio.Group>
          </Flex>
          <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
            <Button type={'primary'} className={'w-full'} onClick={() => setVisible(false)}>
              Oke
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}
