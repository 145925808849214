import { AdvancedMarker, Map, useAdvancedMarkerRef, useMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';
import { manokwariCoordinate as defaultCoordinate } from '@/utils';
// @ts-expect-error: google-maps-current-location is not typed
import addCurrentLocation from 'google-maps-current-location';

type Coordinate = {
  lat: number;
  lng: number;
};

interface MapWithMarkerProps {
  coordinate?: Coordinate;
  zoom?: number;
  height?: number;
  onCoordinateChanged: (latitude: number, longitude: number) => void;
}

export default function MapWithMarker(props: MapWithMarkerProps) {
  const { coordinate, onCoordinateChanged, zoom = 18, height = 300 } = props;

  const map = useMap();
  const [markerRef, marker] = useAdvancedMarkerRef();

  // Detect prop changes for coordinate
  useEffect(() => {
    updateMapAndMarkerLocation(coordinate ? coordinate : defaultCoordinate);
  }, [coordinate, marker]);

  // Listen to marker drag end event
  useEffect(() => {
    if (!marker) return;

    // Detect realtime user location
    addCurrentLocation(map, {
      watchPositionFn,
    });

    marker.addListener('dragend', markerDragEnd);

    return () => {
      marker.removeEventListener('dragend', markerDragEnd);
    };
  }, [marker]);

  const markerDragEnd = () => {
    if (!marker) return;
    updateMapAndMarkerLocation(marker.position as Coordinate);
    updatePosition(marker.position as Coordinate);
  };

  const updateMapAndMarkerLocation = (coordinate: Coordinate) => {
    console.log(coordinate);
    if (map) {
      map.setCenter(coordinate);
      map.setZoom(zoom);
    }

    if (marker) {
      marker.position = coordinate;
    }
  };

  // @ts-expect-error: updatePos and setError doesn't have type
  const watchPositionFn = async (updatePos) => {
    const appNavigator = window.isNativeApp ? window.nativeNavigator : window.navigator;

    return appNavigator?.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        updateMapAndMarkerLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
        updatePosition({ lat: position.coords.latitude, lng: position.coords.longitude });
        updatePos(position);
      },
      (error: GeolocationPositionError) => {
        if (error.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
          alert('Gagal mendapatkan lokasi kamu: GPS tidak tersedia');
        } else if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
          alert('Mohon izinkan akses GPS/Lokasi kamu');
        } else if (error.code === GeolocationPositionError.TIMEOUT) {
          alert('Gagal mendapatkan lokasi kamu, coba lagi nanti!');
        } else {
          alert('Gagal mendapatkan lokasi kamu: ' + error);
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 30000,
      }
    );
  };

  const updatePosition = (coordinate: Coordinate) => {
    onCoordinateChanged(coordinate.lat, coordinate.lng);
  };

  return (
    <div className={'w-full'} style={{ height }}>
      <Map
        defaultCenter={defaultCoordinate}
        defaultZoom={zoom}
        gestureHandling={'greedy'}
        streetViewControl={false}
        mapTypeControl={false}
        fullscreenControl={false}
        mapId={'my-map-id'}
      >
        <AdvancedMarker draggable ref={markerRef} />
      </Map>
    </div>
  );
}
