import { Button, Flex } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import IProductCategory from '@core/types/IProductCategory.ts';

interface ProductCategoriesProps {
  categories?: IProductCategory[];
  onCategorySelected: (category?: string) => void;
}

export default function ProductCategories(props: ProductCategoriesProps) {
  const { categories, onCategorySelected } = props;
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
    isMounted: !!ref.current,
  }); // Now we pass the reference to the useDraggable hook:

  const [selectedCategory, setSelectedCategory] = useState<IProductCategory>();

  useEffect(() => {
    onCategorySelected(selectedCategory?.id);
  }, [selectedCategory]);

  if (!categories) return null;

  return (
    <Flex
      className="px-4 pt-6 pb-2 overflow-x-scroll scrollbar-hide"
      {...events}
      ref={ref}
      gap={'small'}
      style={{ backgroundColor: '#F5F5F5' }}
    >
      {categories.map((i) => (
        <Button
          key={i.id}
          size={'small'}
          type={selectedCategory?.id === i.id ? 'primary' : 'default'}
          className={'text-sm'}
          onClick={() => {
            setSelectedCategory(selectedCategory?.id === i.id ? undefined : i);
          }}
        >
          {i.name}
        </Button>
      ))}
    </Flex>
  );
}
