import { useUserStore } from '@/stores/user.store.ts';
import FullscreenModal from '@components/FullscreenModal';
import { Flex, Typography } from 'antd';
import Header from '@components/Header.tsx';
import { CloseIcon } from '@components/AppIcons';
import Address from '@components/Address.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import React, { useState } from 'react';
import MapPicker from '@pages/KirimAjaPage/components/MapPicker.tsx';
import { Location } from '@services/kirim_aja.service.ts';

interface LocationPickerProps {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSelected: (location: Location) => void;
}

const { Text } = Typography;

export default function LocationPicker(props: LocationPickerProps) {
  const { title, visible, onClose, onSelected } = props;
  const addresses = useUserStore((state) => state.addresses);

  const [mapPickerVisible, setMapPickerVisible] = useState(false);

  const onLocationSelected = (location: Location) => {
    onSelected(location);
    onClose();
  };

  return (
    <React.Fragment>
      <FullscreenModal visible={visible}>
        <Flex flex={1} vertical>
          <Header
            title={title}
            showBackButton
            onBackButtonClicked={onClose}
            backButtonIcon={<CloseIcon style={{ fontSize: 24, marginTop: 4 }} />}
          />

          <Flex flex={1} vertical className={'px-6 pb-6'} gap={'middle'}>
            {addresses.map((address) => (
              <TouchableOpacity key={address.id} onClick={() => onLocationSelected(address)}>
                <Address address={address} showDefault={false} />
              </TouchableOpacity>
            ))}

            <Flex justify={'center'} className={'mt-3'}>
              <TouchableOpacity onClick={() => setMapPickerVisible(true)}>
                <Text className={'font-bold text-primary text-sm'}>atau pilih lokasi dari maps</Text>
              </TouchableOpacity>
            </Flex>
          </Flex>
        </Flex>
      </FullscreenModal>

      <MapPicker
        visible={mapPickerVisible}
        onClose={() => setMapPickerVisible(false)}
        onConfirmed={onLocationSelected}
      />
    </React.Fragment>
  );
}
