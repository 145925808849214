import { Modal } from 'antd';
import useWindowSize from '@utils/hooks/useWindowSize.ts';

interface FullscreenModalProps {
  visible: boolean;
  children: React.ReactNode;
}

export default function FullscreenModal(props: FullscreenModalProps) {
  const { visible, children } = props;
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <Modal
      centered
      open={visible}
      width={windowWidth}
      style={{ height: windowHeight }}
      closable={false}
      footer={null}
      className={'app-modal bg-transparent'}
    >
      {children}
    </Modal>
  );
}
