import AppRoute from './routes/AppRoute.tsx';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import theme from './configs/theme.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

const queryClient = new QueryClient();

export default function App() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <ConfigProvider theme={{ ...theme, cssVar: true }}>
        <QueryClientProvider client={queryClient}>
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
            <AppRoute />
          </APIProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}
