import { Button, Divider, Flex, Image, Skeleton, Tag, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getMerchant, getMerchantDistance } from '@/services/merchant.service.ts';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { displayPrice, humanizeDistance } from '@/utils';
import PageSkeleton from '@pages/MerchantPage/components/PageSkeleton.tsx';
import { ArrowLeftIcon, BikeIcon, LocationOutlinedIcon } from '@components/AppIcons';
import { NotFoundError } from '@components/Errors';
import MenuList from '@pages/MerchantPage/components/MenuList.tsx';
import Header from '@components/Header.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { useCartStore } from '@/stores/cart.store.ts';
import { useEffect, useState } from 'react';
import PaymentMethod from '@core/enums/PaymentMethod.ts';
import { track } from '@utils/analytic.util.ts';
import { CalendarOutlined } from '@ant-design/icons';
import MenuSearch from '@pages/MerchantPage/components/MenuSearch.tsx';
import { PageRoutes } from '@/routes/AppRoute.tsx';

const { Title, Text } = Typography;

export default function MerchantPage() {
  const { merchantId } = useParams();
  const [, windowHeight] = useWindowSize();
  const navigate = useNavigate();
  const currentMerchant = useCartStore((state) => state.merchant);
  const setMerchant = useCartStore((state) => state.setMerchant);
  const cart = useCartStore((state) => state.cart);
  const clearCart = useCartStore((state) => state.clearCart);
  const setPaymentMethod = useCartStore((state) => state.setPaymentMethod);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, isFetching, isPending } = useQuery({
    queryKey: ['getMerchant', merchantId],
    queryFn: () => getMerchant(merchantId!),
    enabled: !!merchantId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: location, isFetching: isFetchingLocation } = useQuery({
    queryKey: ['getMerchantDistance', merchantId],
    queryFn: () => getMerchantDistance(merchantId!),
    enabled: !!merchantId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  // Reset to default payment method
  useEffect(() => {
    setPaymentMethod(PaymentMethod.CASH_ON_DELIVERY);
  }, []);

  // Set merchant data only when its available
  useEffect(() => {
    if (!data) return;
    setMerchant(data.merchant);

    // If different mex are loaded
    if (currentMerchant && currentMerchant.id !== data.merchant.id) {
      clearCart();
    }
  }, [data]);

  const getTotalCartItems = (): number => {
    if (!cart.length || !merchant) return 0;

    return (
      cart.reduce((prev, curr) => {
        return prev + curr.quantity;
      }, 0) ?? 0
    );
  };

  const getTotalPrice = (): number => {
    if (!cart.length || !merchant) return 0;

    return (
      cart.reduce((prev, curr) => {
        return prev + curr.totalPrice;
      }, 0) ?? 0
    );
  };

  const checkout = () => {
    track('VIEW_CART');
    navigate(PageRoutes.CHECKOUT);
  };

  if (isFetching) {
    return <PageSkeleton />;
  }

  if (!data && !isPending) {
    return <NotFoundError />;
  }

  const { merchant } = data!;

  return (
    <Flex flex={1} className={'min-h-screen'} vertical>
      <Image src={merchant.banner_image} className={'object-cover'} preview={false} height={windowHeight * 0.25} />
      <Flex className={'absolute'}>
        <Header
          accessoryLeft={
            <TouchableOpacity onClick={() => navigate(-1)}>
              <div
                className={'flex items-center justify-center rounded-full bg-black bg-opacity-50'}
                style={{ height: 36, width: 36 }}
              >
                <ArrowLeftIcon style={{ fontSize: 24, color: 'white' }} />
              </div>
            </TouchableOpacity>
          }
        />
      </Flex>

      <Flex className={'p-6'} vertical>
        <Title className={'m-0'}>{merchant.name}</Title>
        {merchant.description && <Text className={'mt-2'}>{merchant.description}</Text>}
        <Divider />
        <Flex align={'center'} gap={'small'}>
          <BikeIcon className={'text-xl text-primary'} />
          {isFetchingLocation ? (
            <Skeleton.Input active size={'small'} />
          ) : (
            <>
              <Text>{displayPrice(location?.delivery_fee)}</Text>
              <Text className={'text-xs'}>(biaya parkir mungkin berlaku)</Text>
            </>
          )}
        </Flex>
        <Divider />
        <Flex align={'center'} gap={'small'}>
          <LocationOutlinedIcon className={'text-xl text-primary'} />
          {isFetchingLocation ? (
            <Skeleton.Input active size={'small'} />
          ) : (
            <Text>{humanizeDistance(location?.distance)}</Text>
          )}
        </Flex>
        <Divider />
        <Flex align={'center'}>
          <Flex flex={1}>
            <CalendarOutlined className={'text-xl text-primary'} />
            <Text className={'ml-2'}>{merchant.operating_hour}</Text>
          </Flex>
          <Tag color={merchant.is_operating ? 'success' : 'error'}>{merchant.is_operating ? 'BUKA' : 'TUTUP'}</Tag>
        </Flex>
        {merchant.menus && merchant.menus.length >= 10 && <Divider className={'mb-0'} />}
      </Flex>

      {merchant.menus && merchant.menus.length >= 10 && (
        <MenuSearch searchTerm={searchTerm} onChange={(value) => setSearchTerm(value)} />
      )}

      <Flex flex={1} style={{ backgroundColor: '#F5F5F5' }}>
        <MenuList menus={merchant.menus} searchTerm={searchTerm} />
      </Flex>

      {getTotalCartItems() > 0 && (
        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button type={'primary'} className={'w-full'} onClick={checkout}>
            Keranjang - {getTotalCartItems()} item - {displayPrice(getTotalPrice(), false)}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
