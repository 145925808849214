import { useGetMyAddresses } from '@/services/customer.service.ts';
import { Avatar, Flex, Skeleton, Typography } from 'antd';
import { CaretDownFilled, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useUserStore } from '@/stores/user.store.ts';
import ChangeDefaultAddress from '@components/ChangeDefaultAddress';
import TouchableOpacity from '@components/TouchableOpacity';
import { useAuthStore } from '@/stores/auth.store.ts';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';

const { Text } = Typography;

export default function TopHeader() {
  const { data, isFetching } = useGetMyAddresses();
  const { defaultAddress, setAddresses, setDefaultAddress } = useUserStore((state) => state);
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!data) return;

    const defaultAddress = data.find((address) => address.default);
    setAddresses(data);

    if (defaultAddress) {
      setDefaultAddress(defaultAddress!);
    }
  }, [data]);

  const onClose = () => {
    setVisible(false);
  };

  const onAddressClick = () => {
    if (!user) {
      navigate(PageRoutes.LOGIN);
      return;
    }

    setVisible(true);
  };

  if (isFetching) {
    return <Skeleton avatar paragraph={{ rows: 0 }} />;
  }

  return (
    <Flex flex={1} gap={'middle'}>
      <Flex align={'center'}>
        <Avatar size={'large'} icon={<UserOutlined />} />
      </Flex>
      <Flex flex={1}>
        <Flex vertical>
          <Text>Antarkan ke:</Text>
          <TouchableOpacity onClick={onAddressClick}>
            <Text strong>{user ? defaultAddress.label : 'Rumah Saya'}</Text>
            <CaretDownFilled className={'ml-1 text-primary'} />
          </TouchableOpacity>
        </Flex>
      </Flex>
      {/*<Flex gap={'small'}>*/}
      {/*  <Button style={{ width: 40, height: 40, minWidth: 40 }} shape="circle" icon={<BellOutlined />} />*/}
      {/*  <Button style={{ width: 40, height: 40, minWidth: 40 }} shape="circle" icon={<ShoppingCartOutlined />} />*/}
      {/*</Flex>*/}
      <ChangeDefaultAddress visible={visible} onClose={onClose} />
    </Flex>
  );
}
