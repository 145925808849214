import { Carousel, Flex, Image, Skeleton, Typography } from 'antd';
import TouchableOpacity from '@components/TouchableOpacity';
import { useGetPromotions } from '@/services/promotion.service.ts';

const { Title, Text } = Typography;

export default function Promotions() {
  const { data, isFetching } = useGetPromotions();

  if (isFetching) {
    return (
      <Flex className={'my-8'}>
        <Skeleton active paragraph={{ rows: 5 }} />
      </Flex>
    );
  }

  if (!data || !data.length) {
    return null;
  }

  return (
    <Flex vertical className={'mt-8'}>
      <Flex justify={'space-between'} align={'flex-end'}>
        <Title level={3} style={{ margin: 0 }}>
          Penawaran Spesial
        </Title>
        <TouchableOpacity>
          <Text className={'text-sm font-bold text-primary'}>Lihat Semua</Text>
        </TouchableOpacity>
      </Flex>
      <Carousel className={'mt-4'} arrows infinite style={{ borderRadius: 32 }} autoplay autoplaySpeed={5000}>
        {data.map((promotion) => (
          <div key={promotion.id}>
            <Image src={promotion.banner_path} preview={false} className={'rounded-xl'} />
          </div>
        ))}
      </Carousel>
    </Flex>
  );
}
