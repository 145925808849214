import * as React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Button, Result } from 'antd';
import PhoneLoginPage from '@pages/auth/PhoneLoginPage.tsx';
import PhoneOtpPage from '@pages/auth/PhoneOtpPage.tsx';
import { useAuthStore } from '@/stores/auth.store.ts';
import ProfilePage from '@pages/ProfilePage.tsx';
import FoodLayout from '@components/Layouts/FoodLayout';
import SettingsPage from '@pages/SettingsPage';
import OrdersPage from '@pages/OrdersPage';
import FoodCategoriesPage from '@pages/CategoriesPage/FoodCategoriesPage.tsx';
import FoodSearchPage from '@pages/FoodSearchPage';
import HomePage from '@pages/HomePage';
import MerchantPage from '@pages/MerchantPage';
import CheckoutPage from '@pages/CheckoutPage';
import FoodOrderDetailPage from '@pages/OrdersPage/FoodOrderDetailPage';
import MyAddressPage from '@pages/MyAddressPage';
import { tagUser } from '@utils/analytic.util.ts';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import KirimAjaPage from '@pages/KirimAjaPage';
import ShipmentOrderDetailPage from '@pages/OrdersPage/ShipmentOrderDetailPage';
import BelanjaAjaPage from '@pages/BelanjaAjaPage';
import PrivacyPolicyPage from '@pages/PrivacyPolicyPage';
import ProductSearchPage from '@pages/ProductSearchPage';
import StorePage from '@pages/StorePage';
import StoreCheckoutPage from '@pages/StoreCheckoutPage';
import StoreOrderDetailPage from '@pages/OrdersPage/StoreOrderDetailPage';

export class PageRoutes {
  static HOME = `/`;
  static LOGIN = `/login`;
  static FOOD_SEARCH = `/search/food`;
  static PRODUCT_SEARCH = `/search/product`;
  static CHECKOUT = `/checkout`;
  static STORE_CHECKOUT = `/store-checkout`;
  static MY_ADDRESS = `/my-address`;
  static SETTINGS = `/settings`;
  static BELANJA_AJA = `/belanja-aja`;
  static KIRIM_AJA = `/kirim-aja`;
  static PROFILE = '/profile';
  static PRIVACY_POLICY = '/privacy-policy';

  static MY_ORDERS = `/orders`;
  static GET_BELANJA_AJA_ORDER = `/orders/belanja-aja/:orderId`;
  static GET_KIRIM_AJA_ORDER = `/orders/kirim-aja/:orderId`;
  static GET_MAKAN_AJA_ORDER = `/orders/makan-aja/:orderId`;
  static GET_MARKET_AJA_ORDER = `/orders/market-aja/:orderId`;

  static GET_MERCHANT = '/merchant/:merchantId';
  static GET_STORE = '/store/:storeId';

  static getFoodOrder(orderId: string): string {
    return this.GET_MAKAN_AJA_ORDER.replace(':orderId', orderId);
  }

  static getStoreOrder(orderId: string): string {
    return this.GET_MARKET_AJA_ORDER.replace(':orderId', orderId);
  }

  static getShipmentOrder(orderId: string): string {
    return this.GET_KIRIM_AJA_ORDER.replace(':orderId', orderId);
  }

  static foodSearchWithTerm(term: string): string {
    return `${this.FOOD_SEARCH}?term=${term}`;
  }

  static getMerchant(merchantId: string): string {
    return this.GET_MERCHANT.replace(':merchantId', merchantId);
  }

  static getStore(storeId: string): string {
    return this.GET_STORE.replace(':storeId', storeId);
  }
}

export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const LOGIN_OTP_ROUTE = 'login-otp';
export const PROFILE_ROUTE = '/profile';
export const ORDERS_ROUTE = 'orders';
export const FOOD_CATEGORIES_ROUTE = 'categories/food';

export default function AppRoute() {
  return (
    <Routes>
      <Route path={'/'} element={<FoodLayout />}>
        <Route index element={<HomePage />} />
        <Route path={PageRoutes.MY_ORDERS} element={<OrdersPage />} />
        <Route path={PageRoutes.SETTINGS} element={<SettingsPage />} />
      </Route>

      <Route path={PageRoutes.FOOD_SEARCH} element={<FoodSearchPage />} />
      <Route path={PageRoutes.GET_MERCHANT} element={<MerchantPage />} />
      <Route path={PageRoutes.PRODUCT_SEARCH} element={<ProductSearchPage />} />
      <Route path={PageRoutes.GET_STORE} element={<StorePage />} />

      <Route element={<RequireAuth />}>
        <Route path={PageRoutes.GET_MAKAN_AJA_ORDER} element={<FoodOrderDetailPage />} />
        <Route path={PageRoutes.GET_MARKET_AJA_ORDER} element={<StoreOrderDetailPage />} />
        <Route path={PageRoutes.GET_KIRIM_AJA_ORDER} element={<ShipmentOrderDetailPage />} />

        <Route path={PageRoutes.BELANJA_AJA} element={<BelanjaAjaPage />} />
        <Route path={PageRoutes.KIRIM_AJA} element={<KirimAjaPage />} />

        <Route path={FOOD_CATEGORIES_ROUTE} element={<FoodCategoriesPage />} />
        <Route path={PageRoutes.PROFILE} element={<ProfilePage />} />
        <Route path={PageRoutes.MY_ADDRESS} element={<MyAddressPage />} />

        <Route path={PageRoutes.CHECKOUT} element={<CheckoutPage />} />
        <Route path={PageRoutes.STORE_CHECKOUT} element={<StoreCheckoutPage />} />
      </Route>

      <Route path={LOGIN_ROUTE} element={<GuestOnly />}>
        <Route index element={<PhoneLoginPage />} />
        <Route path={LOGIN_OTP_ROUTE} element={<PhoneOtpPage />} />
      </Route>

      <Route path={PageRoutes.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function RequireAuth() {
  const location = useLocation();
  const { user } = useAuthStore((state) => state);

  if (user) {
    if (!user.has_complete_profile && location.pathname !== '/profile') {
      return <Navigate to={PROFILE_ROUTE} state={{ from: location }} replace />;
    }

    tagUser(user.phone_number);
    return <Outlet />;
  }

  return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
}

function GuestOnly() {
  const { user } = useAuthStore((state) => state);
  const location = useLocation();

  if (user) {
    return <Navigate to={HOME_ROUTE} state={{ from: location }} replace />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
      <Outlet />
    </GoogleReCaptchaProvider>
  );
}

const NotFound: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary">Back Home</Button>}
  />
);
