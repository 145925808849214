import FullscreenModal from '@components/FullscreenModal';
import { Button, Card, Flex, Typography } from 'antd';
import Header from '@components/Header.tsx';
import MapWithMarker from '@components/MapWithMarker.tsx';
import { useUserStore } from '@/stores/user.store.ts';
import { useState } from 'react';
import { track } from '@utils/analytic.util.ts';

interface OrderConfirmationProps {
  visible: boolean;
  onClose: () => void;
  onConfirmed: (latitude: number, longitude: number) => void;
}

type Location = {
  latitude: number;
  longitude: number;
};

const { Text } = Typography;

export default function OrderConfirmation(props: OrderConfirmationProps) {
  const { visible, onClose, onConfirmed } = props;
  const defaultAddress = useUserStore((state) => state.defaultAddress);
  const [location, setLocation] = useState<Location>({
    latitude: defaultAddress.latitude,
    longitude: defaultAddress.longitude,
  });

  const onMarkerDragEnd = (latitude: number, longitude: number) => {
    track('USER_UPDATE_COORDINATE');
    setLocation({ latitude, longitude });
  };

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'flex-1 min-h-screen'} style={{ backgroundColor: '#F5F5F5' }}>
        <Header title={'Konfirmasi Alamat Pengiriman'} showBackButton onBackButtonClicked={onClose} />

        <Flex flex={1} className={'px-6 pb-6'} vertical gap={'middle'}>
          <Card className={'w-full text-center'}>
            <Text className={'text-center'}>
              Apakah pin berikut sudah sesuai? Jika belum, geser pin sesuai dengan lokasi-mu saat ini.
            </Text>
          </Card>

          <Card>
            <MapWithMarker
              height={360}
              onCoordinateChanged={onMarkerDragEnd}
              coordinate={{ lat: location.latitude, lng: location.longitude }}
            />
          </Card>
        </Flex>

        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button
            type={'primary'}
            className={'w-full'}
            onClick={() => {
              onClose();
              onConfirmed(location.latitude, location.longitude);
            }}
          >
            Ya, Sudah Benar
          </Button>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
