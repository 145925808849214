import { Button, Card, Collapse, Divider, Flex, Input, Modal, Radio, Space, Timeline, Typography } from 'antd';
import Header from '@components/Header.tsx';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import './KirimAjaPage.less';
import LocationPicker from '@components/LocationPicker';
import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { displayPrice } from '@/utils';
import bike from '@assets/images/kirim/bike.png';
import car from '@assets/images/kirim/car.png';
import DestinationPicker, { Destination } from '@pages/KirimAjaPage/components/DestinationPicker.tsx';
import { v4 as uuidv4 } from 'uuid';
import {
  Location,
  ShipmentData,
  useCalculateShippingFees,
  useSubmitShippingOrder,
} from '@/services/kirim_aja.service.ts';
import Lottie from 'lottie-react';
import SuccessAnimation from '@assets/animations/success.json';

const { Text, Title } = Typography;

export default function KirimAjaPage() {
  const navigate = useNavigate();
  const maxDestinations = 5;

  const [unitType] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState(2);

  const [senderLocation, setSenderLocation] = useState<Location>();
  const [locationPickerVisible, setLocationPickerVisible] = useState(false);

  const [destinationPickerVisible, setDestinationPickerVisible] = useState(false);
  const [selectedDestinationId, setSelectedDestinationId] = useState<string>();
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [shipmentData, setShipmentData] = useState<ShipmentData>({
    sender_name: '',
    sender_phone: '',
    recipient_name: '',
    recipient_phone: '',
    item_details: '',
    item_weight: 0,
  });

  const [isValidRoutes, setIsValidRoutes] = useState(false);
  const [isValidShipmentData, setIsValidShipmentData] = useState(false);

  const { mutate: mutateCalculation, data: feesData, isPending: isPendingCalculation } = useCalculateShippingFees();
  const {
    mutate: mutateSubmit,
    data: submitData,
    isPending: isPendingSubmit,
    isError: isSubmitOrderError,
    failureReason,
  } = useSubmitShippingOrder();

  useEffect(() => {
    if (!destinations.length) {
      addMoreDestination();
    }
  }, []);

  useEffect(() => {
    if (!senderLocation) {
      setIsValidRoutes(false);
    }

    setIsValidRoutes(isValidDestinations());

    if (senderLocation && isValidDestinations()) {
      calculateShippingFees();
    }
  }, [senderLocation, destinations]);

  useEffect(() => {
    setIsValidShipmentData(!Object.values(shipmentData).some((i) => !i));
  }, [shipmentData]);

  useEffect(() => {
    if (!isSubmitOrderError) return;

    Modal.error({
      title: 'Gagal membuat pesanan',
      content: failureReason?.message,
      centered: true,
      okText: 'Oke',
    });
  }, [isSubmitOrderError]);

  const setData = (key: keyof ShipmentData, value: string | number) => {
    const data = {
      ...shipmentData,
      [key]: value,
    };
    setShipmentData(data);
  };

  const isValidDestinations = () => {
    if (!destinations.length) {
      return false;
    }

    return !destinations.some((i) => !i.location);
  };

  const calculateShippingFees = () => {
    mutateCalculation({
      origin: {
        address: senderLocation!.address,
        latitude: senderLocation!.latitude,
        longitude: senderLocation!.longitude,
      },
      destination: destinations.map((i) => {
        return {
          address: i.location!.address,
          latitude: i.location!.latitude,
          longitude: i.location!.longitude,
        };
      }),
    });
  };

  const onLocationPickerClosed = () => {
    setLocationPickerVisible(false);
  };

  const onLocationSelected = (location: Location) => {
    setSenderLocation(location);
  };

  const addMoreDestination = () => {
    if (destinations.length >= maxDestinations) return;

    setDestinations(() => [
      ...destinations,
      {
        id: uuidv4(),
      },
    ]);
  };

  const onDestinationPickerClosed = () => {
    setSelectedDestinationId(undefined);
    setDestinationPickerVisible(false);
  };

  const onSelectingDestination = (id: string) => {
    setSelectedDestinationId(id);
    setDestinationPickerVisible(true);
  };

  const onDestinationSelected = (location: Location) => {
    const newDestinations = [...destinations];
    const destinationIndex = newDestinations.findIndex((i) => i.id === selectedDestinationId);

    const destination = destinations[destinationIndex];
    destination.address = location.address;
    destination.location = location;

    newDestinations[destinationIndex] = destination;
    setDestinations(newDestinations);
  };

  const onDestinationDeleted = (id: string) => {
    const newDestinations = [...destinations];
    const destinationIndex = newDestinations.findIndex((i) => i.id === id);

    newDestinations.splice(destinationIndex, 1);
    setDestinations(newDestinations);
  };

  const submit = () => {
    mutateSubmit({
      ...shipmentData,
      payment_method: paymentMethod,
      origin: {
        address: senderLocation!.address,
        latitude: senderLocation!.latitude,
        longitude: senderLocation!.longitude,
      },
      destination: destinations.map((i) => {
        return {
          address: i.location!.address,
          latitude: i.location!.latitude,
          longitude: i.location!.longitude,
        };
      }),
    });
  };

  if (submitData) {
    return (
      <Flex vertical gap={'large'} className={'min-h-screen'} flex={1} align={'center'} justify={'center'}>
        <Lottie animationData={SuccessAnimation} loop={false} style={{ width: '50%' }} />
        <Flex vertical align={'center'} gap={'small'}>
          <Title className={'m-0'}>Pesanan diterima</Title>
          <Button
            type={'link'}
            onClick={() => {
              navigate(`${PageRoutes.MY_ORDERS}?category=kirim-aja`);
            }}
          >
            Klik disini untuk melihat status pesanan Anda
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex vertical flex={1} className={'min-h-screen'}>
      <Header title={'Kirim-Aja'} showBackButton onBackButtonClicked={() => navigate(PageRoutes.HOME)} />

      <Flex flex={1} vertical gap={'large'} className={'p-6'} style={{ backgroundColor: '#F5F5F5' }}>
        <Card className={'w-full h-fit app-timeline'}>
          <Timeline
            className={'w-full mt-2'}
            items={[
              {
                children: (
                  <TouchableOpacity
                    onClick={() => {
                      setLocationPickerVisible(true);
                    }}
                  >
                    <Text className={clsx({ 'ml-2': true, 'opacity-50': !senderLocation })}>
                      {senderLocation ? senderLocation.address : 'Pickup dimana?'}
                    </Text>
                  </TouchableOpacity>
                ),
              },
              ...destinations.map((i, index) => ({
                children: (
                  <DestinationPicker
                    id={i.id}
                    address={i.address}
                    onClick={() => onSelectingDestination(i.id)}
                    onDelete={() => onDestinationDeleted(i.id)}
                    allowDelete={index !== 0}
                  />
                ),
                color: '#1677ff',
              })),
            ]}
          />
          {destinations.length < maxDestinations && (
            <Flex justify={'center'} className={'mt-2'}>
              <TouchableOpacity onClick={addMoreDestination}>
                <Text className={'text-sm text-primary font-bold'}>Tambah destinasi</Text>
              </TouchableOpacity>
            </Flex>
          )}
        </Card>

        <Collapse
          expandIconPosition={'end'}
          className={'bg-gray-50'}
          items={[
            {
              key: 'sender',
              label: <Text className={'font-bold text-primary'}>Pengirim</Text>,
              children: (
                <React.Fragment>
                  <Input
                    placeholder={'Nama Pengirim'}
                    className={'mb-4'}
                    onChange={(e) => setData('sender_name', e.target.value)}
                  />
                  <Input
                    placeholder={'Nomor Telepon Pengirim'}
                    onChange={(e) => setData('sender_phone', e.target.value)}
                  />
                </React.Fragment>
              ),
            },
          ]}
        />

        <Collapse
          expandIconPosition={'end'}
          className={'bg-gray-50'}
          items={[
            {
              key: 'recipient',
              label: <Text className={'font-bold text-blue-600'}>Penerima</Text>,
              children: (
                <React.Fragment>
                  <Input
                    placeholder={'Nama Penerima'}
                    className={'mb-4'}
                    onChange={(e) => setData('recipient_name', e.target.value)}
                  />
                  <Input
                    placeholder={'Nomor Telepon Penerima'}
                    onChange={(e) => setData('recipient_phone', e.target.value)}
                  />
                </React.Fragment>
              ),
            },
          ]}
        />

        <Collapse
          expandIconPosition={'end'}
          className={'bg-gray-50'}
          items={[
            {
              key: 'items',
              label: <Text className={'font-bold'}>Detail Barang</Text>,
              children: (
                <React.Fragment>
                  <Input
                    placeholder={'Jenis Barang'}
                    className={'mb-4'}
                    onChange={(e) => setData('item_details', e.target.value)}
                  />
                  <Input
                    placeholder={'Berat Barang'}
                    suffix={'kg'}
                    inputMode={'numeric'}
                    onChange={(e) => setData('item_weight', e.target.value)}
                  />
                </React.Fragment>
              ),
            },
          ]}
        />

        <Card>
          <Flex vertical>
            <Text className={'font-bold'}>Unit</Text>
            <Radio.Group className={'mt-3'} value={unitType}>
              <Flex vertical gap={'small'}>
                <Flex align={'center'}>
                  <img src={bike} alt="" width={32} height={32} />
                  <Flex flex={1} className={'ml-2'}>
                    <Text>Motor</Text>
                  </Flex>
                  <Radio value={1}></Radio>
                </Flex>

                <Flex align={'center'} className={'opacity-50 pointer-events-none'}>
                  <img src={car} alt="" width={32} height={32} />
                  <Flex flex={1} className={'ml-2'}>
                    <Text>Mobil</Text>
                  </Flex>
                  <Text className={'pr-3 font-bold text-error'}>segera hadir!</Text>
                  <Radio value={2}></Radio>
                </Flex>
              </Flex>
            </Radio.Group>
          </Flex>
        </Card>

        <Card>
          <Space direction={'vertical'}>
            <Text className={'font-bold'}>Pembayaran</Text>
            <Radio.Group
              className={'mt-2'}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
              value={paymentMethod}
            >
              <Space direction="vertical">
                <Radio value={2}>Transfer Bank</Radio>
                <Radio value={3}>Tunai dibayar pengirim</Radio>
                <Radio value={4}>Tunai dibayar penerima</Radio>
                <Radio value={5}>QRIS</Radio>
              </Space>
            </Radio.Group>
          </Space>
        </Card>

        {isValidRoutes && (
          <Card loading={isPendingCalculation}>
            {feesData && (
              <Flex vertical gap={'small'}>
                <Flex justify={'space-between'}>
                  <Text>Biaya Layanan</Text>
                  <Text className={'font-bold'}>{displayPrice(feesData.service_fee)}</Text>
                </Flex>
                <Flex justify={'space-between'}>
                  <Text>Ongkos Kirim</Text>
                  <Text className={'font-bold'}>{displayPrice(feesData.shipping_fee)}</Text>
                </Flex>
                <Divider className={'my-3'} />
                <Flex justify={'space-between'}>
                  <Text>Total</Text>
                  <Text className={'font-bold'}>{displayPrice(feesData.service_fee + feesData.shipping_fee)}</Text>
                </Flex>
              </Flex>
            )}
          </Card>
        )}
      </Flex>

      <Flex className={'bg-white p-6'}>
        <Button
          type={'primary'}
          className={'w-full'}
          disabled={!isValidRoutes || !feesData || !isValidShipmentData}
          loading={isPendingSubmit}
          onClick={submit}
        >
          Pesan Kirim-Aja
        </Button>
      </Flex>

      <LocationPicker
        visible={locationPickerVisible}
        title={'Lokasi Pengirim'}
        onClose={onLocationPickerClosed}
        onSelected={onLocationSelected}
      />

      <LocationPicker
        visible={destinationPickerVisible}
        title={'Lokasi Penerima'}
        onClose={onDestinationPickerClosed}
        onSelected={onDestinationSelected}
      />
    </Flex>
  );
}
