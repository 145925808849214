import { Empty, Flex } from 'antd';
import OrderList from '@pages/OrdersPage/components/OrderList.tsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import ListLoader from '@components/ListLoader.tsx';
import { OrderStatusCategory, useGetOrders } from '@services/store_order.service.ts';
import IOrder from '@core/types/base/IOrder.ts';

interface MyOrdersProps {
  status: OrderStatusCategory;
}

export default function MyStoreOrders(props: MyOrdersProps) {
  const { status } = props;
  const [data, setData] = useState<IOrder[]>([]);
  const [page, setPage] = useState(1);

  const { mutate, data: response, isPending, isIdle } = useGetOrders(status);

  useEffect(() => {
    if (!response) return;
    setData((prev) => [...prev, ...response.data]);

    // Increment page if there is a next page
    if (response.next_page_url) {
      setPage((prev) => prev + 1);
    }
  }, [response]);

  // Get first page
  useEffect(() => {
    if (isIdle) {
      mutate(1);
    }
  }, []);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={() => mutate(page)}
      hasMore={!!response?.next_page_url}
      scrollableTarget="scrollableDiv"
      loader={null}
    >
      <Flex className={'p-4'} vertical gap={'middle'}>
        {!isIdle && !isPending && !data.length ? <Empty /> : <OrderList type={'store'} orders={data} />}
        {isPending && <ListLoader />}
      </Flex>
    </InfiniteScroll>
  );
}
