import { authorizedClient } from '@services/api.service.ts';
import { API_PATH } from '@core/constants';
import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import ICoupon from '@core/types/ICoupon.ts';

const getCoupon = async (code: string) => {
  return authorizedClient
    .get(API_PATH.getCoupon(code))
    .then((response: AxiosResponse<ICoupon>) => response.data)
    .catch((error) => {
      if (error.response.status === 403) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    });
};

export const useGetCoupon = () => {
  return useMutation({
    mutationKey: ['getCoupon'],
    mutationFn: (code: string) => getCoupon(code),
  });
};
