import { Dropdown, Flex, Space, Tabs, Typography } from 'antd';
import Header from '@components/Header.tsx';
import MyFoodOrders from '@pages/OrdersPage/components/MyFoodOrders.tsx';
import { useSearchParams } from 'react-router-dom';
import { CaretDownFilled } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';

import './OrdersPage.less';
import { OrderStatusCategory } from '@services/order.service.ts';
import MyShipmentOrders from '@pages/OrdersPage/components/MyShipmentOrders.tsx';
import MyStoreOrders from '@pages/OrdersPage/components/MyStoreOrders.tsx';

const services = [
  {
    key: 'makan-aja',
    label: 'Makan-Aja',
  },
  {
    key: 'market-aja',
    label: 'Market-Aja',
  },
  {
    key: 'kirim-aja',
    label: 'Kirim-Aja',
  },
  {
    key: 'belanja-aja',
    label: 'Belanja-Aja',
  },
];

export default function OrdersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState(searchParams.get('category') ?? 'makan-aja');
  const [tab, setTab] = useState(searchParams.get('tab') ?? 'activeForCustomer');

  useEffect(() => {
    setSearchParams({
      tab,
      category,
    });
  }, [category, tab]);

  const items = useCallback(() => {
    const component = (status: OrderStatusCategory, category: string) => {
      switch (category) {
        case 'makan-aja':
          return <MyFoodOrders status={status} />;
        case 'market-aja':
          return <MyStoreOrders status={status} />;
        case 'kirim-aja':
          return <MyShipmentOrders status={status} />;
      }
    };

    return [
      {
        label: 'Aktif',
        key: 'activeForCustomer',
        children: component('activeForCustomer', category),
      },
      {
        label: 'Selesai',
        key: 'completed',
        children: component('completed', category),
      },
      {
        label: 'Dibatalkan',
        key: 'canceled',
        children: component('canceled', category),
      },
    ];
  }, [category]);

  const getServiceLabel = (key: string) => {
    return services.find((i) => i.key === key)?.label;
  };

  return (
    <Flex vertical className={'orders-page'}>
      <Flex className={'bg-white'}>
        <Header
          title={'Pesanan Saya'}
          accessoryRight={
            <Dropdown
              menu={{
                items: services,
                selectable: true,
                selectedKeys: [category],
                onClick: (menu) => setCategory(menu.key),
              }}
            >
              <Typography.Link strong>
                <Space>
                  {getServiceLabel(category)}
                  <CaretDownFilled />
                </Space>
              </Typography.Link>
            </Dropdown>
          }
        />
      </Flex>

      <Tabs
        activeKey={searchParams.get('tab') ?? 'activeForCustomer'}
        items={items()}
        onChange={(key) => setTab(key)}
        tabBarStyle={{
          backgroundColor: 'white',
        }}
      />
    </Flex>
  );
}
