import { Button, Card, Divider, Flex, Typography } from 'antd';
import { ProductCartItem, useProductCartStore } from '@/stores/product_cart.store.ts';
import { displayPrice } from '@/utils';
import TouchableOpacity from '@components/TouchableOpacity';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ProductPicker from '@pages/StorePage/components/ProductPicker.tsx';
import { track } from '@utils/analytic.util.ts';
import { shouldShowUnit } from '@utils/order.util.ts';

const { Title, Text } = Typography;

export default function OrderSummary() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const store = useProductCartStore((state) => state.store);
  const cart = useProductCartStore((state) => state.cart);
  const setProduct = useProductCartStore((state) => state.setProduct);
  const setActiveItem = useProductCartStore((state) => state.setActiveItem);

  const addMoreItems = () => {
    track('ADD_MORE_CART_ITEM');
    navigate(-1);
  };

  const editItem = (item: ProductCartItem) => {
    setActiveItem(item);
    setProduct(item.product);
    setModalVisible(true);
  };

  return (
    <>
      <Card>
        <Flex justify={'space-between'}>
          <Title level={3} className={'m-0'}>
            Rincian Pesanan
          </Title>
          <Button ghost size={'small'} type={'primary'} className={'px-3 font-bold'} onClick={addMoreItems}>
            Tambah
          </Button>
        </Flex>

        <Divider />

        {cart.map((item: ProductCartItem, index) => (
          <Fragment key={item.uuid}>
            {!!index && <Divider />}
            <Flex gap={'middle'}>
              <img
                src={item.product.image_path.length ? item.product.image_path : store.logo_path}
                className={'object-cover rounded-xl'}
                style={{
                  width: 56,
                  height: 56,
                }}
              />
              <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} justify={'center'} gap={'small'}>
                <Text className={'overflow-hidden text-ellipsis m-0'}>{item.product.name}</Text>
                <Text className={'font-bold text-primary m-0'}>
                  {displayPrice(item.totalPrice)} {shouldShowUnit(item.product)}
                </Text>
              </Flex>
              <Flex vertical gap={'small'} justify={'center'} align={'center'}>
                <Text strong>{item.quantity}x</Text>
                <TouchableOpacity onClick={() => editItem(item)}>
                  <Text strong className={'text-primary'}>
                    edit
                  </Text>
                </TouchableOpacity>
              </Flex>
            </Flex>
          </Fragment>
        ))}
      </Card>
      <ProductPicker mode="edit" visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
}
