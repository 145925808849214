import FullscreenModal from '@components/FullscreenModal';
import { Button, Col, Flex, Form, Input, message, Row } from 'antd';
import Header from '@components/Header.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { CloseIcon } from '@components/AppIcons';
import { useEffect, useState } from 'react';
import {
  AddUpdateAddressDto,
  useCreateNewAddress,
  useDeleteAddress,
  useUpdateAddress,
} from '@/services/customer.service.ts';
import IAddress from '@core/types/IAddress.ts';
import MapWithMarker from '@components/MapWithMarker.tsx';
import { track } from '@utils/analytic.util.ts';

interface AddEditAddressModalProps {
  mode: 'add' | 'edit';
  selectedAddress?: IAddress;
  visible: boolean;
  onClose: (refreshData?: boolean) => void;
}

const initialAddress = {
  label: '',
  address: '',
  latitude: '',
  longitude: '',
};

export default function AddEditAddressModal(props: AddEditAddressModalProps) {
  const { mode, visible, selectedAddress, onClose } = props;
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { mutate, data, error, isPending, isIdle } = useCreateNewAddress();

  const {
    mutate: deleteAddress,
    data: deleteData,
    error: deleteError,
    isPending: isPendingDelete,
    isIdle: isIdleDelete,
  } = useDeleteAddress();

  const {
    mutate: updateAddress,
    data: updateData,
    error: updateError,
    isPending: isPendingUpdate,
    isIdle: isIdleUpdate,
  } = useUpdateAddress();

  const setCoordinate = (lat: number, lng: number) => {
    form.setFieldValue('latitude', lat);
    form.setFieldValue('longitude', lng);
  };

  const onCoordinateChanged = (latitude: number, longitude: number) => {
    track('USER_UPDATE_COORDINATE');
    setCoordinate(latitude, longitude);
  };

  useEffect(() => {
    if (selectedAddress) {
      form.setFieldsValue({ ...selectedAddress });
    } else {
      form.setFieldsValue(initialAddress);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!values) return;
    setIsValidForm(values.address && values.label);
  }, [values]);

  // delete request failed
  useEffect(() => {
    if (!isIdleDelete && !isPendingDelete && deleteError) {
      message.error(`Gagal menghapus alamat`);
    }
  }, [deleteError]);

  // update request failed
  useEffect(() => {
    if (!isIdleUpdate && !isPendingUpdate && updateError) {
      message.error(`Gagal mengupdate alamat`);
    }
  }, [updateError]);

  // create request failed
  useEffect(() => {
    if (!isIdle && !isPending && error) {
      message.error(`Gagal menambahkan alamat`);
    }
  }, [error]);

  // create OR update request is done OR delete request is done
  useEffect(() => {
    if (
      (!isIdle && !isPending && data) ||
      (!isIdleUpdate && !isPendingUpdate && updateData) ||
      (!isIdleDelete && !isPendingDelete && deleteData)
    ) {
      onClose(true);
    }
  }, [data, updateData, deleteData]);

  const onSubmit = () => {
    mutate(form.getFieldsValue(true));
  };

  const onDelete = () => {
    deleteAddress(selectedAddress!.id);
  };

  const onUpdate = () => {
    updateAddress({
      id: selectedAddress!.id,
      data: form.getFieldsValue(true),
    });
  };

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'w-screen'}>
        <Flex className={'bg-white'}>
          <Header
            title={mode === 'add' ? 'Tambah Alamat Baru' : 'Update Alamat'}
            accessoryLeft={
              <TouchableOpacity
                onClick={() => {
                  form.resetFields();
                  onClose();
                }}
              >
                <CloseIcon style={{ fontSize: 24, marginTop: 4 }} />
              </TouchableOpacity>
            }
          />
        </Flex>

        <Flex flex={1} className={'p-6'}>
          <Row className={'w-full'}>
            <Col span={24}>
              <Form<AddUpdateAddressDto>
                className={'flex flex-col flex-1'}
                layout={'vertical'}
                form={form}
                initialValues={initialAddress}
              >
                <Form.Item label="Label" name={'label'} required>
                  <Input />
                </Form.Item>
                <Form.Item label="Alamat" name={'address'} required>
                  <Input />
                </Form.Item>
                <MapWithMarker
                  height={360}
                  onCoordinateChanged={onCoordinateChanged}
                  coordinate={
                    selectedAddress
                      ? {
                          lat: selectedAddress.latitude,
                          lng: selectedAddress.longitude,
                        }
                      : undefined
                  }
                />
              </Form>
            </Col>
          </Row>
        </Flex>

        <Flex className={'bg-white p-6'} gap={'middle'}>
          {mode === 'add' ? (
            <Button
              type={'primary'}
              className={'w-full'}
              disabled={!isValidForm}
              onClick={onSubmit}
              loading={isPendingDelete}
            >
              Simpan
            </Button>
          ) : (
            <>
              {!selectedAddress?.default && (
                <Button
                  type={'primary'}
                  className={'w-full'}
                  disabled={isPendingUpdate}
                  onClick={onDelete}
                  loading={isPendingDelete}
                  danger
                >
                  Hapus
                </Button>
              )}
              <Button
                type={'primary'}
                className={'w-full'}
                disabled={!isValidForm || isPendingDelete}
                onClick={onUpdate}
                loading={isPendingUpdate}
              >
                Update
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
