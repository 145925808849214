import { Card, Flex, Typography } from 'antd';
import { IMenu } from '@/core/types/IMenu.ts';
import { useMemo, useState } from 'react';
import MenuPicker from '@pages/MerchantPage/components/MenuPicker.tsx';
import { displayPrice } from '@/utils';
import { CartItem, useCartStore } from '@/stores/cart.store.ts';
import { v4 as uuidv4 } from 'uuid';
import { track } from '@utils/analytic.util.ts';

interface MenuListProps {
  searchTerm: string;
  menus?: IMenu[];
}

interface GroupedMenus {
  [category: string]: IMenu[];
}

const { Text, Title } = Typography;

export default function MenuList({ menus, searchTerm }: MenuListProps) {
  const setMenu = useCartStore((state) => state.setMenu);
  const merchant = useCartStore((state) => state.merchant);
  const setActiveItem = useCartStore((state) => state.setActiveItem);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const groupedMenusByCategory = useMemo(() => {
    return (menus ?? []).reduce((prev: GroupedMenus, curr) => {
      if (!prev[curr.category_id]) {
        prev[curr.category_id] = [];
      }
      prev[curr.category_id].push(curr);
      return prev;
    }, {});
  }, [menus]);

  const getMenuCategoryLabel = (categoryId: string): string => {
    return merchant.menu_categories?.find((category) => category.id === categoryId)?.name ?? '';
  };

  const onMenuClicked = (menu: IMenu) => {
    const newCartItem: CartItem = {
      uuid: uuidv4(),
      menu: menu,
      selectedAddons: {},
      quantity: 1,
      remark: '',
      totalPrice: menu.sell_price,
    };

    setMenu(menu);
    setActiveItem(newCartItem);

    track('CHOOSING_MERCHANT_FOOD', { menuId: menu.id, merchantId: merchant.id, menuName: menu.name });
    setModalVisible(true);
  };

  const renderCategory = (category: string, menus: IMenu[]) => (
    <Flex vertical key={category}>
      <Title level={2} className={'mb-4'}>
        {getMenuCategoryLabel(category)}
      </Title>

      {menus
        .sort((a, b) => a.sorting - b.sorting)
        .filter((menu) => menu.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((menu) => (
          <Card
            hoverable
            className={`mb-4 ${!merchant.is_operating ? 'opacity-35' : ''}`}
            key={menu.id}
            onClick={() => (merchant.is_operating ? onMenuClicked(menu) : null)}
          >
            <Flex gap={'large'}>
              <img
                src={menu.image_path.length ? menu.image_path : merchant.logo_path}
                className={'object-cover rounded-xl'}
                style={{
                  width: 64,
                  height: 64,
                }}
              />
              <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} justify={'center'}>
                <Text className={'font-bold overflow-hidden text-ellipsis'} style={{ margin: 0 }}>
                  {menu.name}
                </Text>
                <Text className={'font-bold text-primary'}>{displayPrice(menu.sell_price)}</Text>
              </Flex>
            </Flex>
          </Card>
        ))}
    </Flex>
  );

  if (!menus) return null;

  return (
    <Flex vertical className={'p-6 w-full'}>
      {Object.keys(groupedMenusByCategory).map((category) =>
        renderCategory(category, groupedMenusByCategory[category])
      )}
      <MenuPicker mode={'add'} visible={modalVisible} setVisible={setModalVisible} />
    </Flex>
  );
}
