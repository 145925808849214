import { clsx } from 'clsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { Flex, Typography } from 'antd';
import { Location } from '@services/kirim_aja.service.ts';
import React from 'react';

const { Text } = Typography;

export interface Destination {
  element?: React.ReactNode;
  address?: string;
  location?: Location;
  id: string;
}

interface DestinationPickerProps extends Omit<Destination, 'element'> {
  onClick: () => void;
  onDelete: () => void;
  allowDelete?: boolean;
  defaultAddress?: string;
}

export default function DestinationPicker(props: DestinationPickerProps) {
  const { address, allowDelete = true, onClick, onDelete, defaultAddress = 'Kirim kemana?' } = props;

  return (
    <Flex justify={'space-between'} align={'center'} className={'w-full'}>
      <TouchableOpacity onClick={onClick}>
        <Text className={clsx({ 'ml-2': true, 'opacity-50': !address })}>{address ? address : defaultAddress}</Text>
      </TouchableOpacity>
      {allowDelete && (
        <TouchableOpacity onClick={onDelete}>
          <Text className={'text-sm text-error font-bold'}>Hapus</Text>
        </TouchableOpacity>
      )}
    </Flex>
  );
}
