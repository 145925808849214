import { Card, Checkbox, Divider, Flex, Radio, RadioChangeEvent, Typography } from 'antd';
import { Fragment, useState } from 'react';
import { displayPrice } from '@/utils';
import { IAddonCategories } from '@/core/types/IAddonCategories.ts';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCartStore } from '@/stores/cart.store.ts';
import { IAddon } from '@/core/types/IAddon.ts';

interface AddonProps {
  addonCategory: IAddonCategories;
}

const { Title, Text } = Typography;

export default function AddonCategory(props: AddonProps) {
  const { addonCategory } = props;
  const { max_selection, is_mandatory } = addonCategory;

  const activeItem = useCartStore((state) => state.activeItem);
  const setActiveItem = useCartStore((state) => state.setActiveItem);

  const [checkedValue, setCheckedValue] = useState<Set<string>>(() => {
    const selectedAddons = { ...activeItem?.selectedAddons };
    const values = selectedAddons[addonCategory.id] ?? [];

    if (!values.length) {
      return new Set();
    }

    return new Set(values.map((i) => i.id));
  });

  const [selectedValue, setSelectedValue] = useState<string>(() => {
    const selectedAddons = { ...activeItem?.selectedAddons };
    const values = selectedAddons[addonCategory.id] ?? [];

    if (!values.length) {
      return '';
    }

    return values[0].id;
  });

  const updateActiveItemAddons = (values: IAddon[]) => {
    const updatedItem = { ...activeItem! };

    updatedItem.selectedAddons = {
      ...updatedItem.selectedAddons,
      [addonCategory.id]: values,
    };

    setActiveItem(updatedItem);
  };

  const onCheckboxChange = (e: CheckboxChangeEvent, addonId: string) => {
    const isChecked = e.target.checked;
    const currentValue = new Set(checkedValue);

    if (isChecked) {
      currentValue.add(addonId);
    } else {
      currentValue.delete(addonId);
    }

    setCheckedValue(currentValue);
    updateActiveItemAddons(addonCategory.addons.filter((addon) => currentValue.has(addon.id)));
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    updateActiveItemAddons(addonCategory.addons.filter((addon) => addon.id === e.target.value));
  };

  const renderSubtitle = (): string => {
    if (!is_mandatory) {
      return `(Opsional / maksimal ${max_selection})`;
    }
    return `(Harus pilih / maksimal ${max_selection})`;
  };

  const renderOptions = () => {
    if (max_selection === 1) {
      return (
        <Flex flex={1}>
          <Radio.Group onChange={onRadioChange} value={selectedValue} className={'w-full'}>
            {addonCategory.addons?.map((addon, index) => (
              <Fragment key={addon.id}>
                {!!index && <Divider />}
                <Flex justify={'space-between'}>
                  <Radio value={addon.id}>{addon.name}</Radio>
                  <Text>+{displayPrice(addon.price, false)}</Text>
                </Flex>
              </Fragment>
            ))}
          </Radio.Group>
        </Flex>
      );
    }

    return addonCategory.addons?.map((addon, index) => (
      <Fragment key={addon.id}>
        {!!index && <Divider />}
        <Flex justify={'space-between'}>
          <Checkbox
            checked={checkedValue?.has(addon.id)}
            onChange={(e) => onCheckboxChange(e, addon.id)}
            disabled={checkedValue?.size === max_selection && !checkedValue.has(addon.id)}
          >
            {addon.name}
          </Checkbox>
          <Text>+{displayPrice(addon.price, false)}</Text>
        </Flex>
      </Fragment>
    ));
  };

  return (
    <Card className={'mt-2 rounded-none border-none'}>
      <Flex gap={'small'} align={'center'} className={'mb-6'}>
        <Title level={3} className={'m-0'}>
          {addonCategory.name}
        </Title>
        <Text className={'text-sm'}>{renderSubtitle()}</Text>
      </Flex>
      {renderOptions()}
    </Card>
  );
}
