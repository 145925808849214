import { Button, Card, Flex, Modal, Radio, RadioChangeEvent, Typography } from 'antd';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { JSX, useState } from 'react';
import { BankIcon, BankNoteDollarIcon, CloseIcon, WalletFilledIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import { QrcodeOutlined, RightOutlined } from '@ant-design/icons';
import Header from '@components/Header.tsx';
import PaymentMethod from '@/core/enums/PaymentMethod';
import ShipmentOrderPaymentMethod from '@/core/enums/kirim_aja/PaymentMethod';

const { Text } = Typography;

export interface PaymentMethodOption {
  label: string;
  value: PaymentMethod | ShipmentOrderPaymentMethod;
  icon: JSX.Element;
  enabled: boolean;
  isComingSoon: boolean;
}

export const paymentMethods: PaymentMethodOption[] = [
  {
    label: 'Dompet Saya',
    value: PaymentMethod.WALLET,
    icon: <WalletFilledIcon className={'text-primary text-xl'} />,
    enabled: false,
    isComingSoon: true,
  },
  {
    label: 'Tunai (COD)',
    value: PaymentMethod.CASH_ON_DELIVERY,
    icon: <BankNoteDollarIcon className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'Transfer Bank',
    value: PaymentMethod.BANK_TRANSFER,
    icon: <BankIcon className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'QRIS',
    value: PaymentMethod.QRIS,
    icon: <QrcodeOutlined className={'text-primary text-xl'} />,
    enabled: true,
    isComingSoon: false,
  },
];

interface PaymentMethodsProps {
  defaultValue: number;
  onChange?: (value: PaymentMethod) => void;
}

export default function PaymentMethods(props: PaymentMethodsProps) {
  const { onChange, defaultValue } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const [value, setValue] = useState(() => {
    return paymentMethods.find((method) => method.value === defaultValue);
  });

  const onValueChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);

    if (onChange) {
      onChange(e.target.value.value);
    }
  };

  return (
    <Flex vertical>
      <Card>
        <Flex gap={'small'} align={'center'}>
          {value?.icon}
          <Flex flex={1}>
            <Text className={'text-sm'}>Metode Pembayaran</Text>
          </Flex>
          {onChange ? (
            <TouchableOpacity onClick={() => setVisible(true)}>
              <Flex gap={'small'}>
                <Text strong className={'text-primary text-sm'}>
                  {value?.label}
                </Text>
                <RightOutlined className={'text-primary'} />
              </Flex>
            </TouchableOpacity>
          ) : (
            <Text strong className={'text-primary text-sm'}>
              {value?.label}
            </Text>
          )}
        </Flex>
      </Card>

      <Modal
        centered
        open={visible}
        width={windowWidth}
        style={{ height: windowHeight }}
        closable={false}
        footer={null}
        className={'app-modal bg-transparent'}
      >
        <Flex vertical flex={1}>
          <Header
            title={'Metode Pembayaran'}
            accessoryLeft={
              <TouchableOpacity onClick={() => setVisible(false)}>
                <CloseIcon style={{ fontSize: 24 }} />
              </TouchableOpacity>
            }
          />
          <Flex vertical flex={1}>
            <Radio.Group onChange={onValueChange} value={value}>
              <Flex vertical gap={'middle'} className={'px-6 py-2'}>
                {paymentMethods.map((method) => (
                  <Card onClick={() => (method.enabled ? setValue(method) : null)} key={method.value}>
                    <Flex gap={'middle'} align={'center'}>
                      {method.icon}
                      <Flex flex={1}>
                        <Text className={'font-bold'} disabled={!method.enabled}>
                          {method.label}
                        </Text>
                      </Flex>
                      {method.isComingSoon ? (
                        <Text className={'m-0 p-0 text-xs font-bold text-error'}>Segera hadir</Text>
                      ) : (
                        <Radio value={method} />
                      )}
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </Radio.Group>
          </Flex>
          <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
            <Button type={'primary'} className={'w-full'} onClick={() => setVisible(false)}>
              Oke
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}
