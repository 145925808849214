import { Flex, Typography } from 'antd';
import React from 'react';
import { ArrowLeftIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import { useNavigate } from 'react-router-dom';

interface AppHeaderProps {
  title?: string;
  accessoryLeft?: React.ReactNode;
  accessoryRight?: React.ReactNode;
  showBackButton?: boolean;
  onBackButtonClicked?: () => void;
  backButtonIcon?: React.ReactNode;
  type?: 'dark' | 'light';
}

export default function Header(props: AppHeaderProps) {
  const navigate = useNavigate();
  const {
    title,
    accessoryLeft,
    accessoryRight,
    onBackButtonClicked,
    backButtonIcon,
    showBackButton = false,
    type = 'dark',
  } = props;

  const defaultAccessoryLeft = (
    <TouchableOpacity
      onClick={() => {
        if (onBackButtonClicked) {
          onBackButtonClicked();
        } else {
          navigate(-1);
        }
      }}
    >
      {backButtonIcon ? (
        backButtonIcon
      ) : (
        <ArrowLeftIcon style={{ fontSize: 24, marginTop: 4, color: type === 'light' ? 'white' : 'black' }} />
      )}
    </TouchableOpacity>
  );

  const accessoryLeftExist = showBackButton || accessoryLeft;

  return (
    <Flex align={'center'} className={'min-w-full p-6'}>
      {showBackButton ? defaultAccessoryLeft : accessoryLeft}
      <Flex flex={1}>
        <Typography.Title
          level={3}
          className={`${accessoryLeftExist ? 'ml-4' : ''} mb-0 ${type === 'light' ? 'text-white' : ''}`}
        >
          {title}
        </Typography.Title>
      </Flex>
      {accessoryRight && accessoryRight}
    </Flex>
  );
}
