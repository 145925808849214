import FullscreenModal from '@components/FullscreenModal';
import { Flex, Typography } from 'antd';
import Header from '@components/Header.tsx';
import { CloseIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import { displayPrice } from '@/utils';
import logoBankBNI from '@assets/images/logo-bank-bni.png';
import { CopyOutlined } from '@ant-design/icons';

interface BankTransferProps {
  visible: boolean;
  onClose: () => void;
  totalAmount: number;
}

const { Text } = Typography;

export default function BankTransfer(props: BankTransferProps) {
  const { totalAmount, visible, onClose } = props;

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'bg-white'}>
        <Header
          title={'Transfer Bank'}
          accessoryLeft={
            <TouchableOpacity onClick={onClose}>
              <CloseIcon style={{ fontSize: 24, marginTop: 4 }} />
            </TouchableOpacity>
          }
        />

        <Flex flex={1} vertical className={'px-6 pb-6 bg-white'} gap={'middle'}>
          <Flex className={'w-full'} justify={'space-between'}>
            <Flex vertical>
              <Text>Bank Negara Indonesia (BNI)</Text>
              <Text className={'font-bold'}>WAAJA TECH SOLUTION</Text>
            </Flex>
            <img src={logoBankBNI} style={{ width: 64 }} className={'object-contain'} />
          </Flex>
          <Flex justify={'space-between'}>
            <Flex vertical>
              <Text>Nomor Rekening</Text>
              <Text className={'font-bold'}>1845819261</Text>
            </Flex>
            <TouchableOpacity
              onClick={() => {
                navigator.clipboard.writeText('1845819261');
              }}
            >
              <Text className={'font-bold text-primary'}>
                Salin <CopyOutlined />
              </Text>
            </TouchableOpacity>
          </Flex>
          <Flex justify={'space-between'}>
            <Flex vertical>
              <Text>Total Pembayaran</Text>
              <Text className={'font-bold'}>{displayPrice(totalAmount)}</Text>
            </Flex>
            <TouchableOpacity
              onClick={() => {
                navigator.clipboard.writeText(`${totalAmount}`);
              }}
            >
              <Text className={'font-bold text-primary'}>
                Salin <CopyOutlined />
              </Text>
            </TouchableOpacity>
          </Flex>

          <Text className={'text-center mt-8'}>
            Jangan lewatkan diskon dan promo spesial jika membayar menggunakan aplikasi{' '}
            <a href="https://referral-wondr.bni.co.id/Y7KA" target="_blank">
              wondr by BNI
            </a>
            . Yuk, gunakan sekarang!
          </Text>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
