import { Card, Col, Flex, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { displayPrice } from '@/utils';
import OrderStatusTag from '@pages/OrdersPage/components/OrderStatusTag.tsx';
import { IStoreOrder } from '@core/types/IStoreOrder.ts';
import { useMemo } from 'react';

interface MarketAjaProps {
  order: IStoreOrder;
}

const { Text } = Typography;

export default function MarketAja(props: MarketAjaProps) {
  const { order } = props;
  const navigate = useNavigate();

  const total = useMemo(() => {
    return order.total - order.delivery_fee_discount - order.order_discount;
  }, [order]);

  return (
    <Card
      className={'flex flex-1 order-card'}
      key={order.id}
      onClick={() => {
        navigate(PageRoutes.getStoreOrder(order.id));
      }}
    >
      <Row wrap={false}>
        <Col className={'p-3'}>
          <img src={order.store.logo_path} className={'object-cover rounded-xl'} style={{ width: 96, height: 96 }} />
        </Col>
        <Col flex={'auto'} className={'py-3 px-2 overflow-hidden whitespace-nowrap'}>
          <Flex vertical flex={1} className={'h-full'} gap={'small'} justify={'center'}>
            <Text strong className={'overflow-hidden text-ellipsis'} style={{ margin: 0 }}>
              {order.store.name}
            </Text>
            <Flex gap={'small'}>
              <Text className={'font-bold text-primary'}>{displayPrice(total)}</Text>
              <Text>({order.items?.reduce((prev, curr) => prev + curr.quantity, 0)} item)</Text>
            </Flex>
            <OrderStatusTag orderType={'store'} status={order.status} />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}
