import { Card, Divider, Flex, Skeleton, Typography } from 'antd';
import { displayPrice } from '@/utils';

const { Text } = Typography;

interface OrderPricingBreakdownProps {
  couponCode?: string;
  isLoading: boolean;
  subtotal?: number;
  serviceFee?: number;
  deliveryFee?: number;
  orderDiscount: number;
  deliveryDiscount: number;
  total?: number;
}

export default function OrderPricingBreakdown(props: OrderPricingBreakdownProps) {
  const { couponCode, isLoading, subtotal, serviceFee, deliveryFee, orderDiscount, deliveryDiscount, total } = props;

  if (subtotal === 0) {
    return null;
  }

  return (
    <Skeleton
      active
      loading={isLoading}
      paragraph={{
        rows: 2,
      }}
    >
      <Card>
        <Flex vertical gap={'small'}>
          {subtotal && (
            <Flex justify={'space-between'}>
              <Text className={'text-sm'}>Subtotal</Text>
              <Text className={'text-sm font-bold'}>{displayPrice(subtotal)}</Text>
            </Flex>
          )}
          {orderDiscount > 0 && (
            <Flex justify={'space-between'}>
              <Text className={'text-sm text-primary font-bold'}>{couponCode}</Text>
              <Text className={'text-sm text-primary font-bold'}>-{displayPrice(orderDiscount)}</Text>
            </Flex>
          )}
          <Flex justify={'space-between'}>
            <Text className={'text-sm'}>Biaya Layanan</Text>
            <Text className={'text-sm font-bold'}>{displayPrice(serviceFee)}</Text>
          </Flex>
          <Flex justify={'space-between'}>
            <Text className={'text-sm'}>Ongkos Kirim</Text>
            <Text className={'text-sm font-bold'}>{displayPrice(deliveryFee)}</Text>
          </Flex>
          {deliveryDiscount > 0 && (
            <Flex justify={'space-between'}>
              <Text className={'text-sm text-primary font-bold'}>{couponCode}</Text>
              <Text className={'text-sm text-primary font-bold'}>-{displayPrice(deliveryDiscount)}</Text>
            </Flex>
          )}
          <Divider className={'my-3'} />
          <Flex justify={'space-between'}>
            <Text className={'text-sm'}>Total</Text>
            <Text className={'text-sm font-bold'}>{displayPrice(total)}</Text>
          </Flex>
        </Flex>
      </Card>
    </Skeleton>
  );
}
