import { useGetRandomMenus } from '@/services/menu.service.ts';
import { Card, Col, Empty, Flex, Image, Row, Skeleton, Typography } from 'antd';
import { displayPrice } from '@/utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IMenu } from '@core/types/IMenu.ts';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Title, Text } = Typography;

export default function MenuForYou() {
  const navigate = useNavigate();
  const [data, setData] = useState<IMenu[]>([]);
  const [page, setPage] = useState(1);
  const { mutate, data: response, isIdle, isPending } = useGetRandomMenus();

  useEffect(() => {
    if (!response) return;
    setData((prev) => [...prev, ...response.data]);

    // Increment page if there is a next page
    if (response.next_page_url) {
      setPage((prev) => prev + 1);
    }
  }, [response]);

  // Get first page
  useEffect(() => {
    if (isIdle) {
      mutate(1);
    }
  }, []);

  return (
    <Flex vertical className={'mt-8'}>
      <Title level={3} style={{ margin: 0 }}>
        Menu Untukmu
      </Title>
      <InfiniteScroll
        dataLength={data.length}
        next={() => mutate(page)}
        hasMore={!!response?.next_page_url}
        scrollableTarget="scrollableDiv"
        loader={null}
      >
        <Flex className={'px-0 overflow-hidden'} vertical gap={'middle'}>
          {!isIdle && !isPending && !data.length ? (
            <Empty />
          ) : (
            <Row gutter={16}>
              {data.map((menu) => (
                <Col span={12} className={'py-2'} key={menu.id}>
                  <Card
                    styles={{ body: { padding: 16 } }}
                    hoverable
                    onClick={() => navigate(`/merchant/${menu.merchant_id}`)}
                  >
                    <Flex vertical>
                      <Image src={menu.image_path} height={120} className={'rounded-lg'} />
                      <Flex vertical className={'pt-2'}>
                        <Text strong>{menu.name}</Text>
                        <Text className={'text-primary font-bold'}>{displayPrice(menu.sell_price)}</Text>
                      </Flex>
                    </Flex>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          {isPending && (
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Skeleton.Image active />
                  <Skeleton className={'mt-4'} active paragraph={{ rows: 1 }} />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Skeleton.Image active />
                  <Skeleton className={'mt-4'} active paragraph={{ rows: 1 }} />
                </Card>
              </Col>
            </Row>
          )}
        </Flex>
      </InfiniteScroll>
    </Flex>
  );
}
