import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import './SearchInput.less';
import { ChangeEvent } from 'react';

interface SearchInputProps {
  defaultValue?: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput(props: SearchInputProps) {
  const { defaultValue, placeholder, onChange } = props;

  return (
    <Input
      defaultValue={defaultValue}
      className={'search-input'}
      variant={'filled'}
      prefix={<SearchOutlined />}
      placeholder={placeholder}
      onChange={onChange}
      autoFocus
    />
  );
}
