import { Card, Space, Typography } from 'antd';
import React, { useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import IProductDiscount from '@core/types/IProductDiscount.ts';
import { useProductCartStore } from '@/stores/product_cart.store.ts';
import DiscountedPrice from '@pages/StorePage/components/DiscountedPrice.tsx';
import { IProduct } from '@core/types/IProduct.ts';

const { Text } = Typography;

interface ProductSaleItemsProps {
  discount: IProductDiscount;
  onClick: (product: IProduct) => void;
}

export default function ProductSaleItems(props: ProductSaleItemsProps) {
  const { discount, onClick } = props;
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
    isMounted: !!ref.current,
  }); // Now we pass the reference to the useDraggable hook:
  const store = useProductCartStore((state) => state.store);

  return (
    <div
      className="pt-4 pb-2"
      style={{
        backgroundColor: '#1B9B6E',
      }}
    >
      <Text className={'font-bold text-white text-xl ml-6'}>{discount.name}</Text>
      <div className="flex space-x-4 p-3 overflow-x-scroll scrollbar-hide" {...events} ref={ref}>
        {discount.products?.map((product) => (
          <Card className={'flex-none no-padding'} style={{ width: 200 }} onClick={() => onClick(product)}>
            <img
              src={product.image_path.length ? product.image_path : store.logo_path}
              alt={product.name}
              style={{
                width: '100%',
                height: 100,
                objectFit: 'cover',
                borderTopRightRadius: 13,
                borderTopLeftRadius: 13,
              }}
            />
            <Space direction={'vertical'} className={'p-3'}>
              <Text className={'break-normal text-sm font-bold line-clamp-2'}>{product.name}</Text>
              <DiscountedPrice product={product} />
            </Space>
          </Card>
        ))}
      </div>
    </div>
  );
}
