import FullscreenModal from '@components/FullscreenModal';
import { Flex, message, Typography, Upload, UploadProps } from 'antd';
import Header from '@components/Header.tsx';
import { CloseIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import { FileDoneOutlined } from '@ant-design/icons';
import { API_HOST, API_PATH } from '@core/constants';
import { useAuthStore } from '@/stores/auth.store.ts';
import OrderType from '@core/types/OrderType.ts';
import imageCompression from 'browser-image-compression';

interface PaymentConfirmationProps {
  orderType: OrderType;
  orderId: string;
  visible: boolean;
  onClose: () => void;
}

const { Text } = Typography;
const { Dragger } = Upload;

export default function PaymentConfirmation(props: PaymentConfirmationProps) {
  const { orderType, orderId, visible, onClose } = props;
  const accessToken = useAuthStore((state) => state.accessToken);

  const requestUrl = () => {
    if (orderType === 'food') {
      return `${API_HOST}/${API_PATH.uploadPaymentProof(orderId)}`;
    }

    if (orderType === 'store') {
      return `${API_HOST}/${API_PATH.uploadStorePaymentProof(orderId)}`;
    }

    if (orderType == 'shipment') {
      return `${API_HOST}/${API_PATH.uploadShipmentPaymentProof(orderId)}`;
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.jpg, .jpeg, .png, .pdf',
    action: requestUrl(),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    beforeUpload: (file) => {
      return imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: false,
        preserveExif: true,
      });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} berhasil diunggah.`);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 'error') {
        message.error(`${info.file.name} gagal mengunggah file.`);
      }
    },
  };

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'bg-white'}>
        <Header
          title={'Konfirmasi Pembayaran'}
          accessoryLeft={
            <TouchableOpacity onClick={onClose}>
              <CloseIcon style={{ fontSize: 24, marginTop: 4 }} />
            </TouchableOpacity>
          }
        />

        <Flex flex={1} vertical className={'px-6 pb-6 bg-white'} gap={'middle'} align={'center'}>
          <Text className="font-bold ant-upload-text text-primary">Terima kasih telah melakukan pembayaran</Text>

          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <FileDoneOutlined />
            </p>

            <p className="ant-upload-hint mt-2">
              Untuk mempercepat proses verifikasi, klik disini untuk mengunggah bukti pembayaran Anda
            </p>
          </Dragger>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
