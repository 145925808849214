import { Flex, Skeleton } from 'antd';
import useWindowSize from '@utils/hooks/useWindowSize.ts';

export default function PageSkeleton() {
  const [, windowHeight] = useWindowSize();

  return (
    <Flex vertical>
      <Skeleton.Image active className={'w-full'} style={{ height: windowHeight * 0.25 }} />
      <Flex className={'p-6'} vertical gap={48}>
        <Skeleton active></Skeleton>
        <Skeleton active></Skeleton>
      </Flex>
    </Flex>
  );
}
