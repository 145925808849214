import { CartItem } from '@/stores/cart.store.ts';
import { ProductCartItem } from '@/stores/product_cart.store.ts';
import { IProduct, ProductUnit } from '@core/types/IProduct.ts';

export const buildOrderRequest = (
  merchant: string,
  cart: CartItem[],
  paymentMethod: number,
  serviceFee: number,
  deliveryFee: number,
  noteToDriver?: string
) => {
  let subtotal = 0;

  const menus = cart.map((item) => {
    const { menu, selectedAddons, quantity, remark, totalPrice } = item;
    const addons: string[] = [];

    subtotal += totalPrice;

    for (const key in selectedAddons) {
      selectedAddons[key].forEach((addon) => {
        addons.push(addon.id);
      });
    }

    return {
      id: menu.id,
      quantity,
      remark,
      addons,
    };
  });

  return {
    merchant,
    menus,
    paymentMethod,
    serviceFee,
    deliveryFee,
    subtotal,
    noteToDriver,
  };
};

export const buildStoreOrderRequest = (
  store: string,
  cart: ProductCartItem[],
  paymentMethod: number,
  serviceFee: number,
  deliveryFee: number,
  noteToDriver?: string
) => {
  let subtotal = 0;

  const products = cart.map((item) => {
    const { product, quantity, remark, totalPrice } = item;

    subtotal += totalPrice;

    return {
      id: product.id,
      quantity,
      remark,
    };
  });

  return {
    store,
    products,
    paymentMethod,
    serviceFee,
    deliveryFee,
    subtotal,
    noteToDriver,
  };
};

export const shouldShowUnit = (product: IProduct): string => {
  if (product.unit === ProductUnit.GRAM || product.unit === ProductUnit.KG) {
    return `/${product.unit.toLowerCase()}`;
  }

  return '';
};
