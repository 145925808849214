import { Button, Card, Divider, Flex, Image, Input, Modal, Typography } from 'antd';
import Header from '@components/Header.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { CloseIcon } from '@components/AppIcons';
import { displayPrice } from '@/utils';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { IAddonCategories } from '@/core/types/IAddonCategories.ts';
import { useEffect, useState } from 'react';
import { CartItem, useCartStore } from '@/stores/cart.store.ts';
import AddonCategory from '@pages/MerchantPage/components/AddonCategory.tsx';
import { track } from '@utils/analytic.util.ts';
import { useAuthStore } from '@/stores/auth.store.ts';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';

interface MenuPickerProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  mode: 'add' | 'edit';
}

type ActiveItemKeys = 'quantity' | 'remark';

const { Title, Text } = Typography;

export default function MenuPicker(props: MenuPickerProps) {
  const { mode, visible, setVisible } = props;
  const [windowWidth, windowHeight] = useWindowSize();
  const [addonsTotalPrice, setAddonsTotalPrice] = useState<number>(0);
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);
  const merchant = useCartStore((state) => state.merchant);
  const menu = useCartStore((state) => state.menu);
  const addToCart = useCartStore((state) => state.addToCart);
  const updateCartItem = useCartStore((state) => state.updateCartItem);
  const removeCartItem = useCartStore((state) => state.removeCartItem);

  const activeItem = useCartStore((state) => state.activeItem);
  const setActiveItem = useCartStore((state) => state.setActiveItem);

  // Calculate addons total price
  useEffect(() => {
    if (!activeItem?.selectedAddons) return;

    if (!Object.keys(activeItem.selectedAddons).length) {
      setAddonsTotalPrice(0);
      return;
    }

    let price = 0;

    for (const key in activeItem.selectedAddons) {
      activeItem.selectedAddons[key].forEach((item) => {
        price += item.price;
      });
    }

    setAddonsTotalPrice(price);
  }, [activeItem?.selectedAddons]);

  const onQuantityChanged = (value: number) => {
    if (!activeItem) return;

    const newValue = (activeItem?.quantity ?? 0) + value;
    const newItem = { ...activeItem };

    if (newValue < 0) {
      newItem.quantity = 0;
    } else if (newValue > 100) {
      newItem.quantity = 100;
    } else {
      newItem.quantity = newValue;
    }

    setActiveItem(newItem);
  };

  const calculateGrandTotal = (): number => {
    return ((menu.sell_price ?? 0) + addonsTotalPrice) * (activeItem?.quantity ?? 0);
  };

  const isValidMenu = (): boolean => {
    if (!activeItem?.selectedAddons) return false;

    return (
      menu?.addon_categories
        ?.map((category) => {
          const { is_mandatory, max_selection } = category;

          if (!is_mandatory) {
            return true;
          }

          const selectedOptions = activeItem?.selectedAddons[category.id]?.length ?? 0;
          return selectedOptions > 0 && selectedOptions <= max_selection;
        })
        .every(Boolean) ?? false
    );
  };

  const addMenuToCart = (): void => {
    // Redirect to auth page if user is not logged in
    if (!user) {
      navigate(PageRoutes.LOGIN);
      return;
    }

    // Ignore current item if qty is zero
    if (activeItem?.quantity === 0) {
      setVisible(false);
      return;
    }

    const cartItem: CartItem = {
      ...activeItem!,
      menu: menu!,
      totalPrice: calculateGrandTotal(),
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };

    track('ADD_TO_CART', { menuId: menu?.id, merchantId: merchant.id, menuName: menu?.name });
    addToCart(cartItem);
    setVisible(false);
  };

  const updateCart = (): void => {
    // Remove item from cart if qty is zero
    if (activeItem?.quantity === 0) {
      track('REMOVE_FROM_CART', { menuId: menu?.id, merchantId: merchant.id, menuName: menu?.name });
      removeCartItem(activeItem.uuid);
      setVisible(false);
      return;
    }

    const cartItem: CartItem = {
      ...activeItem!,
      totalPrice: calculateGrandTotal(),
      updatedAt: Date.now(),
    };

    track('EDIT_CART_ITEM', { menuId: menu?.id, merchantId: merchant.id, menuName: menu?.name });
    updateCartItem(cartItem);
    setVisible(false);
  };

  const updateActiveItem = (key: ActiveItemKeys, value: number | string) => {
    const updatedItem = { ...activeItem! };

    switch (key) {
      case 'remark':
        updatedItem.remark = value as string;
        break;
      case 'quantity':
        updatedItem.quantity = value as number;
        break;
    }

    setActiveItem(updatedItem);
  };

  const onCloseClicked = () => {
    setVisible(false);
  };

  const renderAddons = (addonCategories?: IAddonCategories[]) => {
    if (!addonCategories) {
      return null;
    }

    return addonCategories.map((addonCategory) => {
      return <AddonCategory key={addonCategory.id} addonCategory={addonCategory} />;
    });
  };

  const renderButtonText = () => {
    const textToDisplay = mode === 'add' ? 'Tambahkan ke Keranjang' : 'Update Keranjang';
    const priceToDisplay = displayPrice(calculateGrandTotal(), false);
    const zeroQuantity = activeItem?.quantity === 0;

    if (zeroQuantity && mode === 'add') {
      return 'Kembali ke Menu';
    }

    if (zeroQuantity && mode === 'edit') {
      return 'Hapus';
    }

    return `${textToDisplay} - ${priceToDisplay}`;
  };

  if (!activeItem) {
    return null;
  }

  return (
    <Modal
      centered
      open={visible}
      width={windowWidth}
      style={{ height: windowHeight }}
      closable={false}
      footer={null}
      className={'app-modal bg-transparent'}
    >
      <Flex vertical flex={1}>
        <Flex flex={1} vertical className={'pb-6'}>
          <Flex className={'absolute z-[1]'}>
            <Header
              accessoryLeft={
                <TouchableOpacity onClick={onCloseClicked}>
                  <div
                    className={'flex items-center justify-center rounded-full bg-black bg-opacity-50'}
                    style={{ height: 36, width: 36 }}
                  >
                    <CloseIcon style={{ fontSize: 24, color: 'white' }} />
                  </div>
                </TouchableOpacity>
              }
            />
          </Flex>

          <Image
            src={menu?.image_path.length ? menu.image_path : merchant.logo_path}
            className={'object-cover'}
            height={windowHeight * 0.25}
          />

          <Flex vertical>
            <Flex flex={1} vertical className={'p-6 bg-white'}>
              <Flex justify={'space-between'}>
                <Text className={'font-bold m-0'}>{menu?.name}</Text>
                <Text className={'m-0 font-bold text-primary whitespace-nowrap'}>{displayPrice(menu?.sell_price)}</Text>
              </Flex>
              {menu?.description && (
                <>
                  <Divider />
                  <Text>{menu?.description}</Text>
                </>
              )}
            </Flex>

            {renderAddons(menu?.addon_categories)}

            <Card className={'mt-2 rounded-none border-none'}>
              <Flex gap={'small'} align={'center'} className={'mb-6'}>
                <Text className={'font-bold m-0'}>Catatan untuk resto</Text>
                <Text className={'text-sm'}>(Opsional)</Text>
              </Flex>
              <Input
                placeholder="misalnya: jangan terlalu pedas"
                maxLength={64}
                value={activeItem.remark}
                onChange={(e) => updateActiveItem('remark', e.target.value)}
              />
            </Card>

            <Flex align={'center'} justify={'center'} gap={'middle'} className={'mt-6'}>
              <Button icon={<MinusOutlined />} className={'text-primary'} onClick={() => onQuantityChanged(-1)} />
              <Title className={'m-0'}>{activeItem.quantity}</Title>
              <Button icon={<PlusOutlined />} className={'text-primary'} onClick={() => onQuantityChanged(1)} />
            </Flex>
          </Flex>
        </Flex>

        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button
            type={'primary'}
            danger={renderButtonText() === 'Hapus'}
            className={'w-full'}
            disabled={!isValidMenu()}
            onClick={mode === 'add' ? addMenuToCart : updateCart}
          >
            {renderButtonText()}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
