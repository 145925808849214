import { Button, Card, Divider, Flex, Typography } from 'antd';
import { CartItem, useCartStore } from '@/stores/cart.store.ts';
import { displayPrice } from '@/utils';
import TouchableOpacity from '@components/TouchableOpacity';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { IAddon } from '@/core/types/IAddon.ts';
import MenuPicker from '@pages/MerchantPage/components/MenuPicker.tsx';
import { track } from '@utils/analytic.util.ts';

const { Title, Text } = Typography;

export default function OrderSummary() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const merchant = useCartStore((state) => state.merchant);
  const cart = useCartStore((state) => state.cart);
  const setMenu = useCartStore((state) => state.setMenu);
  const setActiveItem = useCartStore((state) => state.setActiveItem);

  const getAddonsText = (item: CartItem): string => {
    return Object.keys(item.selectedAddons)
      .reduce((prev: IAddon[], curr) => {
        return [...prev, ...item.selectedAddons[curr]];
      }, [])
      .map((i) => i.name)
      .join(', ');
  };

  const addMoreItems = () => {
    track('ADD_MORE_CART_ITEM');
    navigate(-1);
  };

  const editItem = (item: CartItem) => {
    setActiveItem(item);
    setMenu(item.menu);
    setModalVisible(true);
  };

  return (
    <>
      <Card>
        <Flex justify={'space-between'}>
          <Title level={3} className={'m-0'}>
            Rincian Pesanan
          </Title>
          <Button ghost size={'small'} type={'primary'} className={'px-3 font-bold'} onClick={addMoreItems}>
            Tambah
          </Button>
        </Flex>

        <Divider />

        {cart.map((item: CartItem, index) => (
          <Fragment key={item.uuid}>
            {!!index && <Divider />}
            <Flex gap={'middle'}>
              <img
                src={item.menu.image_path.length ? item.menu.image_path : merchant.logo_path}
                className={'h-full object-cover rounded-xl'}
                width={56}
              />
              <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} justify={'center'} gap={'small'}>
                <Text className={'overflow-hidden text-ellipsis m-0'}>{item.menu.name}</Text>
                <Text className={'font-bold text-primary m-0'}>{displayPrice(item.totalPrice)}</Text>
              </Flex>
              <Flex vertical gap={'small'} justify={'center'} align={'center'}>
                <Text strong>{item.quantity}x</Text>
                <TouchableOpacity onClick={() => editItem(item)}>
                  <Text strong className={'text-primary'}>
                    edit
                  </Text>
                </TouchableOpacity>
              </Flex>
            </Flex>
            <Flex className={'mt-4'} vertical>
              {getAddonsText(item) && <Text className={'text-xs text-info'}>*Variasi: {getAddonsText(item)}</Text>}
              {item.remark && <Text className={'text-xs text-error'}>*Catatan: {item.remark}</Text>}
            </Flex>
          </Fragment>
        ))}
      </Card>
      <MenuPicker mode="edit" visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
}
