import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCartStore } from '@/stores/cart.store.ts';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { track } from '@utils/analytic.util.ts';
import useWindowSize from '@utils/hooks/useWindowSize.ts';

interface MenuSearchProps {
  searchTerm: string;
  onChange: (value: string) => void;
}

export default function MenuSearch(props: MenuSearchProps) {
  const { searchTerm, onChange } = props;
  const merchant = useCartStore((state) => state.merchant);
  const [sticky, setSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [width] = useWindowSize();
  const [headerOffsetTop, setHeaderOffsetTop] = useState(0);

  useEffect(() => {
    if (headerRef.current === null) return;
    setHeaderOffsetTop(headerRef.current.offsetTop);
  }, []);

  // add/remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerOffsetTop]);

  // handle scroll event
  const handleScroll = () => {
    if (headerRef.current === null) return;

    if (window.scrollY > headerOffsetTop) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={`${sticky ? 'bg-white px-6 pb-6 z-[1] sticky top-0 pt-6 shadow' : 'bg-white px-6 pb-6 z-[1]'}`}
      ref={headerRef}
      style={{ width: width }}
    >
      <Input
        prefix={<SearchOutlined className={'pr-1'} style={{ fontSize: 18 }} />}
        placeholder={`Cari menu ${merchant.name}`}
        value={searchTerm}
        onFocus={() => track('SEARCH_MERCHANT_MENU')}
        onChange={onTextChange}
      />
    </div>
  );
}
