import { Button, Flex, Form, Input, Typography } from 'antd';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import { useAuthStore } from '@/stores/auth.store.ts';
import { updateProfile, UpdateProfileDto } from '@/services/profile.service.ts';
import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';
import { HOME_ROUTE } from '@/routes/AppRoute.tsx';
import Header from '@components/Header.tsx';
import MapWithMarker from '@components/MapWithMarker.tsx';

const { Text } = Typography;

export default function ProfilePage() {
  const user = useAuthStore((state) => state.user!);
  const updateUser = useAuthStore((state) => state.updateUser);
  const [submittable, setSubmittable] = useState(false);
  const [userLocation, setUserLocation] = useState<
    | {
        latitude: number;
        longitude: number;
      }
    | undefined
  >(() => {
    return user.latitude && user.longitude
      ? {
          latitude: user.latitude,
          longitude: user.longitude,
        }
      : undefined;
  });
  const [profileData, setProfileData] = useState<UpdateProfileDto>();
  const [form] = Form.useForm();

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['updateProfile'],
    queryFn: () => updateProfile(profileData!),
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });

  useEffect(() => {
    if (!profileData) return;
    refetch();
  }, [profileData]);

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const onValidForm = (values: UpdateProfileDto) => {
    setProfileData({ ...values, ...userLocation });
  };

  if (data) {
    updateUser(data.user);
    return <Navigate to={HOME_ROUTE} />;
  }

  return (
    <Flex vertical className={'min-h-screen'}>
      <Flex className={'bg-white'}>
        <Header title={'Profil Saya'} />
      </Flex>

      <Flex flex={1} className={'px-6 bg-white'} vertical>
        <Form<UpdateProfileDto>
          className={'flex flex-col flex-1'}
          layout={'vertical'}
          form={form}
          initialValues={{
            phone_number: user.phone_number,
            name: user.name,
            whatsapp_number: user.whatsapp_number,
            address: user.address,
            latitude: user.latitude,
            longitude: user.longitude,
          }}
          onFinish={onValidForm}
        >
          <Form.Item label="Nomor telepon" name={'phone_number'}>
            <Input variant={'filled'} disabled />
          </Form.Item>
          <Form.Item label="Nama" name={'name'} required rules={[{ required: true, message: '' }]}>
            <Input variant={'filled'} />
          </Form.Item>
          <Form.Item label="Nomor Whatsapp" name={'whatsapp_number'} required rules={[{ required: true, message: '' }]}>
            <Input variant={'filled'} />
          </Form.Item>
          <Form.Item label="Alamat" name={'address'} required rules={[{ required: true, message: '' }]}>
            <Input variant={'filled'} />
          </Form.Item>
          <MapWithMarker
            height={360}
            onCoordinateChanged={(latitude, longitude) => setUserLocation({ latitude, longitude })}
            coordinate={
              userLocation
                ? {
                    lat: userLocation.latitude,
                    lng: userLocation.longitude,
                  }
                : undefined
            }
          />
          <Flex justify={'center'}>
            <Text className={'text-sm text-error'}>Geser pin sesuai dengan lokasi alamat kamu</Text>
          </Flex>
        </Form>
      </Flex>

      <Flex className={'p-6'}>
        <Button
          type="primary"
          className={'w-full'}
          htmlType="submit"
          loading={isFetching}
          disabled={!submittable}
          onClick={() => {
            setProfileData({
              ...form.getFieldsValue(),
              ...userLocation,
            });
          }}
        >
          Update
        </Button>
      </Flex>
    </Flex>
  );
}
