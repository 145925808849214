import FullscreenModal from '@components/FullscreenModal';
import { Button, Flex, Radio, RadioChangeEvent, Typography } from 'antd';
import Header from '@components/Header.tsx';
import { useUserStore } from '@/stores/user.store.ts';
import Address from '@components/Address.tsx';
import { useSetDefaultAddress } from '@/services/customer.service.ts';
import { useEffect } from 'react';
import TouchableOpacity from '@components/TouchableOpacity';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '@utils/hooks/useWindowSize.ts';

interface ChangeDefaultAddressProps {
  visible: boolean;
  onClose: () => void;
}

const { Text } = Typography;

export default function ChangeDefaultAddress(props: ChangeDefaultAddressProps) {
  const navigate = useNavigate();
  const { visible, onClose } = props;
  const { addresses, defaultAddress, setDefaultAddress, setAddresses } = useUserStore((state) => state);
  const { mutate, data, isPending } = useSetDefaultAddress();
  const [width] = useWindowSize();

  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);

  const onChangeDefaultAddress = (e: RadioChangeEvent) => {
    const selected = addresses.find((address) => address.id === e.target.value);
    const newAddresses = addresses.map((address) => {
      address.default = address.id === e.target.value;
      return address;
    });

    if (selected) {
      setAddresses(newAddresses);
      setDefaultAddress(selected);
    }
  };

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical style={{ width }}>
        <Header title={'Pilih Alamat Pengantaran'} />

        <Radio.Group
          className={'flex flex-1 w-full px-6 pb-6'}
          value={defaultAddress.id}
          onChange={onChangeDefaultAddress}
        >
          <Flex flex={1} vertical gap={'middle'}>
            {addresses.map((address) => (
              <Address address={address} key={address.id} accessoryRight={<Radio value={address.id} />} />
            ))}

            <Flex justify={'center'}>
              <TouchableOpacity onClick={() => navigate(PageRoutes.MY_ADDRESS)}>
                <Text className={'font-bold text-sm text-primary'}>Tambah Alamat Baru</Text>
              </TouchableOpacity>
            </Flex>
          </Flex>
        </Radio.Group>

        <Flex className={'p-6 bg-white'}>
          <Button
            type={'primary'}
            className={'w-full'}
            loading={isPending}
            onClick={() => {
              mutate(defaultAddress.id);
            }}
          >
            Simpan
          </Button>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
