import { Avatar, Button, Col, Divider, Flex, Input, Modal, Rate, Row, Typography } from 'antd';
import TouchableOpacity from '@components/TouchableOpacity';
import React, { useEffect, useState } from 'react';
import FullscreenModal from '@components/FullscreenModal';
import Header from '@components/Header.tsx';
import { useRateShipmentOrder } from '@services/kirim_aja.service.ts';

const { TextArea } = Input;
const { Text, Title } = Typography;

interface ShipmentOrderRatingProps {
  driverName: string;
  driverPhoto: string;
  orderId: string;
}

export default function ShipmentOrderRating(props: ShipmentOrderRatingProps) {
  const { driverName, driverPhoto, orderId } = props;

  const [visible, setVisible] = useState(false);
  const [ratingData, setRatingData] = useState({
    driverRating: 5,
    driverReview: '',
  });

  const { mutate, data, isPending, isError, failureReason } = useRateShipmentOrder();

  useEffect(() => {
    if (!data) return;

    Modal.success({
      title: 'Pesanan berhasil direview',
      content: 'Terima kasih telah memberikan ulasan untuk pesanan kamu!',
      centered: true,
      okText: 'Oke',
      onOk: () => {
        location.reload();
      },
    });
  }, [data]);

  useEffect(() => {
    if (!isError) return;

    Modal.error({
      title: 'Pesanan gagal direview',
      content: failureReason?.message ?? 'Terjadi kesalahan sistem, silahkan coba lagi nanti.',
      centered: true,
      okText: 'Oke',
    });
  }, [isError]);

  const goBack = () => {
    setVisible(false);
  };

  const setRating = (value: number) => {
    setRatingData((prev) => ({
      ...prev,
      driverRating: value,
    }));
  };

  const setReview = (value: string) => {
    setRatingData((prev) => ({
      ...prev,
      driverReview: value,
    }));
  };

  const submit = () => {
    mutate({
      order_id: orderId,
      driver_rating: ratingData.driverRating,
      driver_review: ratingData.driverReview,
    });
  };

  const renderPage = () => {
    return (
      <React.Fragment>
        <Flex flex={1} vertical className={'px-6 pb-6'} align={'center'}>
          <Avatar
            src={driverPhoto}
            style={{
              width: 100,
              height: 100,
            }}
          />

          <Title level={2} className={'mt-6 text-center'}>
            Beri nilai untuk pelayanan kurir {driverName}
          </Title>

          <Text className={'text-secondary text-center mt-2'}>Bagaimana proses pengiriman paket kamu?</Text>

          <Rate
            allowHalf
            value={ratingData.driverRating}
            allowClear={false}
            className={'mt-6'}
            style={{ fontSize: 36 }}
            onChange={(value) => setRating(value)}
          />

          <Divider />

          <TextArea
            variant={'filled'}
            placeholder="Misalnya: Kurirnya ramah 🤩, pesanan sampai tepat waktu, dan pesanan saya dalam kondisi baik. Terima kasih!"
            autoSize={{ minRows: 3, maxRows: 4 }}
            maxLength={160}
            value={ratingData.driverReview}
            onChange={(e) => setReview(e.target.value)}
          />
        </Flex>

        <Row className={'px-6 pb-6'} gutter={16}>
          <Col span={12}>
            <Button type={'default'} className={'w-full'} onClick={goBack} disabled={isPending}>
              Batal
            </Button>
          </Col>
          <Col span={12}>
            <Button type={'primary'} className={'w-full'} onClick={submit} loading={isPending}>
              Kirim
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Flex justify={'center'}>
        <TouchableOpacity onClick={() => setVisible(true)}>
          <Text className={'text-primary font-bold'}>⭐ Beri nilai untuk pesanan ini ⭐</Text>
        </TouchableOpacity>
      </Flex>

      <FullscreenModal visible={visible}>
        <Flex flex={1} vertical className={'flex-1 bg-white'}>
          <Header showBackButton onBackButtonClicked={goBack} />
          {renderPage()}
        </Flex>
      </FullscreenModal>
    </React.Fragment>
  );
}
