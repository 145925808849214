import { Card, Divider, Flex, Typography } from 'antd';
import { Fragment } from 'react';
import { IStore } from '@core/types/IStore.ts';
import IStoreOrderItem from '@core/types/IStoreOrderItem.ts';
import StoreOrderItem from '@components/StoreOrderSummary/StoreOrderItem.tsx';

const { Title } = Typography;

interface StoreOrderSummaryProps {
  store: IStore;
  orderItems?: IStoreOrderItem[];
}

export default function StoreOrderSummary(props: StoreOrderSummaryProps) {
  const { store, orderItems } = props;

  return (
    <Card>
      <Flex justify={'space-between'}>
        <Title level={3} className={'m-0'}>
          Rincian Pesanan
        </Title>
        {/*<Button ghost size={'small'} type={'primary'} className={'px-3 font-bold'} onClick={() => navigate(-1)}>*/}
        {/*  Tambah*/}
        {/*</Button>*/}
      </Flex>

      <Divider />

      {orderItems?.map((item: IStoreOrderItem, index) => (
        <Fragment key={item.id}>
          {!!index && <Divider />}
          <StoreOrderItem
            productImage={item.product?.image_path.length ? item.product?.image_path : store.logo_path}
            productName={item.name}
            totalPrice={item.total}
            quantity={item.quantity}
            addons={item.addons}
            remark={item.remark}
          />
        </Fragment>
      ))}
    </Card>
  );
}
