import AppLayout from '@components/Layouts/AppLayout';
import logoUrl from '@assets/images/logo-dark.png';
import { Button, Flex, Image, Input, Typography } from 'antd';
import useWindowSize from '@/utils/hooks/useWindowSize.ts';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLogin } from '@/services/auth.service.ts';
import { LOGIN_OTP_ROUTE } from '@/routes/AppRoute.tsx';

export default function PhoneLoginPage() {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [validPhone, setValidPhone] = useState<boolean>(false);

  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { mutate, data, isPending } = useLogin();

  const onVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [refreshReCaptcha]
  );

  useEffect(() => {
    setValidPhone(phoneNumber?.length >= 8);
  }, [phoneNumber]);

  useEffect(() => {
    if (!data) return;
    navigate(LOGIN_OTP_ROUTE, { state: { phoneNumber: `+62${phoneNumber}` } });
  }, [data]);

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value.startsWith('0', 0) ? value.substring(1) : value); // remove leading zero
  };

  const doLogin = () => {
    setRefreshReCaptcha((r) => !r);
    mutate({ phoneNumber: `+62${phoneNumber}`, token: token! });
  };

  return (
    <AppLayout pageTitle={'Login'} showHeader={false}>
      <Flex vertical flex={1}>
        <Flex vertical flex={1}>
          <Flex flex={1} align={'center'} justify={'center'}>
            <Image preview={false} src={logoUrl} width={width / 3} />
          </Flex>
          <Flex flex={2} vertical>
            <Typography.Title className={'text-center'}>Masuk ke Akun Anda</Typography.Title>
            <Input
              variant={'filled'}
              addonBefore="🇮🇩 +62"
              placeholder={'812345678'}
              className={'mt-8'}
              onChange={onChangePhoneNumber}
            />
            <Button
              type={'primary'}
              className={'mt-8'}
              onClick={doLogin}
              disabled={!validPhone || !token}
              id={'sign-in-button'}
              loading={isPending}
            >
              Masuk
            </Button>
          </Flex>
        </Flex>
        <Flex justify={'center'}>
          <Typography.Text type={'secondary'}>v1.0.0</Typography.Text>
        </Flex>
      </Flex>
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
    </AppLayout>
  );
}
