import { Button, Flex } from 'antd';
import Header from '@components/Header.tsx';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { useGetMyAddresses } from '@/services/customer.service.ts';
import ListLoader from '@components/ListLoader.tsx';
import AddEditAddressModal from '@pages/MyAddressPage/components/AddEditAddressModal.tsx';
import { useEffect, useState } from 'react';
import IAddress from '@core/types/IAddress.ts';
import Address from '@components/Address.tsx';
import { EditOutlined } from '@ant-design/icons';
import TouchableOpacity from '@components/TouchableOpacity';

export default function MyAddressPage() {
  const navigate = useNavigate();
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [selectedAddress, setSelectedAddress] = useState<IAddress>();
  const [visible, setVisible] = useState<boolean>(false);
  const [needsRefetch, setNeedsRefects] = useState<boolean>(false);

  const { data, refetch, isFetching } = useGetMyAddresses();

  useEffect(() => {
    if (needsRefetch) {
      refetch();
      setNeedsRefects(false);
    }
  }, [needsRefetch]);

  // Reset modal state
  useEffect(() => {
    if (!visible) {
      setSelectedAddress(undefined);
      setMode('add');
    }
  }, [visible]);

  const showAddModal = () => {
    setVisible(true);
  };

  const showEditModal = (address: IAddress) => {
    setSelectedAddress(address);
    setMode('edit');
    setVisible(true);
  };

  const hideAddEditModal = (refreshData: boolean = false) => {
    setVisible(false);

    if (refreshData) {
      setNeedsRefects(true);
    }
  };

  return (
    <Flex vertical className={'min-h-screen'} style={{ background: '#f7f7f7' }}>
      <Header title={'Alamat Saya'} showBackButton onBackButtonClicked={() => navigate(PageRoutes.SETTINGS)} />

      <Flex flex={1} className={'px-6 pb-6'} vertical gap={'middle'}>
        {isFetching ? (
          <ListLoader rows={1} />
        ) : (
          data?.map((address) => (
            <Address
              address={address}
              key={address.id}
              accessoryRight={
                <TouchableOpacity onClick={() => showEditModal(address)}>
                  <EditOutlined className={'text-primary p-2'} />
                </TouchableOpacity>
              }
            />
          ))
        )}
      </Flex>

      <Flex className={'bg-white p-6'}>
        <Button type={'primary'} className={'w-full'} onClick={showAddModal}>
          Tambah Alamat
        </Button>
      </Flex>

      <AddEditAddressModal mode={mode} selectedAddress={selectedAddress} visible={visible} onClose={hideAddEditModal} />
    </Flex>
  );
}
