import { Button, Flex, Modal } from 'antd';
import OrderStatus from '@core/enums/OrderStatus.ts';
import ShipmentOrderStatus from '@core/enums/kirim_aja/OrderStatus.ts';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useCancelOrder } from '@services/order.service.ts';
import { useCancelShipmentOrder } from '@services/kirim_aja.service.ts';
import OrderType from '@core/types/OrderType.ts';

interface CancelOrderProps {
  orderId: string;
  orderType: OrderType;
  status: OrderStatus | ShipmentOrderStatus;
  onCanceled: () => void;
}

const { confirm } = Modal;

export default function CancelOrder(props: CancelOrderProps) {
  const { orderId, status, orderType, onCanceled } = props;
  const [actionConfirmed, setActionConfirmed] = useState(false);

  const { mutate, data, isPending } = useCancelOrder();
  const { mutate: mutateShipment, data: shipmentData, isPending: isPendingShipment } = useCancelShipmentOrder();

  useEffect(() => {
    if (!actionConfirmed) {
      return;
    }

    if (orderType === 'food') {
      mutate(orderId);
    }

    if (orderType === 'shipment') {
      mutateShipment(orderId);
    }
  }, [actionConfirmed]);

  useEffect(() => {
    if (data || shipmentData) {
      onCanceled();
    } else {
      setActionConfirmed(false);
    }
  }, [data, shipmentData]);

  const showCancelOrderConfirmation = () => {
    confirm({
      centered: true,
      title: 'Apakah kamu yakin membatalkan pesanan ini?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Ya, Batalkan',
      okType: 'danger',
      cancelText: 'Tidak',
      onOk() {
        setActionConfirmed(true);
      },
    });
  };

  if (
    status !== OrderStatus.WAITING_FOR_CUSTOMER_PAYMENT &&
    status !== ShipmentOrderStatus.WAITING_FOR_CUSTOMER_PAYMENT
  ) {
    return null;
  }

  return (
    <Flex className={'bg-white p-6 rounded-xl'}>
      <Button
        className={'w-full'}
        type={'primary'}
        danger
        onClick={showCancelOrderConfirmation}
        loading={isPending || isPendingShipment}
      >
        Batalkan Pesanan
      </Button>
    </Flex>
  );
}
