import { IFoodOrder } from '@core/types/IFoodOrder.ts';
import IOrder from '@core/types/base/IOrder.ts';
import MakanAja from '@pages/OrdersPage/components/MakanAja.tsx';
import { IShipmentOrder } from '@core/types/kirim_aja/IShipmentOrder.ts';
import KirimAja from '@pages/OrdersPage/components/KirimAja.tsx';
import MarketAja from '@pages/OrdersPage/components/MarketAja.tsx';
import OrderType from '@core/types/OrderType.ts';
import { IStoreOrder } from '@core/types/IStoreOrder.ts';

interface OrderListProps {
  orders: IOrder[];
  type: OrderType;
}

export default function OrderList(props: OrderListProps) {
  const { orders, type } = props;

  if (type === 'food') {
    return (orders as IFoodOrder[]).map((order) => <MakanAja order={order} key={order.id} />);
  }

  if (type === 'store') {
    return (orders as IStoreOrder[]).map((order) => <MarketAja order={order} key={order.id} />);
  }

  if (type === 'shipment') {
    return (orders as IShipmentOrder[]).map((order) => <KirimAja order={order} key={order.id} />);
  }

  return null;
}
