import { FileTextOutlined, LogoutOutlined, PushpinOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import './SettingsPage.less';
import { firebaseAuth } from '@/configs/firebase.ts';
import { useAuthStore } from '@/stores/auth.store.ts';
import Header from '@components/Header.tsx';
import { track } from '@utils/analytic.util.ts';
import { PageRoutes } from '@/routes/AppRoute.tsx';

export default function SettingsPage() {
  const { user, logout } = useAuthStore((state) => state);
  const onLogoutClick = async () => {
    track('LOGOUT');
    logout();
    await firebaseAuth.signOut();
  };

  return (
    <>
      <Flex className={'bg-white'}>
        <Header title={'Pengaturan'} />
      </Flex>

      <Flex vertical className={'px-6 pb-6 bg-white'}>
        <Flex align={'center'} className={'mt-4'}>
          <Avatar size={64} icon={<UserOutlined />} />
          <Flex flex={1} vertical gap={8} className={'ml-4'}>
            <Typography.Text strong>{user?.name}</Typography.Text>
            <Typography.Text>{user?.phone_number}</Typography.Text>
          </Flex>
        </Flex>

        <Divider />

        <Link type={'link'} className={'neutral-link py-2 px-4'} to={'/profile'}>
          <Flex>
            <UserOutlined />
            <Flex flex={1} className={'pl-4'}>
              <Typography.Text>Profile</Typography.Text>
            </Flex>
            <RightOutlined />
          </Flex>
        </Link>

        <Link type={'link'} className={'neutral-link py-2 px-4'} to={'/my-address'}>
          <Flex>
            <PushpinOutlined />
            <Flex flex={1} className={'pl-4'}>
              <Typography.Text>Alamat</Typography.Text>
            </Flex>
            <RightOutlined />
          </Flex>
        </Link>

        <Link type={'link'} className={'neutral-link py-2 px-4'} to={PageRoutes.PRIVACY_POLICY}>
          <Flex>
            <FileTextOutlined />
            <Flex flex={1} className={'pl-4'}>
              <Typography.Text>Kebijakan Privasi</Typography.Text>
            </Flex>
            <RightOutlined />
          </Flex>
        </Link>

        <Link type={'button'} className={'neutral-link py-2 px-4'} to={'#'} onClick={onLogoutClick}>
          <Flex>
            <LogoutOutlined />
            <Flex flex={1} className={'pl-4'}>
              <Typography.Text>Logout</Typography.Text>
            </Flex>
          </Flex>
        </Link>
      </Flex>
    </>
  );
}
