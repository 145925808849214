import { IMerchant } from '@/core/types/IMerchant.ts';
import { authorizedClient, PaginatedResponse } from '@/services/api.service.ts';
import { API_PATH } from '@/core/constants';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IFoodOrder } from '@core/types/IFoodOrder.ts';

interface OrderMenu {
  id: string;
  quantity: number;
  remark?: string;
  addons?: string[];
}

interface SubmitOrderDto {
  merchant: string;
  menus: OrderMenu[];
  paymentMethod: number;
  serviceFee: number;
  deliveryFee: number;
  subtotal: number;
  latitude: number;
  longitude: number;
  coupon_code?: string;
}

interface RateOrderDto {
  order_id: string;
  driver_rating: number;
  driver_review: string;
  merchant_rating: number;
  merchant_review: string;
}

interface SubmitOrderResponse {
  merchant: IMerchant;
}

const submitOrder = async (order: SubmitOrderDto) => {
  return authorizedClient.post(API_PATH.SUBMIT_ORDER, order).then(
    (response: AxiosResponse<SubmitOrderResponse>) => response.data,
    (error) => {
      if (error.response.status === 422) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    }
  );
};

export const useSubmitOrder = () => {
  return useMutation({
    mutationKey: ['submitOrder'],
    mutationFn: (order: SubmitOrderDto) => submitOrder(order),
  });
};

export type OrderStatusCategory = 'activeForCustomer' | 'completed' | 'canceled';

export interface GetOrdersResponse extends PaginatedResponse<IFoodOrder> {}

const getOrders = async (statusCategory: OrderStatusCategory, page: number) => {
  return authorizedClient
    .get(API_PATH.GET_ORDERS, {
      params: { statusCategory, page },
    })
    .then((response: AxiosResponse<GetOrdersResponse>) => response.data);
};

export const useGetOrders = (statusCategory: OrderStatusCategory) => {
  return useMutation({
    mutationKey: ['getOrders', statusCategory],
    mutationFn: (page: number) => getOrders(statusCategory, page),
  });
};

const getOrder = async (orderId: string) => {
  return authorizedClient.get(API_PATH.getOrder(orderId)).then((response: AxiosResponse<IFoodOrder>) => response.data);
};

export const useGetOrder = (orderId: string) => {
  return useQuery({
    queryKey: ['getOrder', orderId],
    queryFn: () => getOrder(orderId),
    refetchOnWindowFocus: false,
  });
};

const cancelOrder = async (orderId: string) => {
  return authorizedClient.delete(API_PATH.getOrder(orderId)).then(() => true);
};

export const useCancelOrder = () => {
  return useMutation({
    mutationKey: ['cancelOrder'],
    mutationFn: (orderId: string) => cancelOrder(orderId),
  });
};

const rateOrder = async (data: RateOrderDto) => {
  return authorizedClient
    .post(API_PATH.rateOrder(data.order_id), data)
    .then(() => true)
    .catch((error) => {
      if (error.response.status === 400) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    });
};

export const useRateOrder = () => {
  return useMutation({
    mutationKey: ['rateOrder'],
    mutationFn: (data: RateOrderDto) => rateOrder(data),
  });
};
