import { authorizedClient, PaginatedResponse } from '@/services/api.service.ts';
import { API_PATH } from '@/core/constants';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { OrderStatusCategory } from '@services/order.service.ts';
import { IShipmentOrder } from '@core/types/kirim_aja/IShipmentOrder.ts';

export interface Location {
  address: string;
  latitude: number;
  longitude: number;
}

export interface ShipmentData {
  sender_name: string;
  sender_phone: string;
  recipient_name: string;
  recipient_phone: string;
  item_details: string;
  item_weight: number;
}

interface ShippingRoutesDto {
  origin: Location;
  destination: Location[];
}

interface CalculateShippingFeesResponse {
  service_fee: number;
  shipping_fee: number;
  distance: number;
}

interface SubmitShippingOrderDto extends ShipmentData, ShippingRoutesDto {
  payment_method: number;
}

interface RateOrderDto {
  order_id: string;
  driver_rating: number;
  driver_review: string;
}

const calculateShippingFees = async (data: ShippingRoutesDto) => {
  return authorizedClient
    .post(API_PATH.CALCULATE_SHIPPING_FEES, data)
    .then((response: AxiosResponse<CalculateShippingFeesResponse>) => response.data);
};

export const useCalculateShippingFees = () => {
  return useMutation({
    mutationKey: ['calculateShippingFees'],
    mutationFn: (data: ShippingRoutesDto) => calculateShippingFees(data),
  });
};

const submitShippingOrder = async (order: SubmitShippingOrderDto) => {
  return authorizedClient.post(API_PATH.SUBMIT_SHIPPING_ORDER, order).then(
    () => true,
    (error) => {
      if (error.response.status === 422) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    }
  );
};

export const useSubmitShippingOrder = () => {
  return useMutation({
    mutationKey: ['submitShippingOrder'],
    mutationFn: (order: SubmitShippingOrderDto) => submitShippingOrder(order),
  });
};

export interface GetShipmentOrdersResponse extends PaginatedResponse<IShipmentOrder> {}

const getShipmentOrders = async (statusCategory: OrderStatusCategory, page: number) => {
  return authorizedClient
    .get(API_PATH.GET_SHIPMENT_ORDERS, {
      params: { statusCategory, page },
    })
    .then((response: AxiosResponse<GetShipmentOrdersResponse>) => response.data);
};

export const useGetShipmentOrders = (statusCategory: OrderStatusCategory) => {
  return useMutation({
    mutationKey: ['getShipmentOrders', statusCategory],
    mutationFn: (page: number) => getShipmentOrders(statusCategory, page),
  });
};

const getShipmentOrder = async (orderId: string) => {
  return authorizedClient
    .get(API_PATH.getShipmentOrder(orderId))
    .then((response: AxiosResponse<IShipmentOrder>) => response.data);
};

export const useGetShipmentOrder = (orderId: string) => {
  return useQuery({
    queryKey: ['getShipmentOrder', orderId],
    queryFn: () => getShipmentOrder(orderId),
    refetchOnWindowFocus: false,
  });
};

const cancelShipmentOrder = async (orderId: string) => {
  return authorizedClient.delete(API_PATH.getShipmentOrder(orderId)).then(() => true);
};

export const useCancelShipmentOrder = () => {
  return useMutation({
    mutationKey: ['cancelShipmentOrder'],
    mutationFn: (orderId: string) => cancelShipmentOrder(orderId),
  });
};

const rateShipmentOrder = async (data: RateOrderDto) => {
  return authorizedClient
    .post(API_PATH.rateShipmentOrder(data.order_id), data)
    .then(() => true)
    .catch((error) => {
      if (error.response.status === 400) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    });
};

export const useRateShipmentOrder = () => {
  return useMutation({
    mutationKey: ['rateShipmentOrder'],
    mutationFn: (data: RateOrderDto) => rateShipmentOrder(data),
  });
};
