import { IFoodOrder } from '@core/types/IFoodOrder.ts';
import { Card, Flex, Typography } from 'antd';
import { displayDate } from '@/utils';
import OrderStatus from '@core/enums/OrderStatus.ts';
import { IShipmentOrder } from '@core/types/kirim_aja/IShipmentOrder.ts';
import { IStoreOrder } from '@core/types/IStoreOrder.ts';

interface CancelationDataProps {
  order: IFoodOrder | IShipmentOrder | IStoreOrder;
}

const { Text } = Typography;

export default function CancelationData(props: CancelationDataProps) {
  const { order } = props;

  if (order.status !== OrderStatus.CANCELED) {
    return null;
  }

  return (
    <Card className={'bg-red-100'}>
      <Flex justify={'space-between'} className={'mb-2'}>
        <Text className={'text-sm'}>Waktu Batal</Text>
        <Text className={'text-sm'}>{order.canceled_at ? displayDate(order.canceled_at) : ''}</Text>
      </Flex>
      <Flex justify={'space-between'} className={'mb-2'}>
        <Text className={'text-sm'}>Dibatalkan Oleh</Text>
        <Text className={'text-sm'}>{order.canceled_from}</Text>
      </Flex>
      <Flex justify={'space-between'} className={'mb-2'}>
        <Text className={'text-sm'}>Alasan Pembatalan</Text>
        <Text className={'text-sm'}>{order.canceled_reason ?? '-'}</Text>
      </Flex>
    </Card>
  );
}
