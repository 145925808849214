import { IStore } from '@/core/types/IStore.ts';
import { authorizedClient, PaginatedResponse } from '@/services/api.service.ts';
import { API_PATH } from '@/core/constants';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IFoodOrder } from '@core/types/IFoodOrder.ts';
import { IStoreOrder } from '@core/types/IStoreOrder.ts';

interface OrderProduct {
  id: string;
  quantity: number;
  remark?: string;
}

interface SubmitOrderDto {
  store: string;
  products: OrderProduct[];
  paymentMethod: number;
  serviceFee: number;
  deliveryFee: number;
  subtotal: number;
  latitude: number;
  longitude: number;
  coupon_code?: string;
}

interface SubmitOrderResponse {
  store: IStore;
}

const submitOrder = async (order: SubmitOrderDto) => {
  return authorizedClient.post(API_PATH.SUBMIT_STORE_ORDER, order).then(
    (response: AxiosResponse<SubmitOrderResponse>) => response.data,
    (error) => {
      if (error.response.status === 422) {
        return Promise.reject(new Error(error.response.data.message));
      }
      return Promise.reject(error);
    }
  );
};

export const useSubmitOrder = () => {
  return useMutation({
    mutationKey: ['submitOrder'],
    mutationFn: (order: SubmitOrderDto) => submitOrder(order),
  });
};

export type OrderStatusCategory = 'activeForCustomer' | 'completed' | 'canceled';

export interface GetOrdersResponse extends PaginatedResponse<IFoodOrder> {}

const getOrders = async (statusCategory: OrderStatusCategory, page: number) => {
  return authorizedClient
    .get(API_PATH.GET_STORE_ORDERS, {
      params: { statusCategory, page },
    })
    .then((response: AxiosResponse<GetOrdersResponse>) => response.data);
};

export const useGetOrders = (statusCategory: OrderStatusCategory) => {
  return useMutation({
    mutationKey: ['getOrders', statusCategory],
    mutationFn: (page: number) => getOrders(statusCategory, page),
  });
};

const getOrder = async (orderId: string) => {
  return authorizedClient
    .get(API_PATH.getStoreOrder(orderId))
    .then((response: AxiosResponse<IStoreOrder>) => response.data);
};

export const useGetOrder = (orderId: string) => {
  return useQuery({
    queryKey: ['getOrder', orderId],
    queryFn: () => getOrder(orderId),
    refetchOnWindowFocus: false,
  });
};

const cancelOrder = async (orderId: string) => {
  return authorizedClient.delete(API_PATH.getOrder(orderId)).then(() => true);
};

export const useCancelOrder = () => {
  return useMutation({
    mutationKey: ['cancelOrder'],
    mutationFn: (orderId: string) => cancelOrder(orderId),
  });
};
