import { Card, Divider, Flex, Typography } from 'antd';
import { Fragment } from 'react';
import IFoodOrderItem from '@core/types/IFoodOrderItem.ts';
import OrderItem from '@components/OrderSummary/OrderItem.tsx';
import { IMerchant } from '@core/types/IMerchant.ts';

const { Title } = Typography;

interface OrderSummaryProps {
  merchant: IMerchant;
  orderItems?: IFoodOrderItem[];
}

export default function OrderSummary(props: OrderSummaryProps) {
  const { merchant, orderItems } = props;

  return (
    <Card>
      <Flex justify={'space-between'}>
        <Title level={3} className={'m-0'}>
          Rincian Pesanan
        </Title>
        {/*<Button ghost size={'small'} type={'primary'} className={'px-3 font-bold'} onClick={() => navigate(-1)}>*/}
        {/*  Tambah*/}
        {/*</Button>*/}
      </Flex>

      <Divider />

      {orderItems?.map((item: IFoodOrderItem, index) => (
        <Fragment key={item.id}>
          {!!index && <Divider />}
          <OrderItem
            menuImage={item.menu?.image_path.length ? item.menu?.image_path : merchant.logo_path}
            menuName={item.name}
            totalPrice={item.total}
            quantity={item.quantity}
            addons={item.addons}
            remark={item.remark}
          />
        </Fragment>
      ))}
    </Card>
  );
}
