export const getPaymentStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return 'Menunggu Pembayaran';
    case 1:
      return 'Verifikasi Pembayaran';
    case 2:
      return 'Cash On Delivery';
    case 3:
      return 'Pembayaran Diterima';
    default:
      return '';
  }
};

export const getOrderStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return 'Dibatalkan';
    case 1:
      return 'Menunggu Pembayaran';
    case 2:
      return 'Verifikasi Pembayaran';
    case 3:
      return 'Menunggu Konfirmasi Restoran';
    case 4:
      return 'Mencari Driver';
    case 5:
      return 'Pesanan Sedang Disiapkan';
    case 6:
      return 'Pesanan Siap Diambil';
    case 7:
      return 'Pesanan Diterima Driver';
    case 8:
      return 'Pesanan Sedang Diantar';
    case 9:
      return 'Pesanan Selesai';
    default:
      return '';
  }
};
