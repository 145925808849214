import { Card, Col, Flex, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { displayPrice } from '@/utils';
import OrderStatusTag from '@pages/OrdersPage/components/OrderStatusTag.tsx';
import { IShipmentOrder } from '@core/types/kirim_aja/IShipmentOrder.ts';
import packageImg from '@assets/images/parcel.png';
import { useMemo } from 'react';

interface KirimAjaProps {
  order: IShipmentOrder;
}

const { Text } = Typography;

export default function KirimAja(props: KirimAjaProps) {
  const { order } = props;
  const navigate = useNavigate();

  const total = useMemo(() => {
    return order.total - order.delivery_fee_discount - order.order_discount;
  }, [order]);

  return (
    <Card
      className={'flex flex-1 order-card'}
      key={order.id}
      onClick={() => {
        navigate(PageRoutes.getShipmentOrder(order.id));
      }}
    >
      <Row wrap={false}>
        <Col className={'p-3 m-auto'}>
          <img src={packageImg} className={'object-cover rounded-xl p-1'} style={{ width: 86, height: 86 }} />
        </Col>
        <Col flex={'auto'} className={'py-3 px-2 overflow-hidden whitespace-nowrap'}>
          <Flex vertical flex={1} className={'h-full'} gap={'small'} justify={'center'}>
            <Text strong className={'overflow-hidden text-ellipsis'} style={{ margin: 0 }}>
              {order.sender_name} <span className={'mx-2'}>&#8674;</span>
              {order.recipient_name}
            </Text>
            <Flex gap={'small'}>
              <Text className={'font-bold text-primary'}>{displayPrice(total)}</Text>
            </Flex>
            <OrderStatusTag orderType={'shipment'} status={order.status} />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}
