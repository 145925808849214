import { authorizedClient, PaginatedResponse } from './api.service.ts';
import { AxiosResponse } from 'axios';
import { API_PATH } from '@/core/constants';
import { useMutation } from '@tanstack/react-query';
import { IProduct } from '@core/types/IProduct.ts';
import { IStore } from '@core/types/IStore.ts';

export interface GetProductByTermResponse extends PaginatedResponse<IStore> {}

const getProductsByTerm = async (term: string, page: number) => {
  return authorizedClient
    .get(API_PATH.GET_PRODUCT_BY_TERM, { params: { term, page } })
    .then((response: AxiosResponse<GetProductByTermResponse>) => response.data);
};

export const useGetProductsByTerm = () => {
  return useMutation({
    mutationKey: ['getProductsByTerm'],
    mutationFn: (params: { term: string; page: number }) => getProductsByTerm(params.term, params.page),
  });
};

export interface GetRandomProductsResponse extends PaginatedResponse<IProduct> {}

const getRandomProducts = async (page: number) => {
  return authorizedClient
    .get(API_PATH.GET_RANDOM_PRODUCTS, { params: { page } })
    .then((response: AxiosResponse<GetRandomProductsResponse>) => response.data);
};

export const useGetRandomProducts = () => {
  return useMutation({
    mutationKey: ['getRandomProducts'],
    mutationFn: (page: number) => getRandomProducts(page),
  });
};

export interface GetStoreProductsResponse extends PaginatedResponse<IProduct> {}

interface GetStoreProductsDto {
  storeSlug: string;
  term: string;
  page: number;
  category?: string;
}

const getStoreProducts = async (data: GetStoreProductsDto) => {
  return authorizedClient
    .get(API_PATH.getStoreProducts(data.storeSlug), {
      params: {
        term: data.term,
        page: data.page,
        category: data.category,
      },
    })
    .then((response: AxiosResponse<GetStoreProductsResponse>) => response.data);
};

export const useGetStoreProducts = () => {
  return useMutation({
    mutationKey: ['getStoreProducts'],
    mutationFn: (getStoreProductsDto: GetStoreProductsDto) => getStoreProducts(getStoreProductsDto),
  });
};
