import { authorizedClient } from './api.service.ts';
import { AxiosResponse } from 'axios';
import { API_PATH } from '@/core/constants';
import { useMutation } from '@tanstack/react-query';
import { IMenu } from '@core/types/IMenu.ts';
import { IMerchant } from '@core/types/IMerchant.ts';

export interface GetMenuByTermResponse {
  current_page: number;
  data: IMerchant[];
  first_page_url: string;
  from?: number;
  to?: number;
  path: string;
  per_page: number;
  next_page_url?: string;
  prev_page_url?: string;
}

const getMenusByTerm = async (term: string, page: number) => {
  return authorizedClient
    .get(API_PATH.GET_MENU_BY_TERM, { params: { term, page } })
    .then((response: AxiosResponse<GetMenuByTermResponse>) => response.data);
};

export const useGetMenusByTerm = () => {
  return useMutation({
    mutationKey: ['getMenusByTerm'],
    mutationFn: (params: { term: string; page: number }) => getMenusByTerm(params.term, params.page),
  });
};

export interface GetRandomMenusResponse {
  current_page: number;
  data: IMenu[];
  first_page_url: string;
  from?: number;
  to?: number;
  path: string;
  per_page: number;
  next_page_url?: string;
  prev_page_url?: string;
}

const getRandomMenus = async (page: number) => {
  return authorizedClient
    .get(API_PATH.GET_RANDOM_MENUS, { params: { page } })
    .then((response: AxiosResponse<GetRandomMenusResponse>) => response.data);
};

export const useGetRandomMenus = () => {
  return useMutation({
    mutationKey: ['getRandomMenus'],
    mutationFn: (page: number) => getRandomMenus(page),
  });
};
