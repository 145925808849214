import { useEffect } from 'react';
import { useGetStoreSale } from '@services/store.service.ts';
import ProductSaleItems from '@pages/StorePage/components/ProductSaleItems.tsx';
import { Flex } from 'antd';
import { IProduct } from '@core/types/IProduct.ts';

interface ProductSaleProps {
  slug?: string;
  onClick: (product: IProduct) => void;
}

export default function ProductSale(props: ProductSaleProps) {
  const { slug, onClick } = props;
  const { mutate, data } = useGetStoreSale();

  useEffect(() => {
    if (!slug) return;
    mutate(slug);
  }, [slug]);

  if (!data) return null;

  return (
    <Flex vertical className={'pt-4'} style={{ backgroundColor: '#F5F5F5' }}>
      {data?.map((discount) => <ProductSaleItems key={discount.id} discount={discount} onClick={onClick} />)}
    </Flex>
  );
}
