enum OrderStatus {
  CANCELED,
  WAITING_FOR_CUSTOMER_PAYMENT,
  WAITING_FOR_PAYMENT_VERIFICATION,
  SEARCHING_FOR_DRIVER,
  DRIVER_GOING_TO_PICKUP_LOCATION,
  ORDER_RECEIVED_BY_DRIVER,
  ON_DELIVERY,
  COMPLETED,
}

export default OrderStatus;
