import { authorizedClient } from '@/services/api.service.ts';
import { API_PATH } from '@core/constants';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import IPromotion from '@core/types/IPromotion.ts';

export interface AddUpdateAddressDto {
  label: string;
  address: string;
  latitude: number;
  longitude: number;
}

const getPromotions = async () => {
  return authorizedClient.get(API_PATH.GET_PROMOTIONS).then((response: AxiosResponse<IPromotion[]>) => response.data);
};

export const useGetPromotions = () => {
  return useQuery({
    queryKey: ['getPromotions'],
    queryFn: () => getPromotions(),
    refetchOnWindowFocus: false,
  });
};
