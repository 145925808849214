import { authorizedClient } from './api.service.ts';
import { AxiosResponse } from 'axios';
import { User } from '../core/models/User';
import { API_PATH } from '@/core/constants';

export interface UpdateProfileDto {
  name: string;
  whatsapp_number: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface UpdateProfileResponse {
  user: User;
}

export const updateProfile = async (data: UpdateProfileDto) => {
  return authorizedClient.post(API_PATH.PROFILE, data).then((response: AxiosResponse<UpdateProfileResponse>) => response.data);
};
