import { Flex } from 'antd';
import SearchInput from '@components/SearchInput.tsx';
import Promotions from '@pages/HomePage/components/Promotions.tsx';
import FoodCategories from '@pages/HomePage/components/FoodCategories.tsx';
import MenuForYou from '@pages/HomePage/components/MenuForYou.tsx';
import TopHeader from '@pages/HomePage/components/TopHeader.tsx';
import Services from '@pages/HomePage/components/Services.tsx';

export default function HomePage() {
  return (
    <Flex vertical className={'p-6'}>
      <TopHeader />

      <Flex className={'mt-6'}>
        <SearchInput />
      </Flex>

      <Services />

      <Promotions />

      <FoodCategories />

      <MenuForYou />
    </Flex>
  );
}
