import { Layout, Typography } from 'antd';
import React from 'react';

interface AppHeaderProps {
  title?: string;
  accessoryLeft?: React.ReactNode;
  accessoryRight?: React.ReactNode;
}

export default function AppHeader(props: AppHeaderProps) {
  const { title, accessoryLeft, accessoryRight } = props;

  return (
    <Layout.Header className={'bg-white px-2 py-2 sticky top-0 z-[1001]'}>
      {accessoryLeft && accessoryLeft}
      <Typography.Text className={'font-bold text-xl ml-2'}>{title}</Typography.Text>
      {accessoryRight && accessoryRight}
    </Layout.Header>
  );
}
