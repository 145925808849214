import axios from 'axios';
import { useAuthStore } from '@/stores/auth.store.ts';
import { firebaseAuth } from '@/configs/firebase.ts';
import { API_HOST } from '@/core/constants';

export interface PaginatedResponse<T> {
  current_page: number;
  first_page_url: string;
  from?: number;
  to?: number;
  path: string;
  per_page: number;
  next_page_url?: string;
  prev_page_url?: string;
  data: T[];
}

const client = axios.create({
  baseURL: API_HOST,
});

const authorizedClient = axios.create({
  baseURL: API_HOST,
});

authorizedClient.interceptors.request.use(function (config) {
  const accessToken = useAuthStore.getState().accessToken;
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

authorizedClient.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  function (response) {
    return response;
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  function (error) {
    // accessToken is expired, force logout user
    if (error.response.status === 401) {
      (async () => {
        await firebaseAuth.signOut();
        useAuthStore.getState().logout();
      })();
    }

    return Promise.reject(error);
  }
);

export { client, authorizedClient };
