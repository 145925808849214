import FullscreenModal from '@components/FullscreenModal';
import { Flex, Typography } from 'antd';
import Header from '@components/Header.tsx';
import { CloseIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import qrisImg from '@assets/images/wa-aja-qris.jpg';
import { displayPrice } from '@/utils';
import { CopyOutlined } from '@ant-design/icons';

interface BankTransferProps {
  visible: boolean;
  onClose: () => void;
  totalAmount: number;
}

const { Text } = Typography;

export default function QRIS(props: BankTransferProps) {
  const { totalAmount, visible, onClose } = props;

  return (
    <FullscreenModal visible={visible}>
      <Flex flex={1} vertical className={'bg-white'}>
        <Header
          title={'QRIS'}
          accessoryLeft={
            <TouchableOpacity onClick={onClose}>
              <CloseIcon style={{ fontSize: 24, marginTop: 4 }} />
            </TouchableOpacity>
          }
        />

        <Flex flex={1} vertical className={'px-6 pb-6 bg-white'} gap={'middle'}>
          <Flex justify={'space-between'} align={'center'}>
            <Flex vertical>
              <Text>Total Pembayaran</Text>
              <Text className={'font-bold'}>{displayPrice(totalAmount)}</Text>
            </Flex>
            <TouchableOpacity
              onClick={() => {
                navigator.clipboard.writeText(`${totalAmount}`);
              }}
            >
              <Text className={'font-bold text-primary'}>
                Salin <CopyOutlined />
              </Text>
            </TouchableOpacity>
          </Flex>

          <img src={qrisImg} alt="wa-aja qris" />

          <Text className={'text-center'}>
            Jangan lewatkan diskon dan promo spesial jika membayar menggunakan aplikasi{' '}
            <a href="https://referral-wondr.bni.co.id/Y7KA" target="_blank">
              wondr by BNI
            </a>
            . Yuk, gunakan sekarang!
          </Text>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
}
