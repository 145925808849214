import Icon from '@ant-design/icons';
import HomeOutlined from '@assets/icons/outlined/home-outlined.svg?react';
import TaskListSquaredFilled from '@assets/icons/filled/task-list-squared-filled.svg?react';
import TaskListSquaredOutlined from '@assets/icons/outlined/task-list-squared-outlined.svg?react';
import GearFilled from '@assets/icons/filled/gear-filled.svg?react';
import GearOutlined from '@assets/icons/outlined/gear-outlined.svg?react';
import LocationOutlined from '@assets/icons/outlined/location-outlined.svg?react';
import LocationFilled from '@assets/icons/filled/location-filled.svg?react';
import ArrowLeft from '@assets/icons/arrow-left.svg?react';
import Close from '@assets/icons/close.svg?react';
import Bike from '@assets/icons/bike.svg?react';
import BankNoteDollar from '@assets/icons/banknote-dollar.svg?react';
import Bank from '@assets/icons/bank.svg?react';
import WalletFilled from '@assets/icons/filled/wallet-filled.svg?react';
import WalletOutlined from '@assets/icons/outlined/wallet-outlined.svg?react';

import { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

export const HomeIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={HomeOutlined} {...props} />;
};

export const TaskListSquaredFilledIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={TaskListSquaredFilled} {...props} />;
};

export const TaskListSquaredOutlinedIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={TaskListSquaredOutlined} {...props} />;
};

export const SettingsFilledIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={GearFilled} {...props} />;
};

export const SettingsOutlinedIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={GearOutlined} {...props} />;
};

export const LocationFilledIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={LocationFilled} {...props} />;
};

export const LocationOutlinedIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={LocationOutlined} {...props} />;
};

export const ArrowLeftIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ArrowLeft} {...props} />;
};

export const CloseIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={Close} {...props} />;
};

export const BikeIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={Bike} {...props} />;
};
export const BankNoteDollarIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={BankNoteDollar} {...props} />;
};

export const BankIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={Bank} {...props} />;
};

export const WalletFilledIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={WalletFilled} {...props} />;
};

export const WalletOutlinedIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={WalletOutlined} {...props} />;
};
