import { Button, Divider, Flex, Image, Modal, Typography } from 'antd';
import Header from '@components/Header.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { CloseIcon } from '@components/AppIcons';
import { displayPrice } from '@/utils';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { track } from '@utils/analytic.util.ts';
import { ProductCartItem, useProductCartStore } from '@/stores/product_cart.store.ts';
import { useMemo } from 'react';
import { shouldShowUnit } from '@utils/order.util.ts';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/stores/auth.store.ts';
import { PageRoutes } from '@/routes/AppRoute.tsx';

interface ProductPickerProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  mode: 'add' | 'edit';
}

const { Title, Text } = Typography;

export default function ProductPicker(props: ProductPickerProps) {
  const { mode, visible, setVisible } = props;
  const [windowWidth, windowHeight] = useWindowSize();
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);
  const store = useProductCartStore((state) => state.store);
  const product = useProductCartStore((state) => state.product);
  const addToCart = useProductCartStore((state) => state.addToCart);
  const updateCartItem = useProductCartStore((state) => state.updateCartItem);
  const removeCartItem = useProductCartStore((state) => state.removeCartItem);

  const activeItem = useProductCartStore((state) => state.activeItem);
  const setActiveItem = useProductCartStore((state) => state.setActiveItem);

  const sellPriceWithDiscount = useMemo(() => {
    return product.sell_price - product.discount_amount;
  }, [product]);

  const onQuantityChanged = (value: number) => {
    if (!activeItem) return;

    const newValue = (activeItem?.quantity ?? 0) + value;
    const newItem = { ...activeItem };

    if (newValue < 0) {
      newItem.quantity = 0;
    } else if (newValue > 100) {
      newItem.quantity = 100;
    } else {
      newItem.quantity = newValue;
    }

    setActiveItem(newItem);
  };

  const calculateGrandTotal = (): number => {
    return sellPriceWithDiscount * (activeItem?.quantity ?? 0);
  };

  const addProductToCart = (): void => {
    // Redirect to auth page if user is not logged in
    if (!user) {
      navigate(PageRoutes.LOGIN);
      return;
    }

    // Ignore current item if qty is zero
    if (activeItem?.quantity === 0) {
      setVisible(false);
      return;
    }

    const cartItem: ProductCartItem = {
      ...activeItem!,
      product: product!,
      totalPrice: calculateGrandTotal(),
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };

    track('ADD_TO_CART', { productId: product?.id, storeId: store.id, productName: product?.name });
    addToCart(cartItem);
    setVisible(false);
  };

  const updateCart = (): void => {
    // Remove item from cart if qty is zero
    if (activeItem?.quantity === 0) {
      track('REMOVE_FROM_CART', { productId: product?.id, storeId: store.id, productName: product?.name });
      removeCartItem(activeItem.uuid);
      setVisible(false);
      return;
    }

    const cartItem: ProductCartItem = {
      ...activeItem!,
      totalPrice: calculateGrandTotal(),
      updatedAt: Date.now(),
    };

    track('EDIT_CART_ITEM', { productId: product?.id, storeId: store.id, productName: product?.name });
    updateCartItem(cartItem);
    setVisible(false);
  };

  const onCloseClicked = () => {
    setVisible(false);
  };

  const renderButtonText = () => {
    const textToDisplay = mode === 'add' ? 'Tambahkan ke Keranjang' : 'Update Keranjang';
    const priceToDisplay = displayPrice(calculateGrandTotal(), false);
    const zeroQuantity = activeItem?.quantity === 0;

    if (zeroQuantity && mode === 'add') {
      return 'Kembali ke Product';
    }

    if (zeroQuantity && mode === 'edit') {
      return 'Hapus';
    }

    return `${textToDisplay} - ${priceToDisplay}`;
  };

  if (!activeItem) {
    return null;
  }

  return (
    <Modal
      centered
      open={visible}
      width={windowWidth}
      style={{ height: windowHeight }}
      closable={false}
      footer={null}
      className={'app-modal bg-transparent'}
    >
      <Flex vertical flex={1}>
        <Flex flex={1} vertical className={'pb-6'}>
          <Flex className={'absolute z-[1]'}>
            <Header
              accessoryLeft={
                <TouchableOpacity onClick={onCloseClicked}>
                  <div
                    className={'flex items-center justify-center rounded-full bg-black bg-opacity-50'}
                    style={{ height: 36, width: 36 }}
                  >
                    <CloseIcon style={{ fontSize: 24, color: 'white' }} />
                  </div>
                </TouchableOpacity>
              }
            />
          </Flex>

          <Image
            src={product?.image_path.length ? product.image_path : store.logo_path}
            className={'object-cover'}
            height={windowHeight * 0.25}
          />

          <Flex vertical>
            <Flex flex={1} vertical className={'p-6 bg-white'}>
              <Flex justify={'space-between'}>
                <Text className={'font-bold m-0'}>{product.name}</Text>
                <Text className={'m-0 font-bold text-primary whitespace-nowrap'}>
                  {displayPrice(sellPriceWithDiscount)} {shouldShowUnit(product)}
                </Text>
              </Flex>
              {product?.description && (
                <>
                  <Divider />
                  <Text>{product?.description}</Text>
                </>
              )}
            </Flex>

            <Flex align={'center'} justify={'center'} gap={'middle'} className={'mt-6'}>
              <Button icon={<MinusOutlined />} className={'text-primary'} onClick={() => onQuantityChanged(-1)} />
              <Title className={'m-0'}>{activeItem.quantity}</Title>
              <Button icon={<PlusOutlined />} className={'text-primary'} onClick={() => onQuantityChanged(1)} />
            </Flex>
          </Flex>
        </Flex>

        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button
            type={'primary'}
            danger={renderButtonText() === 'Hapus'}
            className={'w-full'}
            onClick={mode === 'add' ? addProductToCart : updateCart}
          >
            {renderButtonText()}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
