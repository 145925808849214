import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyDxGuhBxnJnxz26Y7HU-nmlTZdSX8pahpk',
  authDomain: 'wa-aja-app.firebaseapp.com',
  projectId: 'wa-aja-app',
  storageBucket: 'wa-aja-app.appspot.com',
  messagingSenderId: '511156549879',
  appId: '1:511156549879:web:2fe58a11d79f74eb09bbf2',
  measurementId: 'G-PGVFWBCSG8',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
