import { Col, Flex, Image, Row, Typography } from 'antd';
import friedRiceUrl from '@assets/images/fried-rice.png';
import pizzaUrl from '@assets/images/pizza.png';
import burgerUrl from '@assets/images/cheese-burger.png';
import noodleUrl from '@assets/images/ramen.png';
import iceCreamUrl from '@assets/images/ice-cream.png';
import chickenUrl from '@assets/images/chicken-leg.png';
import cakeUrl from '@assets/images/cake.png';
import meatUrl from '@assets/images/meat.png';
import drinksUrl from '@assets/images/cocktail.png';
import satayUrl from '@assets/images/satay.png';
import soupUrl from '@assets/images/hot-soup.png';
import breadUrl from '@assets/images/bread.png';
import Header from '@/components/Header';

const { Text } = Typography;

export default function FoodCategoriesPage() {
  return (
    <Flex vertical>
      <Header title={'Kategori Makanan'} />

      <Flex vertical>
        <Row gutter={[2, { xs: 28, sm: 16, md: 24, lg: 32 }]} className={'mt-4'}>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={friedRiceUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Nasi Goreng
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={pizzaUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Pizza
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={burgerUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Burger
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={noodleUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Mie
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={iceCreamUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Es Krim
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={chickenUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Ayam
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={cakeUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Kue
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={meatUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Daging
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={drinksUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Minuman
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={breadUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Roti
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={satayUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Sate
              </Text>
            </Flex>
          </Col>
          <Col className="gutter-row" span={6}>
            <Flex vertical align={'center'}>
              <Image src={soupUrl} preview={false} width={42} />
              <Text strong className={'text-xs mt-2'}>
                Sup
              </Text>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </Flex>
  );
}
