import { Card, Divider, Flex, Typography } from 'antd';
import { humanizeDistance } from '@/utils';
import { IStore } from '@/core/types/IStore.ts';
import { useNavigate } from 'react-router-dom';
import { LocationOutlinedIcon } from '@components/AppIcons';
import { useCartStore } from '@/stores/cart.store.ts';
import { track } from '@utils/analytic.util.ts';
import DiscountedPrice from '@pages/StorePage/components/DiscountedPrice.tsx';

const { Text } = Typography;

export default function StoreListItem({ store }: { store: IStore }) {
  const navigate = useNavigate();
  const clearCart = useCartStore((state) => state.clearCart);

  const onStoreSelected = () => {
    track('CHOOSING_STORE', { store_id: store.id });
    clearCart();
    navigate(`/store/${store.slug}`);
  };

  return (
    <Card hoverable className={'mb-4'} onClick={onStoreSelected}>
      <Flex gap={'middle'}>
        <img
          src={store.logo_path}
          className={'object-cover rounded-xl'}
          style={{
            width: 64,
            height: 64,
          }}
        />
        <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} gap={'small'} justify={'center'}>
          <Text strong className={'overflow-hidden text-ellipsis'} style={{ margin: 0 }}>
            {store.name}
          </Text>
          <Flex align={'center'} gap={'small'}>
            <Text className={'text-sm'} color={'primary'}>
              <LocationOutlinedIcon /> Estimasi {humanizeDistance(store.distance)}
            </Text>
            {/*Disable rating ui*/}
            {/*<Text>|</Text>*/}
            {/*<StarFilled className={'text-sm text-yellow-400'} />*/}
            {/*<Text className={'text-sm'}>4.7 (1.5k)</Text>*/}
          </Flex>
          {/*Disable delivery fees*/}
          {/*<Text className={'text-sm'}>Rp. 2500</Text>*/}
        </Flex>
      </Flex>

      {(store.products?.length ?? 0) > 0 && (
        <>
          <Divider />

          <Flex gap={'middle'} vertical>
            {store.products?.map((product) => (
              <Flex gap={'middle'} key={product.id}>
                <img
                  src={product.image_path.length ? product.image_path : store.logo_path}
                  className={'object-cover rounded-xl'}
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
                <Flex flex={1} gap={'small'} vertical justify={'center'}>
                  <Text strong className={'overflow-hidden text-ellipsis text-sm'} style={{ margin: 0 }}>
                    {product.name}
                  </Text>
                  <Flex gap={'small'}>
                    <DiscountedPrice product={product} />
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Card>
  );
}
