import { Layout } from 'antd';
import AppFooter from '@components/Layouts/AppLayout/AppFooter.tsx';
import { Outlet } from 'react-router-dom';

export default function FoodLayout() {
  return (
    <Layout className={'min-h-screen'} style={{ background: '#F7F7F7' }}>
      <Layout.Content className={'h-full overflow-auto'}>
        <Outlet />
      </Layout.Content>
      <Layout.Footer className={'bg-white px-2 py-2 sticky bottom-0 z-[1001]'}>
        <AppFooter />
      </Layout.Footer>
    </Layout>
  );
}
