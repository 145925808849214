import { Card, Divider, Flex, Tag, Typography } from 'antd';
import { displayPrice, humanizeDistance } from '@/utils';
import { IMerchant } from '@/core/types/IMerchant.ts';
import { useNavigate } from 'react-router-dom';
import { LocationOutlinedIcon } from '@components/AppIcons';
import { useCartStore } from '@/stores/cart.store.ts';
import { track } from '@utils/analytic.util.ts';

const { Text } = Typography;

export default function MerchantListItem({ merchant }: { merchant: IMerchant }) {
  const navigate = useNavigate();
  const clearCart = useCartStore((state) => state.clearCart);

  const onMerchantSelected = () => {
    track('CHOOSING_MERCHANT', { merchant_id: merchant.id });
    clearCart();
    navigate(`/merchant/${merchant.id}`);
  };

  return (
    <Card hoverable className={'mb-4'} onClick={onMerchantSelected}>
      <Flex gap={'middle'}>
        <img
          src={merchant.logo_path}
          className={'object-cover rounded-xl'}
          style={{
            width: 64,
            height: 86,
          }}
        />
        <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} justify={'center'} gap={'small'}>
          <Text strong className={'overflow-hidden text-ellipsis'} style={{ margin: 0 }}>
            {merchant.name}
          </Text>
          <Flex align={'center'} gap={'small'}>
            <Text className={'text-sm'} color={'primary'}>
              <LocationOutlinedIcon /> Estimasi {humanizeDistance(merchant.distance)}
            </Text>
            {/*Disable rating ui*/}
            {/*<Text>|</Text>*/}
            {/*<StarFilled className={'text-sm text-yellow-400'} />*/}
            {/*<Text className={'text-sm'}>4.7 (1.5k)</Text>*/}
          </Flex>
          <Flex align={'center'} gap={'small'}>
            <Tag className={'text-xs p-1'} color={merchant.is_operating ? 'success' : 'error'}>
              {merchant.is_operating ? 'BUKA' : 'TUTUP'}
            </Tag>
          </Flex>
          {/*Disable delivery fees*/}
          {/*<Text className={'text-sm'}>Rp. 2500</Text>*/}
        </Flex>
      </Flex>

      {(merchant.menus?.length ?? 0) > 0 && (
        <>
          <Divider />

          <Flex gap={'middle'} vertical>
            {merchant.menus?.map((menu) => (
              <Flex gap={'middle'} key={menu.id}>
                <img
                  src={menu.image_path.length ? menu.image_path : merchant.logo_path}
                  className={'object-cover rounded-xl'}
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
                <Flex flex={1} gap={'small'} vertical justify={'center'}>
                  <Text strong className={'overflow-hidden text-ellipsis text-sm'} style={{ margin: 0 }}>
                    {menu.name}
                  </Text>
                  <Text strong className={'overflow-hidden text-ellipsis text-sm text-primary'} style={{ margin: 0 }}>
                    {displayPrice(menu.sell_price)}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Card>
  );
}
