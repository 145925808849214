import { Card, Flex, Result, Skeleton } from 'antd';
import Header from '@components/Header.tsx';
import DeliveryAddress from '@components/DeliveryAddress';
import { useGetOrder } from '@/services/order.service.ts';
import { useParams } from 'react-router-dom';
import OrderStatusTag from '@pages/OrdersPage/components/OrderStatusTag.tsx';
import PendingPayment from '@pages/OrdersPage/components/PendingPayment.tsx';
import OrderSummary from '@components/OrderSummary';
import PaymentMethods from '@components/PaymentMethods';
import OrderPricingBreakdown from '@components/OrderPricingBreakdown';
import React, { useEffect, useMemo, useState } from 'react';
import CancelOrder from '@pages/OrdersPage/components/CancelOrder.tsx';
import CancelationData from '@pages/OrdersPage/components/CancelationData.tsx';
import FoodOrderRating from '@pages/OrdersPage/components/FoodOrderRating.tsx';
import OrderStatus from '@core/enums/OrderStatus.ts';

export default function FoodOrderDetailPage() {
  const { orderId } = useParams();
  const { data, isFetching, isPending, refetch } = useGetOrder(orderId ?? '');
  const [title, setTitle] = useState('Memuat data...');

  useEffect(() => {
    if (!data) return;
    setTitle(data.order_number);
  }, [data]);

  const total = useMemo(() => {
    return (data?.total ?? 0) - (data?.delivery_fee_discount ?? 0) - (data?.order_discount ?? 0);
  }, [data]);

  return (
    <Flex vertical style={{ backgroundColor: '#F7F7F7' }}>
      <Header
        showBackButton
        title={title}
        accessoryRight={!data ? null : <OrderStatusTag orderType={'food'} status={data.status} />}
      />

      <Flex className={'px-6 pb-6'} gap={'large'} vertical>
        {isFetching && (
          <Card>
            <Skeleton active />
          </Card>
        )}

        {data && (
          <React.Fragment>
            {data.status === OrderStatus.COMPLETED && !data.is_rated && (
              <FoodOrderRating
                driverName={data.driver.name}
                driverPhoto={data.driver.photo_path}
                merchantName={data.merchant.name}
                merchantLogo={data.merchant.logo_path}
                orderId={data.id}
              />
            )}

            <CancelationData order={data} />

            <PendingPayment
              orderType={'food'}
              orderId={data.id}
              orderDate={data.created_at}
              lastUpdateDate={data.updated_at}
              totalAmount={total}
              paymentMethod={data.payment_method}
              paymentStatus={data.payment_status}
            />

            <DeliveryAddress label={data.address_label} address={data.address_detail} />

            <OrderSummary merchant={data.merchant} orderItems={data.items} />

            <PaymentMethods defaultValue={data.payment_method} />

            <OrderPricingBreakdown
              isLoading={false}
              deliveryFee={data.delivery_fee}
              serviceFee={data.service_fee}
              subtotal={data.subtotal}
              total={total}
              orderDiscount={data.order_discount}
              deliveryDiscount={data.delivery_fee_discount}
              couponCode={data.coupon}
            />

            <CancelOrder orderType={'food'} orderId={data.id} status={data.status} onCanceled={() => refetch()} />
          </React.Fragment>
        )}

        {!isPending && !data && <Result status="404" title="404" subTitle="Order data not found." />}
      </Flex>
    </Flex>
  );
}
