import { Card, Flex, Result, Skeleton } from 'antd';
import Header from '@components/Header.tsx';
import DeliveryAddress from '@components/DeliveryAddress';
import { useGetOrder } from '@/services/store_order.service.ts';
import { useParams } from 'react-router-dom';
import OrderStatusTag from '@pages/OrdersPage/components/OrderStatusTag.tsx';
import PendingPayment from '@pages/OrdersPage/components/PendingPayment.tsx';
import PaymentMethods from '@components/PaymentMethods';
import OrderPricingBreakdown from '@components/OrderPricingBreakdown';
import React, { useEffect, useState } from 'react';
import CancelOrder from '@pages/OrdersPage/components/CancelOrder.tsx';
import CancelationData from '@pages/OrdersPage/components/CancelationData.tsx';
import StoreOrderSummary from '@components/StoreOrderSummary';

export default function StoreOrderDetailPage() {
  const { orderId } = useParams();
  const { data, isFetching, isPending, refetch } = useGetOrder(orderId ?? '');
  const [title, setTitle] = useState('Memuat data...');

  useEffect(() => {
    if (!data) return;
    setTitle(data.order_number);
  }, [data]);

  return (
    <Flex vertical style={{ backgroundColor: '#F7F7F7' }}>
      <Header
        showBackButton
        title={title}
        accessoryRight={!data ? null : <OrderStatusTag orderType={'store'} status={data.status} />}
      />

      <Flex className={'px-6 pb-6'} gap={'large'} vertical>
        {isFetching && (
          <Card>
            <Skeleton active />
          </Card>
        )}

        {data && (
          <React.Fragment>
            <CancelationData order={data} />

            <PendingPayment
              orderType={'store'}
              orderId={data.id}
              orderDate={data.created_at}
              lastUpdateDate={data.updated_at}
              totalAmount={data.total_after_discount}
              paymentMethod={data.payment_method}
              paymentStatus={data.payment_status}
            />

            <DeliveryAddress label={data.address_label} address={data.address_detail} />

            <StoreOrderSummary store={data.store} orderItems={data.items} />

            <PaymentMethods defaultValue={data.payment_method} />

            <OrderPricingBreakdown
              isLoading={false}
              deliveryFee={data.delivery_fee}
              serviceFee={data.service_fee}
              subtotal={data.subtotal}
              total={data.total_after_discount}
              deliveryDiscount={data.delivery_fee_discount}
              orderDiscount={data.order_discount}
              couponCode={data.coupon}
            />

            <CancelOrder orderType={'food'} orderId={data.id} status={data.status} onCanceled={() => refetch()} />
          </React.Fragment>
        )}

        {!isPending && !data && <Result status="404" title="404" subTitle="Order data not found." />}
      </Flex>
    </Flex>
  );
}
