import { useEffect, useState } from 'react';

const maxWidth = 640;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([Math.min(maxWidth, window.innerWidth), window.innerHeight]);

  const windowSizeHandler = () => {
    setWindowSize([Math.min(maxWidth, window.innerWidth), window.innerHeight]);
  };

  useEffect(() => {
    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
