import { format, parseISO } from "date-fns";

// Manokwari coordinate
export const manokwariCoordinate = {
  lat: -0.8614555773627836,
  lng: 134.06203035421083,
};

export const currencyFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('id-ID', {
  style: 'decimal',
  currency: 'IDR',
  minimumFractionDigits: 0,
});

export const displayPrice = (price?: number, showCurrency = true): string => {
  if (price === undefined) {
    return '';
  }

  if (!showCurrency) {
    return numberFormatter.format(price);
  }

  return currencyFormatter.format(price);
};

export const humanizeDistance = (distance?: number): string => {
  if (distance === undefined) {
    return '';
  }

  // Convert km to meters
  if (distance < 1) {
    return `${(distance / 1000).toFixed(1)} m`;
  } else {
    return `${distance.toFixed(1)} km`;
  }
};

export const displayDate = (date: string): string => {
  return format(parseISO(date), 'd MMMM y - H:mm');
};
