import { Card, Flex, Typography } from 'antd';
import { BankIcon, BankNoteDollarIcon, WalletFilledIcon } from '@components/AppIcons';
import { PaymentMethodOption } from '@components/PaymentMethods';
import PaymentMethod from '@/core/enums/kirim_aja/PaymentMethod';
import { useState } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';

const paymentMethods: PaymentMethodOption[] = [
  {
    label: 'Dompet Saya',
    value: PaymentMethod.WALLET,
    icon: <WalletFilledIcon className={'text-primary text-lg'} />,
    enabled: false,
    isComingSoon: true,
  },
  {
    label: 'Transfer Bank',
    value: PaymentMethod.BANK_TRANSFER,
    icon: <BankIcon className={'text-primary text-lg'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'QRIS',
    value: PaymentMethod.QRIS,
    icon: <QrcodeOutlined className={'text-primary text-lg'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'Tunai (Pengirim)',
    value: PaymentMethod.CASH_BY_SENDER,
    icon: <BankNoteDollarIcon className={'text-primary text-lg'} />,
    enabled: true,
    isComingSoon: false,
  },
  {
    label: 'Tunai (Penerima)',
    value: PaymentMethod.CASH_BY_RECIPIENT,
    icon: <BankNoteDollarIcon className={'text-primary text-lg'} />,
    enabled: true,
    isComingSoon: false,
  },
];

const { Text } = Typography;

interface OrderPaymentMethodProps {
  paymentMethod: number;
}

export default function OrderPaymentMethod(props: OrderPaymentMethodProps) {
  const [value] = useState(() => {
    return paymentMethods.find((method) => method.value === props.paymentMethod);
  });

  return (
    <Card>
      <Flex justify={'space-between'}>
        <Flex align={'center'} justify={'center'} gap={'small'}>
          {value?.icon}
          <Text className={'text-sm'}>Pembayaran</Text>
        </Flex>
        <Text className={'text-sm text-primary'}>{value?.label}</Text>
      </Flex>
    </Card>
  );
}
