import { authorizedClient } from '@/services/api.service.ts';
import { API_PATH } from '@core/constants';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import IAddress from '@core/types/IAddress.ts';
import IResponse from '@core/types/network/IResponse.ts';

export interface AddUpdateAddressDto {
  label: string;
  address: string;
  latitude: number;
  longitude: number;
}

const getMyAddresses = async () => {
  return authorizedClient.get(API_PATH.GET_MY_ADDRESSES).then((response: AxiosResponse<IAddress[]>) => response.data);
};

export const useGetMyAddresses = () => {
  return useQuery({
    queryKey: ['getMyAddresses'],
    queryFn: () => getMyAddresses(),
    refetchOnWindowFocus: false,
    retry: 1,
  });
};

const createNewAddress = async (data: AddUpdateAddressDto) => {
  return authorizedClient
    .post(API_PATH.GET_MY_ADDRESSES, data)
    .then((response: AxiosResponse<IResponse[]>) => response.data);
};

export const useCreateNewAddress = () => {
  return useMutation({
    mutationKey: ['createNewAddress'],
    mutationFn: (data: AddUpdateAddressDto) => createNewAddress(data),
  });
};

const deleteAddress = async (addressId: string) => {
  return authorizedClient
    .delete(API_PATH.updateAddress(addressId))
    .then((response: AxiosResponse<IResponse[]>) => response.data);
};

export const useDeleteAddress = () => {
  return useMutation({
    mutationKey: ['deleteAddress'],
    mutationFn: (addressId: string) => deleteAddress(addressId),
  });
};

const updateAddress = async (id: string, data: AddUpdateAddressDto) => {
  return authorizedClient
    .put(API_PATH.updateAddress(id), data)
    .then((response: AxiosResponse<IResponse[]>) => response.data);
};

export const useUpdateAddress = () => {
  return useMutation({
    mutationKey: ['updateAddress'],
    mutationFn: (params: { id: string; data: AddUpdateAddressDto }) => updateAddress(params.id, params.data),
  });
};

const setDefaultAddress = async (id: string) => {
  return authorizedClient
    .post(API_PATH.setDefaultAddress(id))
    .then((response: AxiosResponse<IResponse[]>) => response.data);
};

export const useSetDefaultAddress = () => {
  return useMutation({
    mutationKey: ['setDefaultAddress'],
    mutationFn: (id: string) => setDefaultAddress(id),
  });
};
