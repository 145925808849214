import { create } from 'zustand';
import { IMenu } from '@/core/types/IMenu.ts';
import { IAddon } from '@/core/types/IAddon.ts';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { IMerchant } from '@/core/types/IMerchant.ts';
import PaymentMethod from '@core/enums/PaymentMethod.ts';
import ICoupon from '@core/types/ICoupon.ts';

export interface CartItem {
  uuid: string;
  menu: IMenu;
  selectedAddons: Record<string, IAddon[]>;
  quantity: number;
  remark?: string;
  totalPrice: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface CartState {
  cart: CartItem[];
  merchant: IMerchant;
  menu: IMenu;
  coupon?: ICoupon;
  paymentMethod: number;
  activeItem?: CartItem;
  addToCart: (item: CartItem) => void;
  updateCartItem: (item: CartItem) => void;
  removeCartItem: (uuid: string) => void;
  clearCart: () => void;
  setActiveItem: (item: CartItem) => void;
  setMerchant: (merchant: IMerchant) => void;
  setMenu: (menu: IMenu) => void;
  setCoupon: (coupon: ICoupon) => void;
  clearCoupon: () => void;
  setPaymentMethod: (paymentMethod: number) => void;
  getSubTotalPrice: () => number;
  getItemsCount: () => number;
}

export const useCartStore = create<CartState>()(
  devtools(
    persist(
      (set, get) => ({
        cart: [],
        merchant: {} as IMerchant,
        menu: {} as IMenu,
        paymentMethod: PaymentMethod.CASH_ON_DELIVERY,
        addToCart: (item) =>
          set((state) => {
            return {
              activeItem: undefined,
              cart: [...state.cart, item],
            };
          }),
        updateCartItem: (item) =>
          set((state) => {
            const cart = [...state.cart];
            const indexToUpdate = state.cart.findIndex((i) => i.uuid === item.uuid);
            cart[indexToUpdate] = item;

            return {
              cart,
            };
          }),
        removeCartItem: (uuid) =>
          set((state) => {
            const cart = state.cart.filter((item) => item.uuid !== uuid);

            return {
              cart,
            };
          }),
        setMerchant: (merchant) => set(() => ({ merchant })),
        setMenu: (menu) => set(() => ({ menu })),
        setCoupon: (coupon) => set(() => ({ coupon })),
        clearCoupon: () => set(() => ({ coupon: undefined })),
        clearCart: () => set(() => ({ cart: [], coupon: undefined })),
        setPaymentMethod: (paymentMethod) => set(() => ({ paymentMethod })),
        setActiveItem: (activeItem) => set(() => ({ activeItem })),
        getSubTotalPrice: () => {
          const { cart } = get();

          if (!cart.length) return 0;

          return cart.reduce((prev, curr) => {
            return prev + curr.totalPrice;
          }, 0);
        },
        getItemsCount: () => {
          const { cart } = get();

          if (!cart.length) return 0;

          return cart.reduce((prev, curr) => {
            return prev + curr.quantity;
          }, 0);
        },
      }),
      {
        name: 'cart-storage',
        version: 1,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
