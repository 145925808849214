import Header from '@components/Header.tsx';
import { Flex, Typography } from 'antd';

const { Text, Title } = Typography;

export default function PrivacyPolicyPage() {
  return (
    <>
      <Header title={'Kebijakan Privasi'} showBackButton />
      <Flex flex={1} className={'px-8 pb-8'} vertical>
        <div>
          <Title level={2} id="kebijakan-privasi-pt-waaja-tech-solution">
            Kebijakan Privasi PT WAAJA TECH SOLUTION
          </Title>
          <Text>Tanggal Efektif: 23 Juli 2024</Text>
          <Title level={3} id="1-pengantar">
            1. Pengantar
          </Title>
          <Text>
            Selamat datang di WA-Aja! Kami menghargai privasi Anda dan berkomitmen untuk melindungi informasi pribadi
            Anda. Kebijakan ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi data pribadi yang
            Anda berikan saat mengakses website kami.
          </Text>
          <Title level={3} id="2-informasi-yang-kami-kumpulkan">
            2. Informasi yang Kami Kumpulkan
          </Title>
          <Text>Kami dapat mengumpulkan informasi pribadi berikut ketika Anda menggunakan layanan kami:</Text>
          <ul>
            <li>Nama</li>
            <li>Alamat email</li>
            <li>Nomor telepon</li>
            <li>Informasi pembayaran (jika diperlukan)</li>
            <li>Data penggunaan website</li>
          </ul>
          <br />
          <Title level={3} id="3-penggunaan-informasi">
            3. Penggunaan Informasi
          </Title>
          <Text>Kami menggunakan informasi yang kami kumpulkan untuk:</Text>
          <ul>
            <li>Menyediakan dan mengelola layanan kami</li>
            <li>Mengirimkan informasi dan pembaruan terkait layanan</li>
            <li>Menanggapi pertanyaan dan permintaan Anda</li>
            <li>Meningkatkan pengalaman pengguna di website kami</li>
            <li>Mematuhi kewajiban hukum</li>
          </ul>
          <br />
          <Title level={3} id="4-perlindungan-data">
            4. Perlindungan Data
          </Title>
          <Text>
            Kami mengambil langkah-langkah yang wajar untuk melindungi informasi pribadi Anda dari kehilangan,
            penyalahgunaan, dan akses yang tidak sah. Namun, tidak ada metode transmisi melalui internet atau metode
            penyimpanan elektronik yang 100% aman. Oleh karena itu, kami tidak dapat menjamin keamanan mutlak.
          </Text>
          <Title level={3} id="5-pengungkapan-kepada-pihak-ketiga">
            5. Pengungkapan kepada Pihak Ketiga
          </Title>
          <Text>
            Kami tidak akan menjual, memperdagangkan, atau mengalihkan informasi pribadi Anda kepada pihak ketiga tanpa
            persetujuan Anda, kecuali jika diwajibkan oleh hukum atau untuk melindungi hak-hak kami.
          </Text>
          <Title level={3} id="6-hak-pengguna">
            6. Hak Pengguna
          </Title>
          <Text>Anda memiliki hak untuk:</Text>
          <ul>
            <li>Mengakses informasi pribadi yang kami miliki tentang Anda</li>
            <li>Memperbaiki informasi pribadi yang tidak akurat</li>
            <li>Menghapus informasi pribadi Anda dalam kondisi tertentu</li>
            <li>Menarik persetujuan atas penggunaan data pribadi Anda</li>
          </ul>
          <br />
          <Title level={3} id="7-perubahan-kebijakan-privasi">
            7. Perubahan Kebijakan Privasi
          </Title>
          <Text>
            Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Perubahan akan diumumkan melalui website
            kami. Harap tinjau kebijakan ini secara berkala untuk mengetahui perubahan terbaru.
          </Text>
          <Title level={3} id="8-kontak">
            8. Kontak
          </Title>
          <Text>
            Jika Anda memiliki pertanyaan atau kekhawatiran tentang Kebijakan Privasi ini, silakan hubungi kami di:
          </Text>
          <br />
          <Text>
            <strong>Email:</strong>{' '}
            <a href="mailto:office@wa-aja.com" target="_blank">
              office@wa-aja.com
            </a>
          </Text>
          <br />
          <Text>
            <strong>Website:</strong>{' '}
            <a href="https://wa-aja.com" target="_blank">
              https://wa-aja.com
            </a>
          </Text>
        </div>
      </Flex>
    </>
  );
}
