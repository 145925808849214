import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import './SearchInput.less';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { track } from '@utils/analytic.util.ts';

export default function SearchInput() {
  const navigate = useNavigate();

  return (
    <Input
      className={'search-input'}
      variant={'filled'}
      prefix={<SearchOutlined />}
      placeholder={'Mau makan apa hari ini?'}
      onFocus={() => {
        track('NAVIGATE_SEARCH_FOOD');
        navigate(PageRoutes.FOOD_SEARCH);
      }}
    />
  );
}
