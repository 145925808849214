import { Flex, Typography } from 'antd';
import { displayPrice } from '@/utils';
import React from 'react';

interface StoreOrderItemProps {
  productImage: string;
  productName: string;
  totalPrice: number;
  quantity: number;
  addons?: string;
  remark?: string;
}

const { Text } = Typography;

export default function StoreOrderItem(props: StoreOrderItemProps) {
  const { productImage, productName, totalPrice, quantity, addons, remark } = props;

  return (
    <React.Fragment>
      <Flex gap={'middle'}>
        <img src={productImage} className={'object-cover rounded-xl'} width={56} />
        {/*<Image className={'rounded-2xl object-cover'} src={menuImage} preview={false} width={64} height={64} />*/}
        <Flex vertical flex={1} className={'overflow-hidden whitespace-nowrap'} justify={'center'} gap={'small'}>
          <Text className={'overflow-hidden text-ellipsis m-0'}>{productName}</Text>
          <Text className={'font-bold text-primary m-0'}>{displayPrice(totalPrice)}</Text>
        </Flex>
        <Flex vertical gap={'small'} justify={'center'} align={'center'}>
          <Text strong>{quantity}x</Text>
        </Flex>
      </Flex>
      <Flex className={'mt-4'} vertical>
        {addons && <Text className={'text-xs text-info'}>*Variasi: {addons}</Text>}
        {remark && <Text className={'text-xs text-error'}>*Catatan: {remark}</Text>}
      </Flex>
    </React.Fragment>
  );
}
