import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import IAddress from '@core/types/IAddress.ts';

export interface UserState {
  defaultAddress: IAddress;
  addresses: IAddress[];
  setDefaultAddress: (address: IAddress) => void;
  setAddresses: (addresses: IAddress[]) => void;
}

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      defaultAddress: {} as IAddress,
      addresses: [],
      setDefaultAddress: (defaultAddress) => set(() => ({ defaultAddress })),
      setAddresses: (addresses) => set(() => ({ addresses })),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
