import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { User } from '../core/models/User';
import * as Sentry from '@sentry/react';

export interface AuthState {
  accessToken?: string;
  user?: User;
  loginSuccess: (user: User, authToken: string) => void;
  updateUser: (user: User) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      updateUser: (user) => set(() => ({ user })),
      logout: () =>
        set(() => {
          Sentry.setUser(null);
          return { user: undefined, accessToken: undefined };
        }),
      loginSuccess: (user, accessToken) =>
        set(() => {
          Sentry.setUser({ id: user.id, username: user.phone_number });
          return { user, accessToken };
        }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
