import { getOrderStatusText } from '@utils/mapper/order.mapper.ts';
import { Tag } from 'antd';
import OrderType from '@core/types/OrderType.ts';
import { getShipmentOrderStatusText } from '@utils/mapper/shipment_order.mapper.ts';
import { getStoreOrderStatusText } from '@utils/mapper/store_order.mapper.ts';

interface OrderStatusTagProps {
  status: number;
  orderType: OrderType;
}

export default function OrderStatusTag(props: OrderStatusTagProps) {
  const { status, orderType } = props;

  const getColorForFoodOrder = () => {
    if (status < 2) {
      return 'error';
    }

    if (status < 8) {
      return 'warning';
    }

    return 'success';
  };

  const getColorForShipmentOrder = () => {
    if (status < 2) {
      return 'error';
    }

    if (status < 6) {
      return 'warning';
    }

    return 'success';
  };

  const determineColor = () => {
    if (orderType === 'food') {
      return getColorForFoodOrder();
    }

    return getColorForShipmentOrder();
  };

  const determineStatusText = () => {
    if (orderType === 'food') {
      return getOrderStatusText(status);
    }
    if (orderType === 'store') {
      return getStoreOrderStatusText(status);
    }
    return getShipmentOrderStatusText(status);
  };

  return (
    <Tag bordered color={determineColor()} className={'w-fit text-sm m-0 rounded-xl py-1 px-2'}>
      {determineStatusText()}
    </Tag>
  );
}
