// const { Text, Title } = Typography;

export default function BelanjaAjaPage() {
  return null;
  // const navigate = useNavigate();
  // const maxDestinations = 5;
  //
  // const [senderLocation, setSenderLocation] = useState<Location>();
  // const [locationPickerVisible, setLocationPickerVisible] = useState(false);
  //
  // const [destinationPickerVisible, setDestinationPickerVisible] = useState(false);
  // const [selectedDestinationId, setSelectedDestinationId] = useState<string>();
  // const [destinations, setDestinations] = useState<Destination[]>([]);
  //
  // useEffect(() => {
  //   if (!destinations.length) {
  //     addMoreDestination();
  //   }
  // }, []);
  //
  // const addMoreDestination = () => {
  //   if (destinations.length >= maxDestinations) return;
  //
  //   setDestinations(() => [
  //     ...destinations,
  //     {
  //       id: uuidv4(),
  //     },
  //   ]);
  // };
  //
  // const onLocationPickerClosed = () => {
  //   setLocationPickerVisible(false);
  // };
  //
  // const onLocationSelected = (location: Location) => {
  //   setSenderLocation(location);
  // };
  //
  // const onSelectingDestination = (id: string) => {
  //   setSelectedDestinationId(id);
  //   setDestinationPickerVisible(true);
  // };
  //
  // const onDestinationDeleted = (id: string) => {
  //   const newDestinations = [...destinations];
  //   const destinationIndex = newDestinations.findIndex((i) => i.id === id);
  //
  //   newDestinations.splice(destinationIndex, 1);
  //   setDestinations(newDestinations);
  // };
  //
  // return (
  //   <Flex vertical flex={1} className={'min-h-screen'}>
  //     <Header title={'Belanja-Aja'} showBackButton onBackButtonClicked={() => navigate(PageRoutes.HOME)} />
  //
  //     <Flex flex={1} vertical gap={'large'} className={'p-6'} style={{ backgroundColor: '#F5F5F5' }}>
  //       <Card className={'w-full h-fit app-timeline'}>
  //         <Timeline
  //           className={'w-full mt-2'}
  //           items={[
  //             ...destinations.map((i, index) => ({
  //               children: (
  //                 <DestinationPicker
  //                   id={i.id}
  //                   address={i.address}
  //                   onClick={() => onSelectingDestination(i.id)}
  //                   onDelete={() => onDestinationDeleted(i.id)}
  //                   allowDelete={index !== 0}
  //                   defaultAddress={'Belanja dimana?'}
  //                 />
  //               ),
  //               color: '#1677ff',
  //             })),
  //             {
  //               children: (
  //                 <TouchableOpacity
  //                   onClick={() => {
  //                     setLocationPickerVisible(true);
  //                   }}
  //                 >
  //                   <Text className={clsx({ 'ml-2': true, 'opacity-50': !senderLocation })}>
  //                     {senderLocation ? senderLocation.address : 'Antar kemana?'}
  //                   </Text>
  //                 </TouchableOpacity>
  //               ),
  //             },
  //           ]}
  //         />
  //         {destinations.length < maxDestinations && (
  //           <Flex justify={'center'} className={'mt-2'}>
  //             <TouchableOpacity onClick={addMoreDestination}>
  //               <Text className={'text-sm text-blue-700 font-bold'}>Tambah lokasi belanja</Text>
  //             </TouchableOpacity>
  //           </Flex>
  //         )}
  //       </Card>
  //     </Flex>
  //
  //     <LocationPicker
  //       visible={locationPickerVisible}
  //       title={'Lokasi Belanja'}
  //       onClose={onLocationPickerClosed}
  //       onSelected={onLocationSelected}
  //     />
  //
  //     <LocationPicker
  //       visible={locationPickerVisible}
  //       title={'Antar kemana?'}
  //       onClose={onLocationPickerClosed}
  //       onSelected={onLocationSelected}
  //     />
  //   </Flex>
  // );
}
