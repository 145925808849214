import React, { ReactNode, useEffect } from 'react';
import type { MenuProps } from 'antd';
import { ConfigProvider, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './AppFooter.less';
import { ORDERS_ROUTE, PageRoutes } from '@/routes/AppRoute.tsx';
import { useCoreStore } from '@/stores/core.store.ts';
import { HomeIcon, SettingsOutlinedIcon, TaskListSquaredOutlinedIcon } from '@components/AppIcons';
import { useAuthStore } from '@/stores/auth.store.ts';

const items = [
  {
    key: '/',
    icon: <HomeIcon />,
    label: 'Beranda',
  },
  {
    key: '/orders',
    icon: <TaskListSquaredOutlinedIcon />,
    label: 'Pesanan',
  },
  {
    key: '/settings',
    icon: <SettingsOutlinedIcon />,
    label: 'Pengaturan',
  },
];

const AppFooter: React.FC = () => {
  const { currentPage, setCurrentPage } = useCoreStore((state) => state);
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  const onClick: MenuProps['onClick'] = (e) => {
    const options = {
      state: { from: location },
    };

    if (!user && e.key !== '/') {
      navigate(PageRoutes.LOGIN, options);
      return;
    }

    switch (e.key) {
      case '/orders':
        navigate(ORDERS_ROUTE, options);
        break;
      case '/settings':
        navigate(PageRoutes.SETTINGS, options);
        break;
      default:
        navigate(PageRoutes.HOME, options);
        break;
    }

    setCurrentPage(e.key);
  };

  return (
    <FooterProvider>
      <Menu
        rootClassName={'app-footer'}
        items={items}
        onClick={onClick}
        selectedKeys={[currentPage]}
        mode="horizontal"
        className={'flex w-full'}
        style={{ border: 'none' }}
        disabledOverflow={true}
      />
    </FooterProvider>
  );
};

function FooterProvider({ children }: { children: ReactNode }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            iconSize: 20,
            fontSize: 14,
            activeBarBorderWidth: 0,
            activeBarHeight: 0,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AppFooter;
