import { Avatar, Card, Flex, Space, Tag, Typography } from 'antd';
import { LocationFilledIcon } from '@components/AppIcons';
import IAddress from '@core/types/IAddress.ts';

interface AddressProps {
  address: IAddress;
  accessoryLeft?: React.ReactNode;
  accessoryRight?: React.ReactNode;
  showDefault?: boolean;
}

const { Text } = Typography;

export default function Address(props: AddressProps) {
  const { address, accessoryRight, accessoryLeft, showDefault = true } = props;

  return (
    <Card className={'w-full'}>
      <Flex align={'center'}>
        {accessoryLeft && accessoryLeft}
        <Avatar
          size={'large'}
          icon={<LocationFilledIcon className={'text-lg'} />}
          className={'text-white bg-primary'}
        />
        <Flex flex={1} vertical gap={'small'} className={'ml-3'}>
          <Space>
            <Text className={'font-bold'}>{address.label}</Text>
            {showDefault && !!address.default && (
              <Tag color={'success'} className={'text-xs'}>
                Default
              </Tag>
            )}
          </Space>
          <Text className={'text-sm'}>{address.address}</Text>
        </Flex>
        {accessoryRight && accessoryRight}
      </Flex>
    </Card>
  );
}
