import { client } from './api.service.ts';
import { User } from '../core/models/User';
import { API_PATH } from '@/core/constants';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export interface LoginDto {
  phoneNumber: string;
  token: string;
}

const login = async (data: LoginDto) => {
  return client
    .post(API_PATH.LOGIN, {
      phoneNumber: data.phoneNumber,
      'g-recaptcha-response': data.token,
    })
    .then(() => true);
};

export const useLogin = () => {
  return useMutation({
    mutationKey: ['login'],
    mutationFn: (data: LoginDto) => login(data),
  });
};

export interface VerifyOtpDto extends LoginDto {
  otp: string;
}

export interface VerifyOtpResponse {
  access_token: string;
  user: User;
}

const verifyOtp = async (data: VerifyOtpDto) => {
  return client
    .post(API_PATH.VERIFY_OTP, {
      phoneNumber: data.phoneNumber,
      'g-recaptcha-response': data.token,
      otp: data.otp,
    })
    .then((response: AxiosResponse<VerifyOtpResponse>) => response.data);
};

export const useVerifyOtp = () => {
  return useMutation({
    mutationKey: ['verifyOtp'],
    mutationFn: (data: VerifyOtpDto) => verifyOtp(data),
  });
};
