import { Col, Empty, Flex, Row } from 'antd';
import { IProduct } from '@/core/types/IProduct.ts';
import { useEffect, useState } from 'react';
import ProductPicker from '@pages/StorePage/components/ProductPicker.tsx';
import { ProductCartItem, useProductCartStore } from '@/stores/product_cart.store.ts';
import { v4 as uuidv4 } from 'uuid';
import { track } from '@utils/analytic.util.ts';
import { useGetStoreProducts } from '@services/product.service.ts';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListLoader from '@components/ListLoader.tsx';
import { useParams } from 'react-router-dom';
import Product from '@pages/StorePage/components/Product.tsx';
import ProductSale from '@pages/StorePage/components/ProductSale.tsx';

interface ProductListProps {
  searchTerm: string;
  category?: string;
}

export default function ProductList({ searchTerm, category }: ProductListProps) {
  const setProduct = useProductCartStore((state) => state.setProduct);
  const { storeId = '' } = useParams();
  const store = useProductCartStore((state) => state.store);
  const setActiveItem = useProductCartStore((state) => state.setActiveItem);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [page, setPage] = useState(1);
  const { mutate, data: response, isIdle, isPending } = useGetStoreProducts();

  useEffect(() => {
    if (!response) return;
    setProducts((prev) => [...prev, ...response.data]);

    // Increment page if there is a next page
    if (response.next_page_url) {
      setPage((prev) => prev + 1);
    }
  }, [response]);

  // Get first page
  useEffect(() => {
    if (isIdle) {
      mutate({
        storeSlug: storeId,
        term: searchTerm,
        page: 1,
        category,
      });
    }
  }, []);

  // Refetch when search term changes
  useEffect(() => {
    setPage(1);
    setProducts([]);

    mutate({
      storeSlug: storeId,
      term: searchTerm,
      page: 1,
      category,
    });
  }, [searchTerm, category]);

  const onProductClicked = (product: IProduct) => {
    const newCartItem: ProductCartItem = {
      uuid: uuidv4(),
      product: product,
      quantity: 1,
      remark: '',
      totalPrice: product.sell_price,
    };

    setProduct(product);
    setActiveItem(newCartItem);

    track('CHOOSING_STORE_PRODUCT', { productId: product.id, storeId: store.id, productName: product.name });
    setModalVisible(true);
  };

  if (!products) return null;

  return (
    <>
      <ProductSale slug={storeId} onClick={onProductClicked} />

      <Flex flex={1} vertical style={{ backgroundColor: '#F5F5F5' }}>
        <InfiniteScroll
          dataLength={products.length}
          next={() =>
            mutate({
              storeSlug: storeId,
              term: searchTerm,
              page,
              category,
            })
          }
          hasMore={!!response?.next_page_url}
          scrollableTarget="scrollableDiv"
          loader={null}
          className={'w-full'}
        >
          {isPending && (
            <Row className={'p-4'} gutter={[16, 16]}>
              <Col span={12}>
                <ListLoader />
              </Col>
              <Col span={12}>
                <ListLoader />
              </Col>
              <Col span={12}>
                <ListLoader />
              </Col>
              <Col span={12}>
                <ListLoader />
              </Col>
            </Row>
          )}
          {!isIdle && !isPending && !products.length ? (
            <Empty className={'mt-6'} />
          ) : (
            <Row className={'p-4'} gutter={[16, 16]}>
              {products.map((product) => (
                <Col span={12} key={product.id}>
                  <Product product={product} store={store} onClick={onProductClicked} />
                </Col>
              ))}
            </Row>
          )}
        </InfiniteScroll>
      </Flex>

      <ProductPicker mode={'add'} visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
}
