import { Card, Skeleton } from 'antd';

interface ListLoaderProps {
  rows?: number;
}

export default function ListLoader(props: ListLoaderProps) {
  const { rows = 2 } = props;

  return (
    <Card>
      <Skeleton
        active
        paragraph={{
          rows,
        }}
      />
    </Card>
  );
}
