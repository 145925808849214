export enum ProductUnit {
  GRAM = 'GRAM',
  KG = 'KG',
  PACK = 'PACK',
  PCS = 'PCS',
}

export interface IProduct {
  id: string;
  name: string;
  description?: string;
  details?: string;
  unit: ProductUnit;
  sku?: string;
  barcode?: string;
  condition?: string;
  category_id: string;
  minimum_purchase_quantity: number;
  prescription_required: boolean;
  price: number;
  sell_price: number;
  discount_amount: number;
  image_path: string;
  status: number;
  status_text: string;
  store_id: string;
  created_at: string;
  updated_at: string;
  sorting: number;
}
