import { Card, Empty, Flex, Skeleton } from 'antd';
import SearchInput from '@pages/FoodSearchPage/components/SearchInput.tsx';
import MerchantListItem from '@pages/MerchantPage/components/MerchantListItem.tsx';
import { IMerchant } from '@/core/types/IMerchant.ts';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';
import { useGetMenusByTerm } from '@/services/menu.service.ts';
import { ArrowLeftIcon } from '@components/AppIcons';
import TouchableOpacity from '@components/TouchableOpacity';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { track } from '@utils/analytic.util.ts';

export default function FoodSearchPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [term, setTerm] = useState<string>(searchParams.get('term') ?? '');
  const [data, setData] = useState<IMerchant[]>([]);
  const [page, setPage] = useState(1);

  const { mutate, data: response, isPending, isIdle } = useGetMenusByTerm();

  const onSearchTermChanged = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  }, 500);

  useEffect(() => {
    if (term.length < 3) return;

    setSearchParams({ term });
    setData([]);
    setPage(1);

    track('SEARCHING_FOOD_TERM', { term });
    mutate({ term, page: 1 });
  }, [term]);

  useEffect(() => {
    if (!response) return;
    setData((prev) => [...prev, ...response.data]);

    // Increment page if there is a next page
    if (response.next_page_url) {
      setPage((prev) => prev + 1);
    }
  }, [response]);

  return (
    <Flex vertical>
      <Flex gap={'small'} className={'my-6 ml-3 mr-6'}>
        <TouchableOpacity onClick={() => navigate(PageRoutes.HOME)}>
          <ArrowLeftIcon style={{ fontSize: 24 }} />
        </TouchableOpacity>
        <SearchInput placeholder={'Mau makan apa hari ini?'} defaultValue={term} onChange={onSearchTermChanged} />
      </Flex>

      <Flex vertical className={'p-6 min-h-screen'} gap={'small'} style={{ background: '#F7F7F7' }}>
        <InfiniteScroll
          dataLength={data.length}
          next={() => mutate({ term, page })}
          hasMore={!!response?.next_page_url}
          scrollableTarget="scrollableDiv"
          loader={null}
        >
          <Flex vertical gap={'small'}>
            {!isIdle && !isPending && !data.length ? (
              <Empty />
            ) : (
              data.map((merchant: IMerchant) => <MerchantListItem key={merchant.id} merchant={merchant} />)
            )}
            {isPending && (
              <Card>
                <Flex gap={'middle'}>
                  <Skeleton.Image active style={{ width: 90, height: 90 }} />
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Flex>
              </Card>
            )}
          </Flex>
        </InfiniteScroll>
      </Flex>
    </Flex>
  );
}
