import { authorizedClient } from './api.service.ts';
import { AxiosResponse } from 'axios';
import { IStore } from '@/core/types/IStore.ts';
import { API_PATH } from '@/core/constants';
import { useMutation } from '@tanstack/react-query';
import IProductDiscount from '@core/types/IProductDiscount.ts';

export interface GetNearbyStoresResponse {
  stores: any;
}

export const getNearbyStores = async () => {
  return authorizedClient
    .get(API_PATH.GET_NEARBY_MERCHANTS, { params: { perPage: 5 } })
    .then((response: AxiosResponse<GetNearbyStoresResponse>) => response.data);
};

export const getStore = async (storeId: string) => {
  return authorizedClient.get(API_PATH.getStore(storeId)).then((response: AxiosResponse<IStore>) => response.data);
};

export interface GetStoresDistanceResponse {
  delivery_fee: number;
  service_fee: number;
  distance: number;
  duration: number;
}

export const getStoreDistance = async (storeSlug: string) => {
  return authorizedClient
    .get(API_PATH.getStoreDistance(storeSlug))
    .then((response: AxiosResponse<GetStoresDistanceResponse>) => response.data);
};

const getStoreSale = async (slug: string) => {
  return authorizedClient
    .get(API_PATH.getStoreSale(slug))
    .then((response: AxiosResponse<IProductDiscount[]>) => response.data);
};

export const useGetStoreSale = () => {
  return useMutation({
    mutationKey: ['getStoreSale'],
    mutationFn: (slug: string) => getStoreSale(slug),
  });
};
