import AppLayout from '@components/Layouts/AppLayout';
import { Button, Flex, Input, Modal, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '@/routes/AppRoute.tsx';
import { useCallback, useEffect, useState } from 'react';
import { useCountdown } from 'usehooks-ts';
import { useLogin, useVerifyOtp } from '@/services/auth.service.ts';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { track } from '@utils/analytic.util.ts';
import { useAuthStore } from '@/stores/auth.store.ts';

export default function PhoneOtpPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { loginSuccess } = useAuthStore((state) => state);

  const [resendAvailable, setResendAvailable] = useState(false);
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { mutate, data, error, isPending } = useVerifyOtp();
  const { mutate: mutateLogin } = useLogin();

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 60,
    intervalMs: 1000,
  });

  const onVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [refreshReCaptcha]
  );

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    if (count === 0) {
      setResendAvailable(true);
    }
  }, [count]);

  // On success
  useEffect(() => {
    if (!data) return;
    track('LOGIN_SUCCESS');
    loginSuccess(data.user, data.access_token);
  }, [data]);

  // Handle error response
  useEffect(() => {
    if (!error) return;
    track('LOGIN_FAILED');

    Modal.error({
      // @ts-expect-error: response is undefined
      title: error.response.data.message ?? 'Terjadi kesalahan',
    });
  }, [error]);

  const onOtpChanged = (value: string) => {
    if (!token) return;
    setRefreshReCaptcha((r) => !r);
    mutate({ phoneNumber: state.phoneNumber, otp: value, token });
  };

  const resendOtp = () => {
    if (token) {
      setRefreshReCaptcha((r) => !r);
      mutateLogin({ phoneNumber: state.phoneNumber, token });
    }

    setResendAvailable(false);
    resetCountdown();
    startCountdown();
  };

  if (!state?.phoneNumber) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  return (
    <AppLayout
      pageTitle={'Verifikasi Kode OTP'}
      headerComponent={{
        accessoryLeft: <Button type={'text'} icon={<ArrowLeftOutlined />} onClick={() => navigate(LOGIN_ROUTE)} />,
      }}
    >
      <Flex vertical flex={1} className={'mt-8'}>
        <Typography.Text className={'text-center mb-8'}>
          Kode OTP berhasil terkirim ke Whatsapp {state.phoneNumber}
        </Typography.Text>
        <Input.OTP variant={'filled'} style={{ fontWeight: 'bold' }} onChange={onOtpChanged} />
        {resendAvailable ? (
          <Button type={'link'} className={'text-center mt-2'} onClick={resendOtp}>
            Kirim ulang
          </Button>
        ) : (
          <Typography.Text className={'text-center mt-8'}>Kirim ulang dalam {count} detik</Typography.Text>
        )}
        <Button type={'primary'} className={'mt-16'} loading={isPending}>
          Konfirmasi
        </Button>
      </Flex>
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
    </AppLayout>
  );
}
