import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import '@fontsource-variable/plus-jakarta-sans';
import './index.less';

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: 'https://13dbb332c709c4e33b6a4d3971bf3203@o4507350692790272.ingest.us.sentry.io/4507446293823488',
  release: `${import.meta.env.VITE_APP_NAME}@${import.meta.env.VITE_APP_VERSION}`,
});

if (import.meta.env.MODE === 'production') {
  ReactGA.initialize('G-PGVFWBCSG8');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
