import { ReactNode, useState } from 'react';
import './TouchableOpacity.less';
import { clsx } from 'clsx';

interface TouchableOpacityProps {
  children?: ReactNode;
  onClick?: () => void;
}

export default function TouchableOpacity(props: TouchableOpacityProps) {
  const { children, ...rest } = props;
  const [touched, setTouched] = useState<boolean>(false);

  const toggleTouched = () => {
    setTouched(!touched);
  };

  const handleMouseUp = () => {
    // Handle smooth animation when clicking without holding
    setTimeout(() => {
      setTouched(false);
    }, 150);
  };

  return (
    <div
      className={clsx({ flex: true, 'touchable-opacity': true, touched: touched })}
      onMouseDown={toggleTouched}
      onMouseUp={handleMouseUp}
      {...rest}
    >
      {children}
    </div>
  );
}
