import { Card, Divider, Flex, Typography } from 'antd';
import TouchableOpacity from '@components/TouchableOpacity';
import { displayDate, displayPrice } from '@/utils';
import PaymentMethod from '@core/enums/PaymentMethod.ts';
import ShipmentOrderPaymentMethod from '@core/enums/kirim_aja/PaymentMethod.ts';
import BankTransfer from '@pages/OrdersPage/components/PaymentInstructions/BankTransfer.tsx';
import { useState } from 'react';
import PaymentConfirmation from '@pages/OrdersPage/components/PaymentConfirmation';
import PaymentStatus from '@core/enums/PaymentStatus.ts';
import ShipmentOrderPaymentStatus from '@core/enums/kirim_aja/PaymentStatus.ts';
import OrderType from '@core/types/OrderType.ts';
import QRIS from '@pages/OrdersPage/components/PaymentInstructions/QRIS.tsx';

const { Text } = Typography;

interface PendingPaymentProps {
  orderId: string;
  orderDate: string;
  orderType: OrderType;
  lastUpdateDate: string;
  totalAmount: number;
  paymentMethod: PaymentMethod | ShipmentOrderPaymentMethod;
  paymentStatus: PaymentStatus | ShipmentOrderPaymentStatus;
}

export default function PendingPayment(props: PendingPaymentProps) {
  const { orderType, orderId, paymentMethod, paymentStatus, orderDate, totalAmount } = props;
  const [instructionVisible, setInstructionVisible] = useState(false);
  const [paymentConfirmationVisible, setPaymentConfirmationVisible] = useState(false);
  const isPendingPayment =
    paymentStatus === PaymentStatus.WAITING_FOR_PAYMENT ||
    paymentStatus === ShipmentOrderPaymentStatus.WAITING_FOR_PAYMENT;

  const togglePaymentInstruction = () => {
    setInstructionVisible(!instructionVisible);
  };

  const togglePaymentConfirmation = () => {
    setPaymentConfirmationVisible(!paymentConfirmationVisible);
  };

  const renderPaymentInstruction = () => {
    switch (paymentMethod) {
      case PaymentMethod.BANK_TRANSFER:
      case ShipmentOrderPaymentMethod.BANK_TRANSFER:
        return (
          <BankTransfer totalAmount={totalAmount} visible={instructionVisible} onClose={togglePaymentInstruction} />
        );
      case PaymentMethod.QRIS:
      case ShipmentOrderPaymentMethod.QRIS:
        return <QRIS totalAmount={totalAmount} visible={instructionVisible} onClose={togglePaymentInstruction} />;
      default:
        return <></>;
    }
  };

  return (
    <Card>
      <Flex justify={'space-between'} className={'mb-2'}>
        <Text className={'text-sm'}>Waktu Pemesanan</Text>
        <Text className={'text-sm'}>{displayDate(orderDate)}</Text>
      </Flex>

      <Flex justify={'space-between'}>
        <Text className={'text-sm'}>Total Pembayaran</Text>
        <Text className={'text-sm'}>{displayPrice(totalAmount)}</Text>
      </Flex>

      {isPendingPayment && (
        <>
          <Divider className={'my-4'} />
          <Flex justify={'space-between'}>
            <TouchableOpacity onClick={togglePaymentInstruction}>
              <Text className={'text-sm text-primary font-bold'}>Lihat Cara Bayar</Text>
            </TouchableOpacity>
            <TouchableOpacity onClick={togglePaymentConfirmation}>
              <Text className={'text-sm text-primary font-bold'}>Konfirmasi Pembayaran</Text>
            </TouchableOpacity>
          </Flex>
        </>
      )}

      {renderPaymentInstruction()}

      <PaymentConfirmation
        orderType={orderType}
        orderId={orderId}
        visible={paymentConfirmationVisible}
        onClose={togglePaymentConfirmation}
      />
    </Card>
  );
}
