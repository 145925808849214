import { Button, Divider, Flex, Image, Skeleton, Tag, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStore, getStoreDistance } from '@/services/store.service.ts';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { displayPrice, humanizeDistance } from '@/utils';
import PageSkeleton from '@pages/StorePage/components/PageSkeleton.tsx';
import { ArrowLeftIcon, BikeIcon, LocationOutlinedIcon } from '@components/AppIcons';
import { NotFoundError } from '@components/Errors';
import ProductList from '@pages/StorePage/components/ProductList.tsx';
import Header from '@components/Header.tsx';
import TouchableOpacity from '@components/TouchableOpacity';
import { useProductCartStore } from '@/stores/product_cart.store.ts';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { useEffect, useState } from 'react';
import PaymentMethod from '@core/enums/PaymentMethod.ts';
import { track } from '@utils/analytic.util.ts';
import { CalendarOutlined } from '@ant-design/icons';
import ProductSearch from '@pages/StorePage/components/ProductSearch.tsx';
import ProductCategories from '@pages/StorePage/components/ProductCategories.tsx';

const { Title, Text } = Typography;
export default function StorePage() {
  const { storeId } = useParams();
  const [, windowHeight] = useWindowSize();
  const navigate = useNavigate();
  const currentStore = useProductCartStore((state) => state.store);
  const setStore = useProductCartStore((state) => state.setStore);
  const cart = useProductCartStore((state) => state.cart);
  const clearCart = useProductCartStore((state) => state.clearCart);
  const setPaymentMethod = useProductCartStore((state) => state.setPaymentMethod);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();

  const { data, isFetching, isPending } = useQuery({
    queryKey: ['getStore', storeId],
    queryFn: () => getStore(storeId!),
    enabled: !!storeId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: location, isFetching: isFetchingLocation } = useQuery({
    queryKey: ['getStoreDistance', storeId],
    queryFn: () => getStoreDistance(storeId!),
    enabled: !!storeId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  // Reset to default payment method
  useEffect(() => {
    setPaymentMethod(PaymentMethod.CASH_ON_DELIVERY);
  }, []);

  // Set store data only when its available
  useEffect(() => {
    if (!data) return;
    setStore(data);

    // If different mex are loaded
    if (currentStore && currentStore.id !== data.id) {
      clearCart();
    }
  }, [data]);

  const getTotalCartItems = (): number => {
    if (!cart.length || !data) return 0;

    return (
      cart.reduce((prev, curr) => {
        return prev + curr.quantity;
      }, 0) ?? 0
    );
  };

  const getTotalPrice = (): number => {
    if (!cart.length || !data) return 0;

    return (
      cart.reduce((prev, curr) => {
        return prev + curr.totalPrice;
      }, 0) ?? 0
    );
  };

  const checkout = () => {
    track('VIEW_CART');
    navigate(PageRoutes.STORE_CHECKOUT);
  };

  if (isFetching) {
    return <PageSkeleton />;
  }

  if (!data && !isPending) {
    return <NotFoundError />;
  }

  if (!data) {
    return null;
  }

  return (
    <Flex flex={1} className={'min-h-screen'} vertical>
      <Image src={data.banner_image} className={'object-cover'} preview={false} height={windowHeight * 0.25} />
      <Flex className={'absolute'}>
        <Header
          accessoryLeft={
            <TouchableOpacity onClick={() => navigate(-1)}>
              <div
                className={'flex items-center justify-center rounded-full bg-black bg-opacity-50'}
                style={{ height: 36, width: 36 }}
              >
                <ArrowLeftIcon style={{ fontSize: 24, color: 'white' }} />
              </div>
            </TouchableOpacity>
          }
        />
      </Flex>

      <Flex className={'p-6'} vertical>
        <Title className={'m-0'}>{data.name}</Title>
        {data.description && <Text className={'mt-2'}>{data.description}</Text>}
        <Divider />
        <Flex align={'center'} gap={'small'}>
          <BikeIcon className={'text-xl text-primary'} />
          {isFetchingLocation ? (
            <Skeleton.Input active size={'small'} />
          ) : (
            <>
              <Text>{displayPrice(location?.delivery_fee)}</Text>
              <Text className={'text-xs'}>(biaya parkir mungkin berlaku)</Text>
            </>
          )}
        </Flex>
        <Divider />
        <Flex align={'center'} gap={'small'}>
          <LocationOutlinedIcon className={'text-xl text-primary'} />
          {isFetchingLocation ? (
            <Skeleton.Input active size={'small'} />
          ) : (
            <Text>{humanizeDistance(location?.distance)}</Text>
          )}
        </Flex>
        <Divider />
        <Flex align={'center'}>
          <Flex flex={1}>
            <CalendarOutlined className={'text-xl text-primary'} />
            <Text className={'ml-2'}>{data.operating_hour}</Text>
          </Flex>
          <Tag color={data.is_operating ? 'success' : 'error'}>{data.is_operating ? 'BUKA' : 'TUTUP'}</Tag>
        </Flex>
        {data.products && data.products.length >= 10 && <Divider className={'mb-0'} />}
      </Flex>

      <ProductSearch searchTerm={searchTerm} onChange={(value) => setSearchTerm(value)} />

      <ProductCategories categories={data.product_categories} onCategorySelected={(i) => setSelectedCategoryId(i)} />

      <ProductList searchTerm={searchTerm} category={selectedCategoryId} />

      {getTotalCartItems() > 0 && (
        <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
          <Button type={'primary'} className={'w-full'} onClick={checkout}>
            Keranjang - {getTotalCartItems()} item - {displayPrice(getTotalPrice(), false)}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
