import { Layout } from 'antd';
import { ReactNode } from 'react';
import AppHeader from '@components/Layouts/AppLayout/AppHeader.tsx';

interface AppLayoutProps {
  pageTitle: string;
  children: ReactNode;
  showHeader?: boolean;
  headerComponent?: {
    accessoryLeft?: ReactNode;
    accessoryRight?: ReactNode;
  };
}

export default function AppLayout(props: AppLayoutProps) {
  const { children, pageTitle, headerComponent, showHeader = true } = props;

  return (
    <Layout className={'flex min-h-screen bg-white'}>
      {showHeader && <AppHeader title={pageTitle} {...headerComponent} />}
      <Layout.Content className={'h-full flex p-8'}>{children}</Layout.Content>
    </Layout>
  );
}
