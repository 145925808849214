import { Button, Card, Flex, Input, Modal, Tag, Typography } from 'antd';
import TouchableOpacity from '@components/TouchableOpacity';
import { RightOutlined, TagsOutlined } from '@ant-design/icons';
import Header from '@components/Header.tsx';
import { CloseIcon } from '@components/AppIcons';
import { useEffect, useState } from 'react';
import useWindowSize from '@utils/hooks/useWindowSize.ts';
import { useGetCoupon } from '@services/coupon.service.ts';
import ICoupon from '@core/types/ICoupon.ts';

const { Text } = Typography;

interface ApplyCouponProps {
  coupon?: ICoupon;
  setCoupon: (coupon: ICoupon) => void;
  clearCoupon: () => void;
}

export default function ApplyCoupon(props: ApplyCouponProps) {
  const { coupon, setCoupon, clearCoupon } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const [code, setCode] = useState('');

  const { mutate, data, error, failureReason, isPending } = useGetCoupon();

  const checkCoupon = () => {
    mutate(code);
  };

  useEffect(() => {
    if (!error) return;

    Modal.error({
      title: 'Kode kupon tidak valid',
      content: failureReason?.message,
      centered: true,
      okText: 'Oke',
    });
  }, [error]);

  useEffect(() => {
    if (!data) return;

    setCoupon(data);
    setVisible(false);
  }, [data]);

  return (
    <Flex vertical>
      <Card>
        <Flex gap={'small'} align={'center'}>
          <TagsOutlined className={'text-primary text-xl'} />
          <Flex flex={1}>
            <Text className={'text-sm'}>Cek Diskon</Text>
          </Flex>
          {coupon ? (
            <Tag color={'success'} closable onClose={clearCoupon}>
              {coupon?.code}
            </Tag>
          ) : (
            <TouchableOpacity onClick={() => setVisible(true)}>
              <Flex gap={'small'}>
                <RightOutlined className={'text-primary'} />
              </Flex>
            </TouchableOpacity>
          )}
        </Flex>
      </Card>

      <Modal
        centered
        open={visible}
        width={windowWidth}
        style={{ height: windowHeight }}
        closable={false}
        footer={null}
        className={'app-modal bg-transparent'}
      >
        <Flex vertical flex={1}>
          <Header
            title={'Kode Kupon'}
            accessoryLeft={
              <TouchableOpacity onClick={() => setVisible(false)}>
                <CloseIcon style={{ fontSize: 24 }} />
              </TouchableOpacity>
            }
          />
          <Flex vertical flex={1} className={'px-6 pb-6'}>
            <Card>
              <Input
                placeholder={'Masukkan kode kupon'}
                className={'w-full p-2'}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Card>
          </Flex>
          <Flex className={'p-6 sticky bottom-0 bg-white shadow'}>
            <Button type={'primary'} className={'w-full'} onClick={checkCoupon} disabled={!code} loading={isPending}>
              Periksa Kupon
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}
