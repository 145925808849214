import { clsx } from 'clsx';
import { Card, Flex, Space, Typography } from 'antd';
import DiscountedPrice from '@pages/StorePage/components/DiscountedPrice.tsx';
import { IProduct } from '@core/types/IProduct.ts';
import { IStore } from '@core/types/IStore.ts';

const { Text } = Typography;

interface ProductProps {
  product: IProduct;
  store: IStore;
  onClick: (product: IProduct) => void;
}

export default function Product(props: ProductProps) {
  const { product, store, onClick } = props;

  return (
    <Card
      hoverable
      className={clsx({
        'opacity-35': !store.is_operating,
        'no-padding': true,
      })}
      key={product.id}
      cover={
        <img
          src={product.image_path.length ? product.image_path : store.logo_path}
          className={'object-cover'}
          alt={product.name}
        />
      }
      onClick={() => (store.is_operating ? onClick(product) : null)}
    >
      <Flex vertical flex={1} className={'overflow-hidden p-3'} justify={'center'} gap={'small'}>
        <Text className={'font-bold overflow-hidden text-ellipsis text-sm'} style={{ margin: 0 }}>
          {product.name}
        </Text>
        <Space direction={'vertical'}>
          <DiscountedPrice product={product} />
        </Space>
      </Flex>
    </Card>
  );
}
