import { create } from 'zustand';
import { IProduct } from '@/core/types/IProduct.ts';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { IStore } from '@/core/types/IStore.ts';
import PaymentMethod from '@core/enums/PaymentMethod.ts';
import ICoupon from '@core/types/ICoupon.ts';

export interface ProductCartItem {
  uuid: string;
  product: IProduct;
  quantity: number;
  remark?: string;
  totalPrice: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface ProductCartState {
  cart: ProductCartItem[];
  store: IStore;
  product: IProduct;
  paymentMethod: number;
  coupon?: ICoupon;
  activeItem?: ProductCartItem;
  addToCart: (item: ProductCartItem) => void;
  updateCartItem: (item: ProductCartItem) => void;
  removeCartItem: (uuid: string) => void;
  clearCart: () => void;
  setActiveItem: (item: ProductCartItem) => void;
  setStore: (store: IStore) => void;
  setProduct: (product: IProduct) => void;
  setCoupon: (coupon: ICoupon) => void;
  clearCoupon: () => void;
  setPaymentMethod: (paymentMethod: number) => void;
  getSubTotalPrice: () => number;
  getItemsCount: () => number;
}

export const useProductCartStore = create<ProductCartState>()(
  devtools(
    persist(
      (set, get) => ({
        cart: [],
        store: {} as IStore,
        product: {} as IProduct,
        paymentMethod: PaymentMethod.CASH_ON_DELIVERY,
        addToCart: (item) =>
          set((state) => {
            return {
              activeItem: undefined,
              cart: [...state.cart, item],
            };
          }),
        updateCartItem: (item) =>
          set((state) => {
            const cart = [...state.cart];
            const indexToUpdate = state.cart.findIndex((i) => i.uuid === item.uuid);
            cart[indexToUpdate] = item;

            return {
              cart,
            };
          }),
        removeCartItem: (uuid) =>
          set((state) => {
            const cart = state.cart.filter((item) => item.uuid !== uuid);

            return {
              cart,
            };
          }),
        setStore: (store) => set(() => ({ store })),
        setProduct: (product) => set(() => ({ product })),
        setCoupon: (coupon) => set(() => ({ coupon })),
        clearCoupon: () => set(() => ({ coupon: undefined })),
        clearCart: () => set(() => ({ cart: [], coupon: undefined })),
        setPaymentMethod: (paymentMethod) => set(() => ({ paymentMethod })),
        setActiveItem: (activeItem) => set(() => ({ activeItem })),
        getSubTotalPrice: () => {
          const { cart } = get();

          if (!cart.length) return 0;

          return cart.reduce((prev, curr) => {
            return prev + curr.totalPrice;
          }, 0);
        },
        getItemsCount: () => {
          const { cart } = get();

          if (!cart.length) return 0;

          return cart.reduce((prev, curr) => {
            return prev + curr.quantity;
          }, 0);
        },
      }),
      {
        name: 'product-cart-storage',
        version: 1,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
